import moment from "moment";
import Year from "../components/yearCalendar/Year";

export class CalendarProps {

    constructor(date, events, localizer, defaultView) {
        this.date = date;
        this.events = events;
        this.localizer = localizer;
        this.defaultView = defaultView;
        this.views = {
            week: true,
            month: true,
            year: Year
        }
    }

    setHandleSelectDate = (handleSelectDate) => {
        this.handleSelectDate = handleSelectDate
    }

    setViews = (views) => {
        this.views = views
    }

    setTitleRenderer = (titleView) => {
        this.getTitle = titleView
    }

    getProps = () => {
        return {
            startAccessor: "start",
            endAccessor: "end",
            defaultDate: new Date(moment(this.date).format()),
            localizer: this.localizer,
            selectable: true,
            onSelectSlot: this.handleSelectDate,
            events: this.events,
            titleAccessor: this.getTitle,
            // tooltipAccessor: (value) => console.log(value),
            toolbar: false,
            views: this.views,
            defaultView: this.defaultView,
        }
    }
}