import React from 'react';
import { CloseOutlined } from "@ant-design/icons";
import { Drawer, Timeline } from "antd";
import moment from "moment";
import { RedDotIcon, RedDotIconForMCD, RedDotIconForSE } from "../../assests/icon";
import { handleSoftMapping } from "../../lib/softMapping";
import _map from 'lodash/map'
import { useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from '../../context/translationContext';

export const ShowScheduleOnDate = ({ onDrawerClose, drawerVisible, selectedDate, timelineData }) => {
    const { getTranslation } = useTranslation();
    const equipmentReducer = useSelector(state => state.equipmentReducer)
	const dashboard = useSelector(state => state.dashboard)

    const title = () => {
        return (
            <div className="d ai-center">
                <CloseOutlined onClick={() => onDrawerClose()} className="icon-wrapper" />
                <div className="cs-title ml-20" >
                    {getTranslation('Schedule for')} <span className="text-red" >{moment(selectedDate).format('Do MMMM')}</span>
                </div>
            </div>
        )
    }
    return (
        <Drawer
            className={`${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
            title={null}
            placement="right"
            closable={false}
            width="30vw"
            onClose={onDrawerClose}
            open={drawerVisible}>
            <div>
                {title()}
                <Timeline className="cs-timeline mt-20" style={{ marginLeft: 45 }}>
                    {
                        _map(timelineData, (value, time) =>
                            <Timeline.Item key={time} dot={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? <RedDotIconForSE className="cs-sub-title" /> : 
                            dashboard?.customerTheme === 'mcdonalds' ? <RedDotIconForMCD className="cs-sub-title" /> : <RedDotIcon className="cs-sub-title" />} >
                                <div className="cs-sub-title mt-10">
                                    <span>{time} :</span>
                                    {value.map((event, idx) =>
                                        <div key={idx}>{` ${getTranslation(event.name)} - ${getTranslation(handleSoftMapping(event.title, event.name, equipmentReducer.streamFunctions))}`}</div>
                                    )}
                                </div>
                            </Timeline.Item>)
                    }
                </Timeline>
            </div>
        </Drawer>
    )
}