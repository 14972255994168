import React, { useEffect, useState } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { Button, Space } from 'antd'
import { CustomDatePicker } from '../custom-datepicker'
import { CalendarProps } from '../../lib/calendarProps'
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { convertTime24to12, prependZero } from '../../lib/dateHelpers'
import { allColourRange, colorMapping, handleSoftMapping } from '../../lib/softMapping'
import { useSelector } from 'react-redux'
import { ShowScheduleOnDate } from './showSchedule'
import { getColors, getTitle } from './calenderHelper'
import PencilIcon from "../../imgs/PencilIcon.svg"
import { useTranslation } from '../../context/translationContext'

export const AllCalendar = ({ addControls, eventsData }) => {

    const { getTranslation } = useTranslation();
	const localizer = momentLocalizer(moment)
	const [view, setView] = useState('month')
	const [dateForCalendar, setDateForCalendar] = useState(moment().format());
	const [events, setEvents] = useState([])
	const [didUpdate, setdidUpdate] = useState(false)
	const [selectedDate, setSelectedDate] = useState(null)
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [currentStreamNames, setCurrentStreamNames] = useState([]);
	const [timelineData, setTimelineData] = useState([]);
	const [legendData, setLegendData] = useState([]);

	const streamFunctions = useSelector(state => state.equipmentReducer.streamFunctions)
	const yearReducer = useSelector(state => state.yearReducer)
	const appPermissions = useSelector(state => state.appStatus.appPermissions)

	useEffect(() => {
		setLegendData(yearReducer.legendData || [])
	}, [yearReducer.legendData])


	useEffect(() => {
		if (streamFunctions) {
			let tempStreamNames = streamFunctions.map(streamData => streamData.name) || []
			setCurrentStreamNames(tempStreamNames);
		} else setCurrentStreamNames([])
	}, [streamFunctions])

	useEffect(() => {
		if (eventsData && eventsData.length)
			setEvents(eventsData)
	}, [eventsData])

	const handleClickOnSlot = (slotData) => {
		setSelectedDate(moment(slotData.start).format('YYYY-MM-DD'))
	}

	const onDrawerClose = () => {
		setSelectedDate(null)
		setDrawerVisible(false)
	}

	useEffect(() => {
		if (selectedDate) {
			let tempEventData = {}
			events.filter(eventData => moment(eventData.start).format('YYYY-MM-DD') === selectedDate)
				.map(reqData => {
					let time = moment(reqData.start).format('hh:mm A');
					if (tempEventData[time]) {
						tempEventData[time] = [...tempEventData[time], reqData]
					} else {
						tempEventData[time] = [reqData]
					}
				})
			setTimelineData(tempEventData)
			setDrawerVisible(true)
		}
	}, [selectedDate])

	const GetCalendar = ({ didUpdate }) => {

		const calendarPropsObj = new CalendarProps(dateForCalendar, events, localizer, view)
		calendarPropsObj.setTitleRenderer((value) => getTitle(value, view, streamFunctions, getTranslation))
		calendarPropsObj.setHandleSelectDate(handleClickOnSlot)

		useEffect(() => {
			let weekElement = document.getElementsByClassName('week')[0];
			if (weekElement) {
				let eventElements = weekElement.getElementsByClassName('rbc-event') || [];
				for (let element = 0; element < eventElements.length; element++) {
					let newText = ""
					if (eventElements[element].getAttribute("title"))
						newText = eventElements[element].getAttribute("title").split('–');
					let title = ""
					if (newText[1])
						title = newText[1].split(':')[2];
					title = title.toString()
					if (title == 1) {
						title = "ON"
					}
					if (title == 0) {
						title = "OFF"
					}
					eventElements[element].setAttribute("title", `${newText[0]} - ${title}`);
				}
			}
		}, [])

		const calendarProps = calendarPropsObj.getProps()

		return <Calendar className={`large mt-20 mb-10 ${view === "month" ? 'custom-month-height' : 'week'}`}
			{...calendarProps} />

	}

	return (
		<div>
			<div className="d jc-sb mb-10 mt-10">
				<Space>
					<CustomDatePicker view={view} iconSmall={true} value={dateForCalendar}
						callback={(date) => { setDateForCalendar(date); setdidUpdate(!didUpdate) }} />
					<Button className="light-grey-button ml-10"
						onClick={() => setDateForCalendar(moment().format())}
					>{getTranslation('Today')}</Button>
				</Space>
				<div style={{ display: 'flex' }} >
					<div style={{ marginRight: 20 }}>
						<div className="d buttons-group">
							{/* <div className={`first  div-button ${view === "week" ? 'selected-view' : ''}`}
								onClick={() => setView('week')}>WEEK</div> */}
							<div className={`first div-button ${view === "month" ? 'selected-view' : ''}`}
								onClick={() => setView('month')}>{getTranslation('MONTH')}</div>
							<div className={`last div-button ${view === "year" ? 'selected-view' : ''}`}
								onClick={() => setView('year')}>{getTranslation('YEAR')}</div>
						</div>
					</div>
					{(!appPermissions.onlyViewControls) && appPermissions.canAddControls && <div>
						<Button className="red-button"
							onClick={() => addControls('addControls')} icon={<img src={PencilIcon} alt="edit-icon" style={{ marginBottom: '3px' }} />}>{getTranslation('Add Controls')}</Button>
					</div>}
				</div>
			</div>
			<GetCalendar didUpdate={didUpdate} />
			<div className="d jc-sb mt-10 mb-10 ai-center">
				<div className="d" >{view !== "year" && getColors(legendData, streamFunctions, getTranslation)}</div>
			</div>
			<ShowScheduleOnDate onDrawerClose={onDrawerClose} selectedDate={selectedDate}
				timelineData={timelineData} drawerVisible={drawerVisible} />
		</div>
	)
}
