import { Modal, Select } from "antd";
import _map from "lodash/map";
import React, { useState } from "react";
import axios from "axios";
import _isEmpty from "lodash/isEmpty";
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg";
import CloseBlackOutlined from "../../imgs/CloseBlackOutlined.svg";
import { useSelector } from "react-redux";
import {
  DEFAULT_LANGUAGE,
  useTranslation,
} from "../../context/translationContext";

export const LanguageSelect = () => {
  const { getTranslation } = useTranslation();
  const dashboard = useSelector((state) => state.dashboard);
  const languageList = [
    { id: "en", label: "English", value: "en" },
    { id: "fr", label: "French", value: "fr" },
  ];
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const setLanguage = async (language) => {
    try {
      // setLanguagePreference for current user in session when changed manually
      await axios.get(`/utilities/setLanguagePreference?language=${language}`);
      localStorage.setItem("language", language);
      window.location = "/";
    } catch (error) {
      // console.log(error)
    }
  };

  return (
    <div className="grey">
      <span onClick={() => setVisible(true)}>
        {getTranslation("Change Language")}
      </span>
      <Modal
        open={visible}
        title={null}
        onCancel={handleClose}
        closable={false}
        footer={null}
        className={`cs-modal new-ui ${
          !_isEmpty(dashboard && dashboard?.customerTheme) &&
          (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')
        }`}
      >
        <div className="d jc-sb ai-center">
          <div className="cs-title cs-medium" id="select-language">
            {getTranslation("SELECT LANGUAGE")}
          </div>
          <div>
            <img
              src={CloseBlackOutlined}
              className="cur"
              alt="close-icon"
              onClick={() => handleClose()}
            />
          </div>
        </div>
        <div className="grey mt-20">
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={getTranslation("Select a language")}
            suffixIcon={
              <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={setLanguage}
            value={localStorage.getItem("language") || DEFAULT_LANGUAGE}
          >
            {_map(languageList, (language) => {
              return (
                <Select.Option key={language.value} value={language.value}>
                  {getTranslation(language.label)}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </Modal>
    </div>
  );
};
