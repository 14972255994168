// apis for modifyFetching data from server
import _forEach from "lodash/forEach";
import _uniqBy from "lodash/uniqBy";
import { toast } from 'react-toastify';
import { encodeParams } from '../../lib/api-helpers';
import { toastError } from "../../toast/toast";
import axiosAPI from '../../lib/api-helpers';

let getAccessibleSites = async () => {
	try {
		const response = await axiosAPI.get('/access/user-access/')
		const body = response['data']
		let sites = [];

		_forEach(body.data.zenatixuserById.accessTags.edges, edge => {
			sites = _uniqBy([...sites, ...edge.node.clientSet.edges.map(item => item.node)], 'id')
		})

		return sites;
	} catch (e) {
		toast.error("No site are assigned to this user, please contact admin.", {icon: toastError()})
	}
}

let getMeters = async (meter, meterCategory = false) => {
	try {
        const response = await axiosAPI.get('/wattman/getMeters/', meter + "/" + meterCategory)
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let getUserPermissions = async (params) => { // params = {type: 'groups'} | {type: 'permissions'}
	try {
        const response = await axiosAPI.post('/access/getUserPermissions', JSON.stringify({data: {...params}}))
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let getDashboardTheme = async () => {
	try {
        const response = await axiosAPI.get('/api/getDashboardTheme');
        return response['data'];
    } catch (e) {
        // toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let setSessionCustomer = async (params) => {
	try{
        const response = await axiosAPI.get('/access/setSessionCustomer?' + encodeParams(params))
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let fetchCustomers = async () => {
	try{
        const response = await axiosAPI.get('/access/getAccessibleCustomers')
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let getTermsAndConditions = async (params) => {
    try{
        const response = await axiosAPI.post('/users/getTermsAndConditions', params)
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let optOutFromTNC = async (params) => {
    try{
        const response = await axiosAPI.get('/users/optOut')
        return response['data'];
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let downloadTNC = async () => {
    try {
		const response = await axiosAPI.post('/users/downloadTerms&Conditions', {}, {responseType: 'blob'})
        let a = document.createElement('a');
        a.href = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
        a.download = `Privacy Policy.pdf`;
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        return true;
	} catch (error) {
		toast.error(error?.response?.data?.resp || ("We Encountered an error, please try sometime later..."), {icon: toastError()})
	}
}

let optInTNC = async () => {
    try {
        let response = await axiosAPI.get('/users/optIn')
        return response['data']
    } catch (e) {
        toast.error("We Encountered an error, please try sometime later...", {icon: toastError()})
    }
}

const getLicenses = async () => {
    try {
        const response = await axiosAPI.get('/access/getLicenses')
        return response['data'];
    } catch (e) {
        // toast.error("No licenses available", {icon: toastError()})
    }
}

let canAccessDashboard = async (params) => {
    try {
        const response = await axiosAPI.get('/wattman/canAccessDashboard?' + encodeParams(params))
        return response['data'];
    } catch (e) {
        toast.error(e.response.data.resp || "We encountered an error, please try sometime later...", {icon: toastError()})
    }
}

let dataApi = {
	getAccessibleSites: getAccessibleSites,
	getMeters: getMeters,
	getUserPermissions,
    getDashboardTheme,
	setSessionCustomer,
	fetchCustomers,
	getTermsAndConditions,
	optOutFromTNC,
    downloadTNC,
    optInTNC,
    getLicenses,
    canAccessDashboard
}

export default dataApi;
