import React, { useEffect, useState } from 'react'
import { NormalView } from './normalView';
import { EditView } from './editView';

export const AddControls = ({ cancel, init }) => {

    const [scheduleId, setScheduleID] = useState(null)

    const callback = (idx) => setScheduleID(idx)

    return !scheduleId ? <NormalView callback={callback} init={init} cancel={cancel} /> :
        <EditView scheduleId={scheduleId} callback={callback} goBackToHome={cancel} />

}