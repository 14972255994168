import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Modal, Space, Spin } from "antd";
import OtpInput from "react-otp-input";
import { TIMER_VALUE } from "../../lib/constants";
import VerificationPageHeader from "./VerificationPageHeader";
import { toast } from "react-toastify";
import { toastError, toastSuccess } from "../../toast/toast";
import { generateEmailOtp, userInfoVerification } from "../../elements/authentication/authentication";
import "./verificationPage.css";

const { Text, Link, Title } = Typography;

const VerificationPage = ({
    isEmailVerified,
    isPhoneVerified,
    username,
    password,
    userType
}) => {
    const [emailOtp, setEmailOtp] = useState("");
    const [phoneOtp, setPhoneOtp] = useState("");
    const [showModal, setShowModal] = useState(false);
    // const [errorMsg, setErrorMsg] = useState("");
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [enableEmailRetry, setEnableEmailRetry] = useState(false)
    const [enablePhoneRetry, setEnablePhoneRetry] = useState(false)
    const [emailTimerCount, setEmailTimerCount] = useState(TIMER_VALUE);
    const [phoneTimerCount, setPhoneTimerCount] = useState(TIMER_VALUE);
    const [redirectTime, setRedirectTimer] = useState(5)

    useEffect(() => {
        sendOTP()
    }, [])

    const sendOTP = async () => {
        try{
            let payload = {
                username
            }
            if(isEmailVerified === false && isPhoneVerified === false)
                payload = {...payload, isEmailVerified, isPhoneVerified}
            else if(isPhoneVerified === false)
                payload = {...payload, isPhoneVerified}
            else if(isEmailVerified === false)
                payload = {...payload, isEmailVerified}
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("OTP sent successfully", {icon: toastSuccess()})
            }
        } catch (e) {
            setEnablePhoneRetry(true)
            setEnableEmailRetry(true)
            console.error(e)
        }
    }

    const resendEmailOtpInterval = () => {
        if (emailTimerCount > 0) {
            setTimeout(() => {
                setEmailTimerCount((emailTimerCount) => emailTimerCount - 1);
            }, 1000);
        } else {
            setEnableEmailRetry(true);
            setEmailTimerCount(TIMER_VALUE);
        }
    };

    const resendPhoneOtpInterval = () => {
        if (phoneTimerCount > 0) {
            setTimeout(() => {
                setPhoneTimerCount((phoneTimerCount) => phoneTimerCount - 1);
            }, 1000);
        } else {
            setEnablePhoneRetry(true);
            setPhoneTimerCount(TIMER_VALUE);
        }
    };

    useEffect(() => {
        !enableEmailRetry && resendEmailOtpInterval();
    }, [emailTimerCount, enableEmailRetry]);

    useEffect(() => {
        !enablePhoneRetry && resendPhoneOtpInterval();
    }, [phoneTimerCount, enablePhoneRetry]);

    useEffect(() => {
        if(showModal){
            if(redirectTime > 0)
                setTimeout(() => {
                    setRedirectTimer((redirectTime) => redirectTime - 1);
                }, 1000);
        }
    }, [showModal, redirectTime])

    const validateOtp = async () => {
        setLoaderStatus(true);
        try {
            let payload = {
                username: username,
                password: password
            };
            if(isEmailVerified === false && isPhoneVerified === false)
                payload = {...payload, verifyPhoneTOTP: phoneOtp, verifyEmailTOTP: emailOtp}
            else if(isPhoneVerified === false)
                payload = {...payload, verifyPhoneTOTP: phoneOtp}
            else if(isEmailVerified === false)
                payload = {...payload, verifyEmailTOTP: emailOtp}
            let resp = await userInfoVerification({...payload})
            if(resp){
                setShowModal(true);
            }
        } catch (error) {
            // if (error && error.error) {
            //     setErrorMsg(error.error.message);
            // }
        }
        setLoaderStatus(false);
    };

    const resendPhoneOTP = async () => {
        try{
            let payload = {
                username,
                isPhoneVerified: false
            }
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("OTP sent successfully", {icon: toastSuccess()})
                setEnablePhoneRetry(false)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const resendEmailOTP = async () => {
        try{
            let payload = {
                username,
                isEmailVerified: false
            }
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("OTP sent successfully", {icon: toastSuccess()})
                setEnableEmailRetry(false)
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div style={{height: '100%', padding: '0 40px 30px'}}>
                <Spin size="large" spinning={loaderStatus}>
                    <VerificationPageHeader />
                    <Card title="Verification" className="card-wrapper verify-otp-card">
                        <Text className="card-sub-header">
                            {
                                isEmailVerified === false && isPhoneVerified === false ? <>Verify your Phone Number and Email</> : 
                                isEmailVerified === false ? <>Verify your Email</> : <>Verify your Phone Number</>
                            }
                        </Text>
                        {
                            isEmailVerified === false && <>
                                <div className="verify-email-container">
                                    <span className="verification-heading">Verify Email</span>
                                    <OtpInput
                                        value={emailOtp}
                                        onChange={(value) => setEmailOtp(value)}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        containerStyle="otp-container"
                                        inputStyle="otp-input"
                                    />
                                </div>
                                {/* {errorMsg && (
                                    <Title level={5} type="danger">
                                        {errorMsg}
                                    </Title>
                                )} */}
                                <Text className="receive-otp-info">
                                    {"If you didn't receive OTP, "} &nbsp;
                                    {enableEmailRetry ? (
                                        <Link className="resend-otp-txt" onClick={resendEmailOTP}>
                                            {"Resend OTP"}
                                        </Link>
                                    ) : (
                                        <Text className="retry-count-txt">{`retry in ${emailTimerCount
                                            .toString()
                                            .padStart(2, "0")} sec.`}</Text>
                                    )}
                                </Text>
                            </>
                        }
                        {
                            isPhoneVerified === false &&
                            <>
                                <div className="verify-email-container" style={{ position: 'relative', right: '40px' }}>
                                    <span className="verification-heading">Verify Phone Number</span>
                                    <OtpInput
                                        value={phoneOtp}
                                        onChange={(value) => setPhoneOtp(value)}
                                        numInputs={6}
                                        shouldAutoFocus={true}
                                        isInputNum={true}
                                        containerStyle="otp-container"
                                        inputStyle="otp-input"
                                    />
                                </div>
                                {/* {errorMsg && (
                                    <Title level={5} type="danger">
                                        {errorMsg}
                                    </Title>
                                )} */}
                                <Text className="receive-otp-info">
                                    {"If you didn't receive OTP, "} &nbsp;
                                    {enablePhoneRetry ? (
                                        <Link className="resend-otp-txt" onClick={resendPhoneOTP}>
                                            {"Resend OTP"}
                                        </Link>
                                    ) : (
                                        <Text className="retry-count-txt">{`retry in ${phoneTimerCount
                                            .toString()
                                            .padStart(2, "0")} sec.`}</Text>
                                    )}
                                </Text>
                            </>
                        }
                        <div className="verifyotp-btn-groups">
                            <Button
                                type="primary"
                                htmlType="button"
                                size="medium"
                                className="form-btn submit-otp-btn"
                                disabled={isEmailVerified === false && isPhoneVerified === false ? (emailOtp.length !== 6 || phoneOtp.length !== 6) :
                                    isEmailVerified === false ? (emailOtp.length !== 6) : (phoneOtp.length !== 6)}
                                onClick={() => validateOtp()}
                            >
                                Submit
                            </Button>
                            <Button
                                htmlType="button"
                                onClick={() => { setEmailOtp(""); setPhoneOtp("") }}
                                className="form-btn clear-otp-btn"
                                disabled={emailOtp.length === 0 && phoneOtp.length === 0}
                            >
                                Clear
                            </Button>
                        </div>
                    </Card>
                </Spin>
            </div>

            <Modal
                centered={true}
                footer={null}
                closable={false}
                visible={showModal}
                className="otp-section-modal"
                width={380}
            >
                <Space direction={"vertical"} className="reset-modal-space">
                    <Title level={3} className="reset-modal-title">
                        Your details have been verified
                    </Title>
                    <Text color="#707070" className="reset-modal-sub-title">
                        You are redirecting to the login screen in {redirectTime} seconds.
                        {redirectTime === 0 && window.location.reload()}
                    </Text>
                </Space>
            </Modal>
        </React.Fragment>
    );
};

export default VerificationPage;
