/*
 *
 * Year state object
 *
 * */
import moment from "moment";

const yearReducer = (
	state = {
		year: moment().format('YYYY'),
		eventsDate: [],
		legendData: []
	},
	action
) => {
	switch (action.type) {
		case "UPDATE_YEAR":
			state = { ...state, year: action.payload }
			break;
		case "UPDATE_EVENT_DATE":
			state = {
				...state, eventsDate: action.payload.eventsDate,
				legendData: action.payload.legendData
			}
			break;
		default:
			break;
	}
	return state;
};

export default yearReducer;
