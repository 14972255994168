import axiosAPI from '../../lib/api-helpers';
import { toast } from "react-toastify";
import { toastError } from '../../toast/toast';
import { encodeParams } from '../../lib/api-helpers';

export let login = async (loginCreds) => {
    let {username, password} = loginCreds;
	try {
        const response = await axiosAPI.post('/users/login', JSON.stringify({username, password, source: 'scheduleDashboard'}))
        const body = response.data;
        if (response.status !== 200)
            toast.error(body.message || ("Some error occured, please try sometime later"), { icon: toastError() });
        return body;
    } catch (error) {
        toast.error(error.message || ("Some error occured, please try sometime later"), { icon: toastError() });
        throw error;
    }
}

export let loginStatus = async () => {
    
    try {
        const response = await axiosAPI.post('/access/status', JSON.stringify({}));
        const body = response.data
        if (response.status !== 200) throw Error(body.message);
        if (body.allow !== undefined) {
            return body;
        }
        return false;
    } catch (ex) {
        console.log('While authenticating from server problem occured--> ', ex);
        return false;
    }
    
}

export let logout = async () => {
    try {
        const response = await axiosAPI.post('/users/logout', JSON.stringify({}));
        const body = response.data
        if (response.status !== 200) throw Error(body.message);
        return false;
    } catch (ex) {
        console.log('While authenticating from server problem occured--> ', ex);
    }
    
}

export const validateOTP = async (params) => {
	try {
        const response = await axiosAPI.get('/users/validateOTP?' + encodeParams(params))
        const body = response.data;
        if (response.status !== 200)
            toast.error("Invalid code, please try again.", { icon: toastError() });
        return body;
    } catch (error) {
        toast.error(error?.response?.data?.message || "Invalid code, please try again.", { icon: toastError() });
        throw error;
    }
}

export const generateOTP = async (params, isNewLogin) => {
	try {
        const response = await axiosAPI.get('/users/generateOTP?' + encodeParams(params))
        const body = response.data;
        if (response.status !== 200)
            toast.error(body.message || ("Some error occured, please try sometime later"), { icon: toastError() });
        return body;
    } catch (error) {
        toast.error(error?.response?.status === 429 ? error?.response?.data : `Unable to generate ${isNewLogin ? " code" : " OTP"}`, { icon: toastError() });
        throw error;
    }
}

export const generateEmailOtp = async (params) => {
	try {
        const response = await axiosAPI.post('/users/generateVerifyOtp', JSON.stringify({...params}))
        const body = response.data;
        if (response.status !== 200)
            toast.error(body.message || ("Some error occured, please try sometime later"), { icon: toastError() });
        return body;
    } catch (error) {
        toast.error(error?.response?.status === 429 ? error?.response?.data.message : "Unable to generate OTP.", {icon: toastError()})
        throw error;
    }
}

export const userInfoVerification = async (params) => {
    try {
        const response = await axiosAPI.post('/users/userInfoVerification', JSON.stringify({...params}))
        const body = response.data;
        if (response.status !== 200)
            toast.error("Invalid code, please try again.", { icon: toastError() });
        return body;
    } catch(error) {
        toast.error((error?.response?.data?.message || "Invalid code, please try again."), { icon: toastError() });
        throw error;
    }
}