import React, { useState, useEffect } from 'react'
import { Button } from 'antd'
import { SingleTimeFram } from './SingleTimeFram';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import { addNewSchedules, clearNewSchedules } from '../../actions/newSchedulesActions';
import { toast } from 'react-toastify';
import { sendSchedule } from '../../elements/apis/controlApis';
import { toastSuccess, toastError } from '../../toast/toast'
import { CloseIcon } from '../../assests/icon';
import { useTranslation } from '../../context/translationContext';
import { getCurrentUnitSystem } from '../../lib/function-helpers';

export const NormalView = ({ callback, cancel, init }) => {
    const { getTranslation } = useTranslation();
    const [schedulesData, setSchedulesData] = useState([])

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
    const equipmentReducer = useSelector(state => state.equipmentReducer)
    const appStatusReducer = useSelector(state => state.appStatus)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)

    const dispatch = useDispatch();

    useEffect(() => {
        if (!_isEmpty(newScheduleStore)) {
            let allSchedules = []
            for (let key of Object.keys(newScheduleStore))
                allSchedules.push({ ...newScheduleStore[key], scheduleId: key })
            setSchedulesData(allSchedules)
        } else {
            setSchedulesData([])
            handleNew(true)
        }
    }, [newScheduleStore])


    const handleEdit = (idx) => {
        callback(idx)
    };

    const handleNew = (isEmpty) => {
        if (isEmpty) {
            dispatch(addNewSchedules({ key: `schedule1` }))
        } else {
            dispatch(addNewSchedules({ key: `schedule${schedulesData.length + 1}` }))
        }
    }

    const handleSubmit = async () => {
        if (equipmentReducer.equipmentName && equipmentReducer.clientName) {
            let result = {};

            // NOTE: right now  there are only single sensor based.
            let thresholdStreamName = "";
            if (equipmentReducer.streamFunctions.filter(config => config.type === "Threshold")[0])
                thresholdStreamName = equipmentReducer.streamFunctions
                    .filter(config => config.type === "Threshold")[0]['name']
            let thresholdLow = ""
            let thresholdHigh = ""
            let thresholdHours = []
            let thresholdDays = []
            let thresholdSingleFlag = thresholdStreamName !== "" ? true : false;

            let modified_by = `${appStatusReducer.firstName || "NA"}.${appStatusReducer.lastName || "NA"}`
            let client_name = equipmentReducer.actualClientName
            let equipment_name = equipmentReducer.equipmentName


            let tempSchedule = newScheduleStore;
            for (let scheduleKey of Object.keys(tempSchedule)) {
                let weekly_schedules = []
                let date_schedules = []
                if (tempSchedule[scheduleKey].frequency && tempSchedule[scheduleKey].frequency.length) {
                    for (let freqCount = 0; freqCount < tempSchedule[scheduleKey].frequency.length; freqCount++) {
                        if (tempSchedule[scheduleKey].frequency[freqCount].isDate) {
                            let tempState = { date: tempSchedule[scheduleKey].frequency[freqCount].date };
                            let states = {}
                            tempSchedule[scheduleKey].frequency[freqCount]['schedules'].map(
                                stateData => {
                                    let [key, value] = Object.entries(stateData)[0];
                                    let tempValue = {}
                                    _map(value, (stateValue, stateName) => {
                                        if (stateName !== 'Time' && stateName !== thresholdStreamName) {
                                            Object.assign(tempValue, { [stateName]: stateValue })
                                        }
                                    })
                                    Object.assign(states, { [key]: tempValue })
                                }
                            )
                            Object.assign(tempState, { states })
                            date_schedules.push(tempState)
                        } else {
                            let tempState = { days_of_week: tempSchedule[scheduleKey].frequency[freqCount].days_of_week };
                            let states = {}
                            if (thresholdSingleFlag && thresholdStreamName !== "") {
                                tempSchedule[scheduleKey].frequency[freqCount]['schedules'].map(
                                    stateData => {
                                        let [key, value] = Object.entries(stateData)[0];
                                        _map(value, (stateValue, stateName) => {
                                            if (stateName === thresholdStreamName) {
                                                // if found any sensor bases stream then only send it....
                                                thresholdSingleFlag = false;
                                                thresholdLow = stateValue[1];
                                                thresholdHigh = stateValue[0];
                                                thresholdHours.push(key.split(':')[0])
                                            }
                                        })
                                    }
                                )
                                thresholdDays = tempState["days_of_week"];
                            }
                            tempSchedule[scheduleKey].frequency[freqCount]['schedules'].map(
                                stateData => {
                                    let [key, value] = Object.entries(stateData)[0];
                                    let tempValue = {}
                                    _map(value, (stateValue, stateName) => {
                                        if (stateName !== 'Time' && stateName !== thresholdStreamName) {
                                            Object.assign(tempValue, { [stateName]: stateValue })
                                        }
                                    })
                                    Object.assign(states, { [key]: tempValue })

                                }
                            )
                            Object.assign(tempState, { states })
                            weekly_schedules.push(tempState)
                        }
                    }
                }
                const { expiry_date, start_date } = tempSchedule[scheduleKey];
                Object.assign(result, { [scheduleKey]: { start_date, expiry_date, weekly_schedules, date_schedules } })
                // delete tempSchedule[scheduleKey]['frequency']
            }

            let tempOperstingHour = [];
            if (!thresholdSingleFlag && thresholdStreamName !== "") {
                for (let hour = 0; hour < thresholdHours.length - 1; hour++) {
                    tempOperstingHour.push(`${thresholdHours[hour]}-${(thresholdHours[hour + 1] - 1)}`)
                    hour++;
                }
            }
            try {
                let payloadToSend = {
                    schedule: result,
                    equipment_name,
                    client_name,
                    modified_by,
                    unit_type:getCurrentUnitSystem(),
                }
                if (!thresholdSingleFlag && thresholdStreamName !== "") {
                    Object.assign(payloadToSend, {
                        "sensor_based_control": {
                            "temperature_control": {
                                name: thresholdStreamName,
                                on_threshold: thresholdLow.toString(),
                                off_threshold: thresholdHigh.toString(),
                                days_of_week: thresholdDays,
                                operating_hours: tempOperstingHour
                            }
                        }
                    })
                }

                // payloadToSend = JSON.stringify(payloadToSend)
                let resp = await sendSchedule(payloadToSend, appPermissions.isDemoUser);
                if (resp && resp.deployment_status) {
                    if (resp.deployment_status === "success") {
                        toast.success(resp.message, {icon: toastSuccess()})
                        await init(equipment_name, client_name)
                        dispatch(clearNewSchedules())
                        cancel()
                    }
                    else
                        toast.error(resp.message || "Could not fetch data, Try sometime later...", {icon: toastError()})
                }
            } catch (err) {
                console.error(err)
            }
        } else {
            toast.error("Not able to find equipment or site.", {icon: toastError()})
            return;
        }
    }

    const footerButton = () => {
        return <div className="d mt-20" style={{ justifyContent: 'flex-end' }} >
            <Button className="red-button-outline" onClick={() => handleNew()} >{getTranslation('New Time Frame')}</Button>
            <Button className="red-button ml-5" onClick={() => handleSubmit()} >{getTranslation('Submit Schedule')}</Button>
        </div>
    }

    const renderView = () => {
        return (
            <div className="display-column jc-sb" style={{ height: '80vh' }}>
                <div>
                    <div className="d jc-fe">
                        <CloseIcon className="icon-small-wrapper d-flex ant-row-center ant-row-middle mr-10" onClick={() => cancel()} />
                    </div>
                    <div style={{ maxHeight: '65vh', height: '100%', overflowY: 'auto' }}>
                        {schedulesData.map((data, idx) => <SingleTimeFram schedulesCount={schedulesData.length}
                            idx={idx} key={idx} config={data} edit={handleEdit} />)}
                        {!schedulesData.length && <div className="br-1 d color-grey jc-c ai-center mt-20" style={{ height: 200, textAlign: 'center' }}>
                            {getTranslation('Add new Time frame from below')}
                        </div>}
                    </div>
                </div>
                <div>
                    {footerButton()}
                </div>
            </div>
        )
    }

    return (
        <div>
            {renderView()}
        </div>
    )
}
