import React, { useState } from "react";
import { Button, Drawer, Select } from "antd";
import _isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";
import "./app-drawer.css";
import { toastWarn } from "../../toast/toast";
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"

const { Option } = Select;

const mode = "MULTIPLE";

const contains = (arr, v) => {
    for (var i = 0; i < arr.length; i++) {
        if (arr[i] === v) return true;
    }
    return false;
};

const unique = (data, key) => {
    var arr = [];
    for (var i = 0; i < data.length; i++) {
        if (!contains(arr, data[i][key])) {
            arr.push(data[i][key]);
        }
    }
    return arr;
};

export const SiteDrawer = ({ selectSiteContext, site, siteOptions }) => {
	const [visible, setvisible] = useState(false);
	const [sites, setsites] = useState([]);

	const handleChange = value => {
		if (!_isEmpty(value)) {
			setsites(value);
		} else {
			toast.warn("Please select atleast one site", {icon: toastWarn()});
		}
	};
	const handleSingleSiteChange = value => {
		if (value) {
			setsites([value]);
		}
	};
	const handleSubmit = () => {
		if (!_isEmpty(sites)) {
			setvisible(false);
			selectSiteContext(sites);
			setsites([]);
		} else {
			toast.warn("Please select atleast one site", {icon: toastWarn()});
		}
	};
	return (
		<>
			<Button className="red-button" onClick={() => setvisible(true)}>
				Select Site
			</Button>
			<Drawer
				title="Select site"
				width={420}
				onClose={() => setvisible(false)}
				open={visible}
				bodyStyle={{ paddingBottom: 80 }}
				footer={
					<div
						style={{
							textAlign: "right"
						}}
					>
						<Button
							onClick={() => setvisible(false)}
							style={{ marginRight: 8 }}
						>
							Cancel
						</Button>
						<Button onClick={handleSubmit} type="primary">
							Submit
						</Button>
					</div>
				}
			>
				{mode === "SINGLE" ? (
					<Select
						allowClear
						style={{ width: "100%" }}
						placeholder="Please select Site"
						suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
						defaultValue={
							!_isEmpty(site) ? site[0] : siteOptions[0].displayName
						}
						onChange={handleSingleSiteChange}
					>
						{siteOptions.map(site => (
							<Option key={site.displayName} value={site.displayName}>
								{site.displayName}
							</Option>
						))}
					</Select>
				) : (
					<Select
						mode="multiple"
						allowClear
						style={{ width: "100%" }}
						placeholder="Please select Site"
						suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
						defaultValue={
							!_isEmpty(site) ? site[0] : siteOptions[0].displayName
						}
						onChange={handleChange}
					>
						{unique(siteOptions, "displayName").map(site => (
							<Option key={site} value={site}>
								{site}
							</Option>
						))}
					</Select>
				)}
			</Drawer>
		</>
	);
};
