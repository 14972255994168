import React from "react";
import Logo from "../../imgs/ZenatixLogo.png";
import "./verificationPage.css";

const VerificationPageHeader = () => {
    return (
        <div className="header-wrapper">
            <img src={Logo} alt={"zenatix-logo"} width={180} />
            <hr />
        </div>
    );
};

export default VerificationPageHeader;
