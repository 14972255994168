import { DatePicker } from '../dayjsToMoment'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { ArrowLeftIcon, ArrowRightIcon } from '../../assests/icon';
import { useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty';

function disabledDate(current) {
	// Can not select days before today and today
	return current && current < moment().startOf("day");
}

export const CustomDatePicker = ({ view, callback, noArrow, small, value, iconSmall, customDropdownClassName }) => {

	const [date, setdate] = useState(value || moment().format())
	const dashboard = useSelector(state => state.dashboard);

	useEffect(() => {
		setdate(value || moment().format())
	}, [value])

	const getWeakDateFormat = (value) => {
		let week = value.format('ww')
		let year = value.format('YYYY')
		let month = value.format('MMMM')

		let firstDay = moment().day("Sunday").year(year).week(week);
		let lastDay = moment().day("Saturday").year(year).week(week);

		return `${month} ${firstDay.format('DD')}-${lastDay.format('DD')}, ${year}`
	};

	const dateFormats = {
		year: 'YYYY',
		month: 'MMM YYYY',
		week: 'YYYY/wo',
	}

	const customFormat = value => `${getWeakDateFormat(value)}`;

	const addOrSubtractYear = (add) => {
		let requiredDate = null

		switch (view) {
			case 'year':
				if (add) requiredDate = moment(date).add(1, 'years')
				else requiredDate = moment(date).subtract(1, 'years')
				break;
			case 'month':
				if (add) requiredDate = moment(date).add(1, 'M')
				else requiredDate = moment(date).subtract(1, 'M')
				break;
			case 'week':
				if (add) requiredDate = moment(date).add(1, 'weeks')
				else requiredDate = moment(date).subtract(1, 'weeks')
				break
		}

		callback(requiredDate)
		setdate(requiredDate)
	}

	const changeDate = date => {
		if (date) {
			setdate(date)
			callback(date)
		}
	}

	return (
		<div className="d ai-center" style={{ textAlign: 'center' }}>
			<DatePicker bordered={false}
				popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
				allowClear={false}
				disabledDate={disabledDate}
				suffixIcon={null}
				className={`custom-datepicker${small || ""}`}
				dropdownClassName={customDropdownClassName || ""}
				value={moment(date)}
				format={(dateFormats && view) && view === 'week' ? customFormat : dateFormats[view]}
				onChange={changeDate}
				picker={view} />
			{noArrow ? null : <div className="ml-10 d-flex">
				<ArrowLeftIcon onClick={() => addOrSubtractYear()}
					className={`d-flex ant-row-center ant-row-middle ${iconSmall ? `icon-small-wrapper` : `icon-wrapper${small || ""}`}`} />
				<ArrowRightIcon onClick={() => addOrSubtractYear(true)}
					className={`d-flex ant-row-center ant-row-middle ${iconSmall ? `icon-small-wrapper ml-10` : `icon-wrapper${small || ""} ml-5 `}`} />
			</div>}
		</div>
	)
}
