export const activeTabs = { column: "column", aggregators: "aggregators" };

export const aggregations = [
  {
    name: "Sum",
    type: "floatSum"
  },
  {
    name: "Max",
    type: "floatMax"
  },
  {
    name: "Min",
    type: "floatMin"
  },
  {
    name: "Count",
    type: "count"
  },
  {
    name: "Average",
    type: "average"
  },
  {
    name: "Average (NaN excluded)",
    type: "NaN_average"
  },
  {
    name: "First Value",
    type: "first_value"
  }
];

export const numericalTypes = ["FLOAT", "LONG", "DOUBLE"];

export const comparators = [
  "GREATER THAN",
  "GREATER OR EQUAL THAN",
  "LESS THAN",
  "LESS OR EQUAL THAN",
  "EQUAL TO",
  "NOT EQUAL TO"
];

export const virtualColumnOperations = [
  {
    text: "Add",
    operation: " + "
  },
  {
    text: "Subtract",
    operation: " - "
  },
  {
    text: "Multiply",
    operation: " * "
  },
  {
    text: "Divide",
    operation: " / "
  },
  {
    text: "Bracket start",
    operation: " ( "
  },
  {
    text: "Bracket end",
    operation: " ) "
  }
];

export const operations = [
  { text: "Inner join", value: "inner_join" },
  // {text: "2 - Outer left join", value: "outer_ljoin"},
  // {text: "3 - Outer right join", value: "outer_rjoin"},
  { text: "Left join", value: "ljoin" },
  { text: "Right join", value: "rjoin" },
  { text: "Outer join", value: "outer_join" },
  // {text: "7 - Full Outer join", value: "full_outer_join"},
  { text: "Append", value: "append" }
];

export const thresholdPallet = [
  "#26a63c",
  "#bd4545",
  "#70d2ef",
  "#b025a8",
  "#ffc107",
  "#FF178F",
  "#177CB0",
  "#ef5d1b",
  "#efc932"
];

export const dataLabel = {
  STRING: { size: "mini", color: "red", content: "STRING" },
  LONG: { size: "mini", color: "green", content: "LONG" },
  FLOAT: { size: "mini", color: "blue", content: "FLOAT" }
};

// attributes name right now, later on combination of name and possible values can also be saved
export const ATTRIBUTES = ["tags"];

export const PALETTE = [
  "#BE002F",
  "#F20C00",
  "#F00056",
  "#FF2D51",
  "#FF2121",
  "#FF4C00",
  "#FF7500",
  "#FF8936",
  "#FFA400",
  "#F0C239",
  "#FFF143",
  "#FAFF72",
  "#C9DD22",
  "#AFDD22",
  "#9ED900",
  "#00E500",
  "#0EB83A",
  "#0AA344",
  "#0C8918",
  "#057748",
  "#177CB0"
];

export const PIE_PELETTE = [
  "rgb(102, 194, 165)",
  "rgb(252, 141, 98)",
  "rgb(141, 160, 203)",
  "rgb(231, 138, 195)"
];

export const GRAPH_PALETTE = [
  "#fea12c",
  "#70d2ef",
  "#fe60ec",
  "#1a4d9c",
  "#FF2121",
  "#FF4C00",
  "#FF7500",
  "#FF8936",
  "#FFA400",
  "#F0C239",
  "#FFF143",
  "#FAFF72",
  "#C9DD22",
  "#AFDD22",
  "#9ED900",
  "#00E500",
  "#0EB83A",
  "#0AA344",
  "#0C8918",
  "#057748",
  "#177CB0"
];
//
// export const GRAPH_PALETTE = [
// 	"#003f5c",
// 	"#2f4b7c",
// 	"#665191",
// 	"#a05195",
// 	"#d45087",
// 	"#f95d6a",
// 	"#ff7c43",
// 	"#ffa600"
// ]

export const ECHARTS_PALETTE = [
  "#c23531",
  "#2f4554",
  "#61a0a8",
  "#d48265",
  "#749f83",
  "#ca8622",
  "#bda29a",
  "#6e7074",
  "#546570",
  "#c4ccd3",
  "#f05b72",
  "#ef5b9c",
  "#f47920",
  "#905a3d",
  "#fab27b",
  "#2a5caa",
  "#444693",
  "#726930",
  "#b2d235",
  "#6d8346",
  "#ac6767",
  "#1d953f",
  "#6950a1",
  "#918597"
];

export const samplingPeriods = {
  "No Sampling": false,
  "1 hr": "PT1H",
  "2 hr": "PT2H",
  "3 hr": "PT3H",
  "4 hr": "PT4H",
  "6 hr": "PT6H",
  "8 hr": "PT8H",
  "12 hr": "PT12H",
  "1 Day": "P1D",
  "1 Week": "P1W",
  "1 Month": "P1M",
  "2 Month": "P2M",
  "3 Month": "P3M",
  "6 Month": "P6M",
  "1 Year": "P1Y"
};

export const unitsPrefix = {
  C: "º"
};

export const specialSymbols = {
  Rs: "₹",
  "%": "%"
};

export const bizChartsThemePalette = [
  "#166fee",
  "#ff5660",
  "#ffb018",
  "#9c5fff",
  "#b2ef4b",
  "#f76b1c"
];

export const ControlIcons = {
  SetIntensity: { icon: "bulb", tooltip: "Variable Intensity" },
  SetPowerStatus: { icon: "poweroff", tooltip: "On/Off" },
  RemoteControl: { icon: "poweroff", tooltip: "On/Off" },
  OperatingModePC: { icon: "dashboard", tooltip: "Operating Mode" },
  SetTemperaturePC: { icon: "fire", tooltip: "Temperature" },
  DesiredOverrideControl: { icon: "poweroff", tooltip: "On/Off" },
  DesiredSetTemperature: { icon: "fire", tooltip: "Temperature" },
  DesiredAnalogOutput: { icon: "thunderbolt", tooltip: "Analog output" }
};

// settings according to MapMyIndia
export const map_tile_subdomains = ["mt1", "mt2", "mt3"];
export const map_key = "5vpj9vmgcrucx3aiq1teztjr9lxh6b1a";

export const removeBranding = ["vishal", "rahul", "demo"];

export const METRIC_SYSTEM = 'metric';
export const IMPERIAL_SYSTEM = 'imperial';

export const unitMapping = {
  Celsius: "°C",
  "%": "%",
  Kilometer:'Km',
  Kilogram:'Kg',
  Fahrenheit:"°F",
};

export const REACT_APP_BASE_URL = window.location.origin;

export const REACT_APP_V2_DASHBOARD_URL = {
  india: "https://dashboard.zenatix.com/",
  europe: "https://dashboard.ecostruxure-building-activate.se.app/",
  europeDemo: "https://dashboard.demo.ecostruxure-building-activate.se.app/"
}

export const REACT_APP_EUROPE_DOMAIN_NAME_KEY = ".se.app";

export const REACT_APP_EUROPE_DEMO_DOMAIN_NAME_KEY = ".demo.ecostruxure-building-activate.se.app";

export const REACT_APP_PASSWORD_RESET_URLS = {
  india: {
    dev: {
      withTeleport: "https://passwordreset-dev.teleport.zenatix.com/",
      withoutTeleport: "https://passwordreset-dev.zenatix.com/",
    },
    qa: {
      withTeleport: "https://passwordreset-qa.teleport.zenatix.com/",
      withoutTeleport: "https://passwordreset-qa.zenatix.com/",
    },
    prod: {
      withTeleport: "https://passwordreset.teleport.zenatix.com/",
      withoutTeleport: "https://passwordreset.zenatix.com/",
    }
  },
  europe: {
    dev: {
      withTeleport: "https://passwordreset.ecostruxure-building-activate.dev.teleport.se.app/",
      withoutTeleport: "https://passwordreset.ecostruxure-building-activate.dev.se.app/",
    },
    qa: {
      withTeleport: "https://passwordreset.ecostruxure-building-activate.qa.teleport.se.app/",
      withoutTeleport: "https://passwordreset.ecostruxure-building-activate.qa.se.app/",
    },
    prod: {
      withTeleport: "https://passwordreset.ecostruxure-building-activate.teleport.se.app/",
      withoutTeleport: "https://passwordreset.ecostruxure-building-activate.se.app/",
    }
  }
}

export const CUSTOMER_NAME_CHANGE = {
  "Decathlon": "Retail",
  "Dominos": "QSR",
  "IBIS": "Hotel"
}

export const RESET_OTP_TIMER = 60;  // value is in second

export const TIMER_VALUE = 60

export const REACT_APP_OPEN_SOURCE_LICENSE = "https://d1u8xttmk41jc6.cloudfront.net/opensource.json";
export const SESSION_TIMEOUT = 14 * 60; // 14 hours in minutes
export const SESSION_TIMEOUT_EXCEPTION_CUSTOMERS = 7 * 24 * 60; // 7 days in minutes
export const EXCEPTION_CUSTOMERS = ["IBIS", "IFCIHCL", "Cinepolis"]
export const SESSION_EXPIRY_CHECK_INTERVAL = 1 * 60 * 1000; // 1 minutes in milliseconds;

export const SITE_OVERRIDE_DURATIONS = [1800,3600,5400];