import React, { useEffect, useRef, useState } from 'react'
import { Select } from 'antd'
import _isEmpty from "lodash/isEmpty";
import _map from "lodash/map";
import { useDispatch, useSelector } from 'react-redux';
import { getClientEquipmentMapping, getEquipmentTypeStreamFeatures } from '../../elements/apis/controlApis';
import { clearNewSchedules, updateMetaInfo } from '../../actions/bulkActions';
import { setUserPermissions } from '../../actions/appActions';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';

export const SelectEquipmentType = () => {
    const { getTranslation } = useTranslation();
    const [allEquipmentsType, setallEquipmentsType] = useState({});
    const [selectedEquipmentType, setSelectedEquipmentType] = useState(null);
    const [allEquipments, setAllEquipments] = useState([]);

    const dispatch = useDispatch()
    const bulkReducer = useSelector(state => state.bulkReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const selectRef = useRef();

    useEffect(() => {
        init()
    }, [])

    useEffect(() => {
        if (bulkReducer.selectedType)
            setSelectedEquipmentType(bulkReducer.selectedType)
    }, [bulkReducer.selectedType])

    const unFocus = (element) => {
        element.blur()
    }

    const init = async () => {
        try{
            let res;
            if(appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
                res = appPermissions['clientEquipmentMapping']
            else{
                res = await getClientEquipmentMapping()
                dispatch(setUserPermissions(res))
            }
            if (res && res.data && res.data.clientSet.edges) {
                let allEquipments = []
                let equipmentTypes = {}
    
                res.data.clientSet.edges.map(data =>
                    allEquipments.push(...data.node.equipments.edges.map(equipmentData => {
                        Object.assign(equipmentTypes, { [equipmentData.node.type.name]: equipmentData.node.type.displayName })
                        return {
                            ...equipmentData.node, siteDisplayName: data.node.displayName,
                            siteName: data.node.clientName, type: equipmentData.node.type.name
                        }
                    }
                    ))
                )
    
                setallEquipmentsType(equipmentTypes)
                setAllEquipments(allEquipments);
    
                if (bulkReducer.selectedType)
                    setSelectedEquipmentType(bulkReducer.selectedType)
            }
        } catch (e) {
            console.log(e);
        }
    }

    const onChangeTypes = equipmentType => {
        if (equipmentType) {
            unFocus(selectRef.current)
            let equipmentMapping = allEquipments.filter(equipmentData =>
                equipmentData.type === equipmentType);
            updateEqupmentRedux(equipmentType, equipmentMapping)
        }
    };

    const updateEqupmentRedux = async (equipmentType, equipmentMapping) => {

        dispatch(clearNewSchedules())
        let widgets = ["Time"];
        let validationConstraint = {}
        let res = await getEquipmentTypeStreamFeatures({ equipmentType })
        if (res && res.data) {
            res.data = JSON.parse(res.data)
            validationConstraint = res.data
            widgets = [...widgets, ...Object.keys(res.data)]
        }
        dispatch(updateMetaInfo({
            equipmentType,
            equipmentMapping,
            widgets,
            validationConstraint
        }))
    }

    return (
        <div className="d jc-sb hierarchy-context mb-10">
          <div>
            <span id={"custom-select-label"}>{getTranslation("Type")}</span>
            <Select
                ref={selectRef}
                showSearch
                placeholder={getTranslation("Select Type")}
                onChange={onChangeTypes}
                value={selectedEquipmentType}
                suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {!_isEmpty(allEquipmentsType) && Object.keys(allEquipmentsType).map(
                    site => <Select.Option key={site} value={site}>{getTranslation(allEquipmentsType[site])}</Select.Option>
                )}
            </Select>
          </div>
        </div>
    )
}
