export function updateYear(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_YEAR",
            payload
        });
    }
}

export function updateEventsDate(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_EVENT_DATE",
            payload
        });
    }
}