export const controlScreens = [
  {
    name: "View / Add Controls",
    url: "configschedules",
    view: "configSchedules",
    new: true,
    icon: "ScheduleOutlined",
    config: {
      siteSelection: true
    }
  },
  {
    name: "Download Schedules",
    url: "downloadSchedules",
    view: "downloadSchedules",
    new: true,
    icon: "DownloadOutlined",
    config: {
      siteSelection: true
    }
  },
  {
    name: "Control Status",
    url: "controlStatus",
    view: "controlStatus",
    new: true,
    icon: "ControlOutlined",
    config: {
      siteSelection: true
    }
  },
];
