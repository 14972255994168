import React, { useState, useEffect, useRef } from "react";
import { updateGlobalFilters } from "../../actions/dashboardActions";
import dataApi from "../../elements/apis/apis";
import { SiteDrawer } from "../app-drawer/app-drawer";
import _find from "lodash/find";
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Skeleton } from "antd";
import { toastWarn, toastError } from "../../toast/toast";

export const SiteSelection = props => {
	const [siteSelection, setsiteSelection] = useState(false);
	const [siteOptions, setsiteOptions] = useState([]);
	const [context, setcontext] = useState([]);
	const [refetchDevices, setrefetchDevices] = useState(false);
	const [deviceType, setdeviceType] = useState(false);
	const [site, setsite] = useState([]);
	const [siteDetails, setsiteDetails] = useState({});
	const [siteObject, setsiteObject] = useState([]);
	const [visible, setvisible] = useState(false);

	const [check, setcheck] = useState(false);

	const dashboard = useSelector(state => state.dashboard);

	const dispatch = useDispatch();

	const ref = useRef(null);

	useEffect(() => {
		dataApi
			.getAccessibleSites()
			.then(resp => {
				if (resp && resp.length) {
					let { config } = dashboard.activeDashboard;
					setsiteSelection(config.siteSelection);
					setsiteOptions(resp);
					setcontext(props.context);
				} else {
					toast.warn(
						"No site are assigned to this user, please contact admin.", {icon: toastWarn()}
					);
				}
			})

	}, [dashboard]);

	const renderSiteSelectionDropdown = () => {
		let enableSiteSelection = !!siteOptions.length && siteSelection;
		let { site, siteObject } = dashboard.globalFilters;
		// setsite(site);
		if (enableSiteSelection) {
			if (!site && siteOptions) {
				setsite([siteOptions[0].displayName]);
				selectSiteContext([siteOptions[0].displayName]);
				setvisible(true);
			} else if (!!site && !siteObject) {
				selectSiteContext([site[0]]);
			} else {
				if (refetchDevices) {
					selectSiteContext([site[0]] || [siteOptions[0].displayName]);
				} else {
					setvisible(true);
				}
			}
		}
	};

	useEffect(() => {
		if (site.length && siteObject.length) {
			updateContext();
		}
	}, [siteObject]);

	useEffect(() => {
		renderSiteSelectionDropdown();
	}, [siteSelection, siteOptions]);

	const updateContext = () => {
		let filters = {
			site: site,
			siteObject: siteObject
		};
		dispatch(updateGlobalFilters(filters));
	};

	const isGlobalFiltersEnabled = () => {
		return siteSelection;
	};

	const selectSiteContext = async allValue => {
		if (siteOptions) {
			let allSiteObject = [];
			let allPromise = [];
			try {
				for (let i = 0; i < allValue.length; i++) {
					let value = allValue[i];
					allPromise.push(dataApi.getMeters(value, deviceType));
				}
				let resp = await Promise.all(allPromise);
				for (let i = 0; i < resp.length; i++) {
					let localSiteObject =
						_find(siteOptions, { displayName: allValue[i] }) || {};
					localSiteObject.devices = resp[i].data;
					allSiteObject.push(localSiteObject);
				}
				setsiteObject([...allSiteObject]);
			} catch (err) {
				// toast.warn("Network error occur", {icon: toastWarn()});
			}
		}
	};

	if (siteSelection)
		return (
			<div className={"hierarchy-context"}>
				<div>
					{siteOptions.length ? (
						<SiteDrawer
							site={site}
							siteOptions={siteOptions}
							selectSiteContext={selectSiteContext}
						/>
					) : (
						<div>Loading</div>
					)}
				</div>
			</div>
		);
	else return <div className="hierarchy-context" ></div>;
};
