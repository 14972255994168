import React, { useState, useEffect } from "react";
import { Card, Button, Typography, Modal, Space, Spin, Row, Col } from "antd";
import OtpInput from "react-otp-input";
import { TIMER_VALUE } from "../../lib/constants";
import background from "../../imgs/newLoginBackground.png";
import logo_se from "../../imgs/logo-se.png"
import "./verificationPage.css";
import { toast } from "react-toastify";
import { toastError, toastSuccess } from "../../toast/toast";
import { generateEmailOtp, userInfoVerification } from "../../elements/authentication/authentication";
import { NewLoginScreenFooterLogoIcon } from "../../assests/icon";

const { Text, Link, Title } = Typography;

const NewVerificationPage = ({
    isEmailVerified,
    isPhoneVerified,
    username,
    password
}) => {
    const [emailOtp, setEmailOtp] = useState("");
    const [phoneOtp, setPhoneOtp] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [loaderStatus, setLoaderStatus] = useState(false);
    const [enableEmailRetry, setEnableEmailRetry] = useState(false)
    const [enablePhoneRetry, setEnablePhoneRetry] = useState(false)
    const [emailTimerCount, setEmailTimerCount] = useState(TIMER_VALUE);
    const [phoneTimerCount, setPhoneTimerCount] = useState(TIMER_VALUE);
    const [redirectTime, setRedirectTimer] = useState(5)

    useEffect(() => {
        sendOTP()
    }, [])

    const sendOTP = async () => {
        try{
            let payload = {
                username
            }
            if(isEmailVerified === false && isPhoneVerified === false)
                payload = {...payload, isEmailVerified, isPhoneVerified}
            else if(isPhoneVerified === false)
                payload = {...payload, isPhoneVerified}
            else if(isEmailVerified === false)
                payload = {...payload, isEmailVerified}
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("Verification code sent successfully", {icon: toastSuccess()})
            }
        } catch (e) {
            setEnablePhoneRetry(true)
            setEnableEmailRetry(true)
            console.error(e)
        }
    }

    const resendEmailOtpInterval = () => {
        if (emailTimerCount > 0) {
            setTimeout(() => {
                setEmailTimerCount((emailTimerCount) => emailTimerCount - 1);
            }, 1000);
        } else {
            setEnableEmailRetry(true);
            setEmailTimerCount(TIMER_VALUE);
        }
    };

    const resendPhoneOtpInterval = () => {
        if (phoneTimerCount > 0) {
            setTimeout(() => {
                setPhoneTimerCount((phoneTimerCount) => phoneTimerCount - 1);
            }, 1000);
        } else {
            setEnablePhoneRetry(true);
            setPhoneTimerCount(TIMER_VALUE);
        }
    };

    useEffect(() => {
        !enableEmailRetry && resendEmailOtpInterval();
    }, [emailTimerCount, enableEmailRetry]);

    useEffect(() => {
        !enablePhoneRetry && resendPhoneOtpInterval();
    }, [phoneTimerCount, enablePhoneRetry]);

    useEffect(() => {
        if(showModal){
            if(redirectTime > 0)
                setTimeout(() => {
                    setRedirectTimer((redirectTime) => redirectTime - 1);
                }, 1000);
        }
    }, [showModal, redirectTime])

    const validateOtp = async () => {
        setLoaderStatus(true);
        try {
            let payload = {
                username: username,
                password: password
            };
            if(isEmailVerified === false && isPhoneVerified === false)
                payload = {...payload, verifyPhoneTOTP: phoneOtp, verifyEmailTOTP: emailOtp}
            else if(isPhoneVerified === false)
                payload = {...payload, verifyPhoneTOTP: phoneOtp}
            else if(isEmailVerified === false)
                payload = {...payload, verifyEmailTOTP: emailOtp}
            let resp = await userInfoVerification({...payload})
            if(resp){
                setShowModal(true);
            }
        } catch (error) {
        }
        setLoaderStatus(false);
    };

    const resendPhoneOTP = async () => {
        try{
            let payload = {
                username,
                isPhoneVerified: false
            }
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("Verification code sent successfully", {icon: toastSuccess()})
                setEnablePhoneRetry(false)
            }
        } catch (e) {
            console.error(e);
        }
    }

    const resendEmailOTP = async () => {
        try{
            let payload = {
                username,
                isEmailVerified: false
            }
            let response = await generateEmailOtp({...payload});
            if (response.otp_sent) {
                toast.success("Verification code sent successfully", {icon: toastSuccess()})
                setEnableEmailRetry(false)
            }
        } catch (e) {
            console.error(e);
        }
    }

    return (
        <React.Fragment>
            <div style={{height: '100%', 'background': 'url("' + background + '")', backgroundSize: 'cover'}}>
                <Spin size="large" spinning={loaderStatus}>
                    {/* <VerificationPageHeader /> */}
                    <Card className="card-wrapper new-card-wrapper verify-otp-card">
                    <div>
                        <div className="transparent-green-cover"></div>
                        <div className="green-cover"></div>
                    </div>
                    <Row>
                        <Col span={17} style={{display: 'flex'}}>
                            <img src={logo_se} className="building-flex-logo" style={{height: "36lvh",marginTop:"-9.5lvh",marginLeft:"-2.2lvw"}} />
                        </Col>
                        <Col span={7}>
                            <Text className="new-card-sub-header eu-login-form-title" style={{marginTop: 20}}>
                                Verify your details
                            </Text>
                            {
                                isEmailVerified === false && <>
                                    <div className="new-verify-email-container">
                                        <span className="new-verification-heading">Email</span>
                                        <Text className="new-card-sub-header" >
                                            Please enter the verification code sent by email
                                        </Text>
                                        <OtpInput
                                            value={emailOtp}
                                            onChange={(value) => setEmailOtp(value)}
                                            numInputs={6}
                                            shouldAutoFocus={true}
                                            isInputNum={true}
                                            containerStyle="new-otp-container"
                                            inputStyle="otp-input"
                                        />
                                    </div>
                                    <Text className="receive-otp-info" style={{display: 'block', textAlign: 'left'}} id="fs-12">
                                        {"If you didn't receive the verification code, "} &nbsp;
                                        {enableEmailRetry ? (
                                            <Link className="resend-otp-txt" onClick={resendEmailOTP}>
                                                {"Re-send Code"}
                                            </Link>
                                        ) : (
                                            <Text className="retry-count-txt">{`retry in ${emailTimerCount
                                                .toString()
                                                .padStart(2, "0")} sec.`}</Text>
                                        )}
                                    </Text>
                                </>
                            }
                            {
                                isPhoneVerified === false &&
                                <>
                                    <div className="new-verify-email-container">
                                        <span className="new-verification-heading">Phone</span>
                                        <Text className="new-card-sub-header">
                                            Please enter the verification code sent by phone
                                        </Text>
                                        <OtpInput
                                            value={phoneOtp}
                                            onChange={(value) => setPhoneOtp(value)}
                                            numInputs={6}
                                            shouldAutoFocus={true}
                                            isInputNum={true}
                                            containerStyle="new-otp-container"
                                            inputStyle="otp-input"
                                        />
                                    </div>
                                    <Text className="receive-otp-info" style={{display: 'block', textAlign: 'left'}} id="fs-12">
                                        {"If you didn't receive the verification code, "} &nbsp;
                                        {enablePhoneRetry ? (
                                            <Link className="resend-otp-txt" onClick={resendPhoneOTP}>
                                                {"Re-send Code"}
                                            </Link>
                                        ) : (
                                            <Text className="retry-count-txt">{`retry in ${phoneTimerCount
                                                .toString()
                                                .padStart(2, "0")} sec.`}</Text>
                                        )}
                                    </Text>
                                </>
                            }
                            <div className="new-verifyotp-btn-groups d">
                                <Button
                                    type="primary"
                                    htmlType="button"
                                    size="medium"
                                    className="new-gen-otp-btn"
                                    disabled={isEmailVerified === false && isPhoneVerified === false ? (emailOtp.length !== 6 || phoneOtp.length !== 6) :
                                        isEmailVerified === false ? (emailOtp.length !== 6) : (phoneOtp.length !== 6)}
                                    onClick={() => validateOtp()}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    </Card>
                    <div className='login-form-footer'>
                        <span id='login-form-footer-content'>
                            This application is protected by copyright law and international treaties. <br/>
                            © 2024 Schneider Electric Industries SAS. All Rights Reserved.
                        </span>
                        <div>
                            <NewLoginScreenFooterLogoIcon style={{width:"14.5vw"}} />
                        </div>
                    </div>
                </Spin>
            </div>

            <Modal
                centered={true}
                footer={null}
                closable={false}
                visible={showModal}
                className="otp-section-modal"
                width={380}
            >
                <Space direction={"vertical"} className="reset-modal-space">
                    <Title level={3} className="reset-modal-title">
                        Your details have been verified
                    </Title>
                    <Text color="#707070" className="reset-modal-sub-title">
                        You are redirecting to the login screen in {redirectTime} seconds.
                        {redirectTime === 0 && window.location.reload()}
                    </Text>
                </Space>
            </Modal>
        </React.Fragment>
    );
};

export default NewVerificationPage;
