import React, { useEffect, useRef, useState } from "react";
import { Circle } from "rc-progress";

import { DatePicker, Switch, Select, Button, Slider, Input, InputNumber, Checkbox } from "antd";
import {
	CheckOutlined,
	ClockCircleOutlined,
	GlobalOutlined,
	LeftOutlined,
	MinusOutlined,
	PoweroffOutlined
} from "@ant-design/icons";

import whiteFan from "./pngs/whiteFan.png";
import greyFan from "./pngs/greyFan.png";

import greySnow from "./pngs/greySnow.png";
import whiteSnow from "./pngs/whiteSnow.png";

import "./clock.css";
import { Timepicker } from "../timePicker/index";
import { toast } from "react-toastify";
import { convertTime12to24, convertTime24to12, prependZero, tConvert24to12 } from "../../lib/dateHelpers";
import { DownArrowGreyIcon, EditIcon, NoLiveDataIcon, NoOverrideIcon, PlusIcon } from "../../assests/icon";
import { toggleMapping } from "../../lib/softMapping";
import { toastWarn } from "../../toast/toast"
import UpArrowOutlined from "../../imgs/UpArrowOutlined.svg"
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from "../../context/translationContext";
import { getCurrentUnitSystem } from "../../lib/function-helpers";
import { unitMapping } from "../../lib/constants";

const OldDataScreen = () => {
	return <div className="d jc-c ai-center">
		<NoLiveDataIcon style={{ fontSize: 100 }} />
	</div>
}

const FailedDataScreen = () => {
	return <div className="d jc-c ai-center">
		<NoOverrideIcon style={{ fontSize: 100 }} />
	</div>
}

export const GetCustomTimePicker = () => {
    const { getTranslation } = useTranslation();
	const [hour, setHour] = useState(0);
	const [minute, setMinute] = useState(0);

	const [hourMode, setHourMode] = useState(true);

	const [mode, setMode] = useState(true);
	const [visible, setVisible] = useState(false);

	const [view, setView] = useState("default");

	const handleOnChange = (hour, minute) => {
		setHour(hour);
		setMinute(minute);
	};

	const FixedTime = () => {
		return (
			<div className="display-column ai-center p-point5">
				<div className="d ai-center p-point5">
					<div className="d ai-center mr-5">
						<h4
							onClick={() => {
								mode !== false && setVisible(!visible)
								setMode(true);
							}}
							className="custom-input"
						>
							{hour}
						</h4>
						<h3 style={{ marginTop: 5, fontWeight: "bold" }}>:</h3>
						<h4
							onClick={() => {
								mode !== true && setVisible(!visible)
								setMode(false);
							}}
							className="custom-input"
						>
							{minute}
						</h4>
					</div>
					<div className="display-column">
						<h3
							onClick={() => setHourMode(true)}
							style={{ margin: "0px 0px 2px 0px", cursor: "pointer" }}
							className={hourMode ? "text-red" : ""}
						>
							{getTranslation('AM')}
						</h3>
						<h3
							onClick={() => setHourMode(false)}
							style={{ margin: 0, cursor: "pointer" }}
							className={hourMode ? "" : "text-red"}
						>
							{getTranslation('PM')}
						</h3>
					</div>
				</div>
				{visible && (
					<div
						className="color-grey br-1"
						style={{ boxShadow: "10px 10px 10px #dbdbdb" }}
					>
						<Timepicker
							size={190}
							mode={mode}
							radius={70}
							militaryTime={false}
							onChange={handleOnChange}
						/>
					</div>
				)}
			</div>
		);
	};

	const Default = () => {
		return (
			<div className="d jc-sb">
				<div className="display-column jc-center mr-5 ai-center">
					<div className="back-grey">
						<ClockCircleOutlined
							onClick={() => setView("fixed")}
							className="text-red"
							style={{ fontSize: 16 }}
						/>
					</div>
					<div className="mt-5">Fixed Time</div>
				</div>
				<div className="display-column jc-center ai-center">
					<div className="back-grey">
						<GlobalOutlined
							onClick={() => setView("sensor")}
							className="text-red"
							style={{ fontSize: 16 }}
						/>
					</div>
					<div className="mt-5">Sun Time</div>
				</div>
			</div>
		);
	};

	const SensorBased = () => {
		return (
			<div className="display-column">
				<div className="d">
					<div className="back-grey mr-5">
						<GlobalOutlined className="" style={{ fontSize: 18 }} />
					</div>
					<div className="back-grey">
						<GlobalOutlined className="" style={{ fontSize: 18 }} />
					</div>
				</div>
				<div className="d jc-sa ai-center">
					<div className="back-grey">
						<p className="" style={{ fontSize: 18, margin: 0 }}>
							0 Min
						</p>
					</div>
					<div className="display-column ai-center jc-center mt-10">
						<PlusIcon
							twoToneColor="#eb2f96"
							className="icon-wrapper-small mb-5 plus-icon-color"
						/>
						<MinusOutlined
							twoToneColor="#eb2f96"
							className="icon-wrapper-small"
						/>
					</div>
				</div>
			</div>
		);
	};

	const renderView = () => {
		switch (view) {
			case "sensor":
				return <SensorBased />;
			case "fixed":
				return <FixedTime />;
			default:
				return <Default />;
		}
	};

	return (
		<div className="display-column ai-center">
			<div className="d jc-sb ai-center mb-20">
				{view !== "default" && (
					<LeftOutlined
						onClick={() => setView("default")}
						twoToneColor="#eb2f96"
						className="icon-wrapper-small mr-10"
					/>
				)}
				<div>
					Set Time
				</div>
			</div>
			{renderView()}
		</div>
	);
};

export const TimeSelect = ({ stateData, updateTime }) => {
    const { getTranslation } = useTranslation();
	let name = "Time"
	const [hourValue, setHourValue] = useState(3);
	const [minuteValue, setMinuteValue] = useState(12);
	const [isAM, setIsAM] = useState(false)

	const ref = useRef();

	const parseTime = () => convertTime12to24(`${prependZero(hourValue)}:${prependZero(minuteValue)} ${isAM ? "AM" : "PM"}`)
	const hanldeUpdate = () => updateTime(parseTime())

	const setValue = (time24) => {
		const { time, am } = convertTime24to12(time24)
		if (time && time.length === 4) {
			setHourValue(parseInt(time[0]))
			setMinuteValue(parseInt(time[2]))
		}
		if (am) {
			setIsAM(true)
		} else {
			setIsAM(false)
		}
	}

	useEffect(() => {
		if (stateData && stateData[name]) {
			setValue(stateData[name])
		}
	}, [stateData])

	useEffect(() => {
		if (ref.current) {
			hanldeUpdate()
		} else ref.current = true
	}, [hourValue, minuteValue, isAM])

	const handleArrow = (action, type) => {
		if (type === "hour") {
			if (action === "add") {
				if (hourValue + 1 <= 12)
					setHourValue(hourValue + 1)
			} else {
				if (hourValue - 1 > 0)
					setHourValue(hourValue - 1)
			}
		} else {
			if (action === "add") {
				if (minuteValue + 1 <= 59)
					setMinuteValue(minuteValue + 1)
			} else {
				if (minuteValue - 1 >= 0)
					setMinuteValue(minuteValue - 1)
			}
		}
	}

	const validate = (type) => {
		if (type === "hour") {
			if (hourValue > 12)
				setHourValue(12)
			if (hourValue < 0)
				setHourValue(0)
		} else {
			if (minuteValue > 59)
				setMinuteValue(59)
			if (minuteValue < 0)
				setMinuteValue(0)
		}
	}

	return (
		<div className="display-column ai-center">
			<div className="mb-10">
				<div className="res-fnt-20 cs-medium">
					{getTranslation("Time")}
				</div>
			</div>
			<div className="d ai-center">
				<div className="display-column">
					<div className="actuator-arrow-icon">
						<img src={UpArrowOutlined} onClick={() => handleArrow("add", "hour")} className="cur mb-10" width={'26px'} />
					</div>
					<InputNumber value={hourValue} onBlur={() => validate("hour")}
						onChange={(val) => setHourValue(val)} className="custom-input" />
					<div className="actuator-arrow-icon">
						<img src={DownArrowOutlined} onClick={() => handleArrow("minus", "hour")} className="cur mt-10" width={'26px'} />
					</div>
				</div>
				<div className="ml-10 mr-10">:</div>
				<div className="display-column">
					<div className="actuator-arrow-icon">
						<img src={UpArrowOutlined} onClick={() => handleArrow("add", "minute")} className="cur mb-10" width={'26px'} />
					</div>
					<InputNumber value={minuteValue} onBlur={() => validate("minute")}
						onChange={(val) => setMinuteValue(val)} className="custom-input mt-10 mb-10" />
					<div className="actuator-arrow-icon">
						<img src={DownArrowOutlined} onClick={() => handleArrow("minus", "minute")} className="cur mt-10" width={'26px'} />
					</div>
				</div>
				<div className="display-column ml-10">
					<Button size="small" onClick={() => setIsAM(true)} className={isAM && "red-button"}>{getTranslation('AM')}</Button>
					<Button size="small" onClick={() => setIsAM(false)} className={"mt-5 " + (!isAM && "red-button")} >{getTranslation('PM')}</Button>
				</div>
			</div>
		</div>
	)
}

const typeCast = (value, leastCount) => Number.isInteger(leastCount) ? parseInt(value) : Math.round(value * 10) / 10

export const ContinousWidget = ({ config, stateData, updateState, isEdit, isOverride, handleCheck, hideCheck }) => {
    const { getTranslation } = useTranslation();

	const { name, isOldData, failed } = config
	const min = config.properties?.[getCurrentUnitSystem()]?.min_measured || config.properties.min_measured || 0;
	const max = config.properties?.[getCurrentUnitSystem()]?.max_measured || config.properties.max_measured || 100;
	const leastCount = config?.properties?.least_count || 1;
	const fraction = 100 / (max - min);
	const [value, setValue] = useState(min);
	const [edit, setEdit] = useState(true);

	useEffect(() => {
		if (stateData && stateData[name] !== undefined) {
			let temp = stateData[name]
			if (name.toLowerCase() === "vfd control") {
				if (parseFloat(temp) <= 10) {
					temp = (2 * parseFloat(stateData[name])) + 30
				}
			}
			setValue(parseFloat(temp))
		} else {
			if (isOverride) {
				updateState({ [name]: typeCast(min, leastCount) })
			}
		}
	}, [stateData, config])

	const onPressKeys = add => {
		if (add) {
			let addResult = (value + leastCount).toFixed(1);
			if (addResult <= max) {
				setValue(addResult);
				updateState({ [name]: typeCast(addResult, leastCount).toString() })
			}
		} else if (value - leastCount >= min) {
			let subtractResult = (value - leastCount).toFixed(1);
			setValue(subtractResult);
			updateState({ [name]: typeCast(subtractResult, leastCount).toString() })
		}
	};

	const onChange = (e) => {
		let value = e.target.value;
		if (value <= max)
			setValue(value)
	}

	const onBlur = () => {
		if (value) {
			if (value >= min && value <= max) {
				updateState({ [name]: typeCast(value, leastCount).toString() })
				// setValue(value)
			} else {
				setValue(min)
				updateState({ [name]: typeCast(min, leastCount).toString() })
				toast.warn("Out of range.", {icon: toastWarn()})
			}
		}
	}

	const editForm = () => {
		return <div className="res-fnt-20 cs-medium d ai-center" style={{gridGap: '5px'}}>
			{edit && <><input onBlur={onBlur} disabled={!edit} className={"cs-actuator-input " + (edit && "cs-actuator-input-active")}
				value={value} onChange={(e) => edit && onChange(e)} /><div id="cs-actuator-input-unit">{unitMapping[config.unit] || config.unit}</div></>}
			{!edit && `${typeCast(value, leastCount)} ${unitMapping[config.unit] || config.unit}`}
		</div>
	}

	const handleEdit = (edit) => {
		if (edit) {
			setEdit(true)
		} else {
			setEdit(false)
		}
	}

	const changeCheckbox = (val) => {
		if(stateData.hasOwnProperty("ON/OFF Control"))
			handleCheck(config.name, val.target.checked)
		else{
			if(stateData['Operating Mode'] === "OFF")
				toast.warn("To change Temperature, first you need to change Operating Mode from OFF", {icon: toastWarn()})
			else
				handleCheck(config.name, val.target.checked)
		}
	}

	return (
		<div className="display-column ai-center" style={{height: '200px', gap: 38}}>
			<div className="d ai-center" style={{textAlign: 'center'}}>
				<div className="res-fnt-20 cs-medium" style={{height: '60px', textAlign: 'center', lineHeight: '22px'}}>
					{getTranslation(config.name)}
				</div>
				<div className="ml-10">
					{(!hideCheck && handleCheck) ? <Checkbox checked={isEdit}
						onChange={changeCheckbox} /> : null}
				</div>
			</div>
			<div className="d jc-sb ai-center">
				{failed ? <FailedDataScreen /> : isOldData ? <OldDataScreen /> : <div className="d" style={{gridGap: 10}}>
					<MinusOutlined
						onClick={() => onPressKeys()}
						twoToneColor="#eb2f96"
						className={
							"icon-wrapper-small mb-5 " + (value - leastCount < min && "cs-disable")
						}
						style={{ zIndex: 99, lineHeight: '25px' }}
					/>
					<div
						className="d jc-c ai-center res-fnt-20 cs-medium" style={{width: '90px'}}>
						{isEdit ? editForm() : value}
					</div>
					<PlusIcon
						onClick={() => onPressKeys(true)}
						twoToneColor="#eb2f96"
						className={
							"icon-wrapper-small mb-5 plus-icon-color " +
							(value + leastCount > max && "cs-disable")
						}
					/>
				</div>}
			</div>
		</div>
	);
};

export const DiscreteWidget = ({ config, stateData, updateState, isEdit, isOverride, handleCheck }) => {
    const { getTranslation } = useTranslation();
	const { name, isOldData, failed } = config;
	const [mode, setMode] = useState("");
	let configProperties = config['properties'].hasOwnProperty("input_string_list") ? config['properties']['input_string_list'] : []

	const handleMode = (val) => {
		setMode(val)
		updateState({ [name]: val })
	}

	const parse = (val) => {
		setMode(val)
	}

	useEffect(() => {
		if (stateData && stateData[name]) {
			parse(stateData[name])
		} else {
			if (isOverride) {
				if(configProperties.includes("OFF") && !configProperties.includes("AUTO"))
					updateState({ [name]: "OFF" })
				else if(configProperties.includes("AUTO"))
					updateState({ [name]: "AUTO" })
				else if(configProperties.includes("OCCUPIED"))
					updateState({ [name]: "OCCUPIED" })
				else
					updateState({ [name]: configProperties?.[0] || "FAN" })
			}
		}
	}, [stateData])

	const renderAccordingToProperties = () => {
		return(
			<>
				<div className="d" style={{ cursor: "pointer", marginTop: '15px' }}>
					<Select
						onChange={val => handleMode(val)}
						value={mode}
						suffixIcon={<DownArrowGreyIcon />}
						className="add-control-discrete-select"
					>
						{
							configProperties.map(property => <Select.Option value={property}>{property}</Select.Option>)
						}
					</Select>
				</div>
			</>
		)
	}

	const OverrideView = () => {
		return (
			<div className="display-column ai-center">
					<div className="mb-10 d ai-center">
						<div className="res-fnt-20 cs-medium" style={{height: '60px', textAlign: 'center', lineHeight: '22px'}}>
							{name}
						</div>
						<div className="ml-10">
							{handleCheck ? <Checkbox checked={isEdit}
								onChange={(val) => handleCheck(config.name, val.target.checked)} /> : null}
						</div>
					</div>
				{failed ? <FailedDataScreen /> : isOldData ? <OldDataScreen /> : <>
					<div className="add-control-discrete-mode-value">{mode}</div>
				</>}
			</div>
		)
	}
	return isEdit ?
		<div className="display-column ai-center" style={{height: '200px', gridGap: '15px'}}>
			<div className="mb-10 d ai-center">
				<div className="res-fnt-20 cs-medium" style={{height: '60px', textAlign: 'center', lineHeight: '22px'}}>
					{name}
				</div>
				<div className="ml-10">
					{handleCheck ? <Checkbox checked={isEdit}
						onChange={(val) => handleCheck(config.name, val.target.checked)} /> : null}
				</div>
			</div>
			{renderAccordingToProperties()}
		</div>
		: <OverrideView />
};

export const TempratureMode = () => {
	return <p className="custom-input">30</p>;
};

export const ThresholdWidget = ({ config, stateData, updateState, isOverride }) => {
    const { getTranslation } = useTranslation();
	const { name, properties } = config;
	const min = properties?.[getCurrentUnitSystem()]?.min_measured || properties.min_measured || 0;
	const max = properties?.[getCurrentUnitSystem()]?.max_measured || properties.max_measured || 100;

	const [lowValue, setLowValue] = useState(min);
	const [highValue, setHighValue] = useState(max);

	const [lowSlider, setLowSlider] = useState(false)
	const [highSlider, setHighSlider] = useState(false)

	useEffect(() => {
		if (stateData && stateData[name]) {
			setLowValue(stateData[name][0])
			setHighValue(stateData[name][1])
		}
	}, [stateData])


	useEffect(() => {
		updateState({ [name]: [lowValue, highValue] })
	}, [lowValue, highValue])

	const SlderWidget = ({ min, max, value, type }) => {
		const marks = {
			min: `${min}`,
			max: `${max}`,
		};

		const setValue = (value) => {
			if (type === "low") {
				setLowValue(value)
			} else {
				setHighValue(value)
			}
		};

		return (
			<div className="slider-wrapper">
				<Slider min={min} onChange={(val) => setValue(val)}
					max={max} defaultValue={value} />
			</div>
		)
	}
	return (
		<div className="display-column ai-center">
			<div className="mb-10">
				<div className="res-fnt-20 cs-medium" style={{height: '60px', textAlign: 'center', lineHeight: '22px'}}>
				{getTranslation(name)}
				</div>
			</div>
			<div className="d ai-center  mb-10 mt-20">
				<div value={lowValue}
					onClick={() => {
						setLowSlider(!lowSlider)
						setHighSlider(false)
					}} className="custom-input" >{lowValue}</div>
				<div className="threshold-btw-line" />
				<div value={highValue}
					onClick={() => {
						setHighSlider(!highSlider)
						setLowSlider(false)
					}} className="custom-input" >{highValue}</div>
			</div>
			{lowSlider && <div>
				<SlderWidget min={min} max={highValue} value={lowValue} type="low" />
			</div>}
			{highSlider && <div>
				<SlderWidget min={lowValue} max={max} value={highValue} type="high" />
			</div>}
			{!(lowSlider || highSlider) && <div className="d">
				<h4 className="m-0">Low</h4>
				<div style={{ width: 50 }}></div>
				<h4 className="m-0">High</h4>
			</div>}
		</div>
	)
}


export const BinaryWidget = ({ config, stateData, updateState, isEdit, isOverride }) => {
    const { getTranslation } = useTranslation();
	const { name, properties, isOldData, failed } = config;
	const trueValue = properties.true_string || "1";
	const falseValue = properties.false_string || "0";

	useEffect(() => {
		if (stateData && stateData[name]) {
			if (String(parseInt(stateData[name])) === trueValue)
				setValue(true)
			else setValue(false)
		} else {
			if (isOverride) {
				updateState({ [name]: falseValue })
			}
		}
	}, [stateData])

	const [value, setValue] = useState(false);

	const toggle = () => {
		updateState({ [name]: !value ? trueValue : falseValue })
		setValue(!value);
	};

	return (
		<div className="display-column ai-center">
			<div className="res-fnt-20 cs-medium" style={{height: '60px', textAlign: 'center', lineHeight: '22px'}}>
				{getTranslation(name)}
			</div>
			<div className="d ai-center jc-c mb-10 ">
				{failed ? <FailedDataScreen /> : isOldData ? <OldDataScreen /> : <>
					<Switch disabled={!isEdit} className="mt-20" checked={value} onChange={toggle} style={{marginTop: 45}}/>
					<h3 className="ml-5 mt-20" style={{marginTop: 45}}>{value ? getTranslation("ON") : getTranslation("OFF")}</h3>
				</>}
			</div>
		</div>
	)
}


export const MultiWidget = ({ configForFirst, configForSecond, maxLen, idx, stateData, updateState, isOverride, handleCheck, hideCheck }) => {
	const [currentType, setCurrentType] = useState();

	const shouldLine = (widget) => (
		<>
			{widget}
			{idx !== maxLen - 1 && <div className="horizontal-hr" />}
		</>
	)

	const handleMultiCheck = (name, val) => {
		let resp = handleCheck(name, val);
		if (resp && !resp.checkStatus) return;
		else handleCheck(name === configForFirst.name ? configForSecond.name : configForFirst.name, false);
	}

	useEffect(() => {
		if (hideCheck && configForFirst.live_device_mode) {
			if (configForFirst.live_device_mode === configForFirst.mode)
				setCurrentType(configForFirst.name)
			else if (configForFirst.live_device_mode === configForSecond.mode)
				setCurrentType(configForSecond.name)
		} else if (hideCheck) {
			//do nothing
		} else
			setCurrentType(configForFirst.name)
	}, [])

	return shouldLine(<div>
		{currentType === configForFirst.name && <RenderActuatorWidget isEdit={configForFirst.isEdit}
			actuationConfig={configForFirst} isOverride={isOverride} idx={idx} key={idx} maxLen={maxLen}
			updateState={(invidualState) => updateState(invidualState)} handleCheck={handleMultiCheck}
			stateData={stateData}
			hideCheck={hideCheck} />
		}
		{currentType === configForSecond.name && <RenderActuatorWidget isEdit={configForSecond.isEdit}
			actuationConfig={configForSecond} isOverride={isOverride} idx={idx} key={idx} maxLen={maxLen}
			updateState={(invidualState) => updateState(invidualState)} handleCheck={handleMultiCheck}
			stateData={stateData}
			hideCheck={hideCheck} />
		}
		{currentType ? <div className="d jc-c ai-center">
			<div onClick={() => setCurrentType(configForFirst.name)} className={"cs-sub-title cs-medium cur mr-15 multi-widget " +
				(currentType === configForFirst.name && "multi-widget-active mr-10") + (hideCheck && " no-clicks")}>
				{toggleMapping[configForFirst.name] || configForFirst.name}</div>
			<div onClick={() => setCurrentType(configForSecond.name)} className={"cs-sub-title cs-medium cur multi-widget " +
				(currentType === configForSecond.name && "multi-widget-active ml-10") + (hideCheck && " no-clicks")}>
				{toggleMapping[configForSecond.name] || configForSecond.name}</div>
		</div> :
			<div className="display-column ai-center" >
				<div className="d ai-center">
					<div className="res-fnt-20 cs-medium">
						{configForSecond.name}
					</div>
				</div>
				<div className="d jc-sb ai-center">
					<NoLiveDataIcon style={{ fontSize: 100 }} />
				</div>
			</div>
		}
	</div>)

}

export const RenderActuatorWidget = ({ actuationConfig, idx, maxLen, stateData, updateState, isEdit, isOverride, handleCheck, hideCheck }) => {

	const shouldLine = (widget) => (
		<>
			{widget}
			{/* {idx !== maxLen - 1 && <div className="horizontal-hr" />} */}
		</>
	)

	// run callback on useeffect
	switch (actuationConfig.type) {
		case "Binary":
			return shouldLine(<BinaryWidget isOverride={isOverride}
				config={actuationConfig} isEdit={isEdit} handleCheck={handleCheck} stateData={stateData}
				updateState={updateState} />)
		case "Threshold":
			return shouldLine(<ThresholdWidget isOverride={isOverride}
				config={actuationConfig} isEdit={isEdit} handleCheck={handleCheck} stateData={stateData}
				updateState={updateState} />)
		case "Continuous":
			return shouldLine(<ContinousWidget isOverride={isOverride}
				config={actuationConfig} isEdit={isEdit} handleCheck={handleCheck} stateData={stateData}
				updateState={updateState}
				hideCheck={hideCheck} />)
		case "Discrete":
			return shouldLine(<DiscreteWidget isOverride={isOverride}
				config={actuationConfig} isEdit={isEdit} handleCheck={handleCheck} stateData={stateData}
				updateState={updateState} />)
		default:
			return <></>
	}
};
