import toast_success from "../imgs/toast_success.png"
import toast_error from "../imgs/toast_error.png"
import toast_warn from "../imgs/toast_warn.png"

export const toastSuccess = () => {
    return <img 
        src={toast_success} 
        style={{background: '#4CAF51', height: '16px', width: '16px', borderRadius: '50%', padding: '4px'}}
    />
}

export const toastError = () => {
    return <img 
        src={toast_error} 
        style={{background: '#F00A19', height: '16px', width: '16px', borderRadius: '50%', padding: '4px'}}
    />
}

export const toastWarn = () => {
    return <img 
        src={toast_warn} 
        style={{height: '16px', width: '16px'}}
    />
}