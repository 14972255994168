import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { DatePicker } from '../dayjsToMoment';
import { FrequencyView } from './frequencyView'
import { Actuator } from './actuator';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _isEmpty from 'lodash/isEmpty'
import { addNewFrequency, addNewState, updateEditStatus, updateEndTime, updateStartTime } from '../../actions/newSchedulesActions';
import { GetDuplicateModal } from './duplicate';
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import { ArrowLeftIcon, CloseIcon, PlusIcon } from '../../assests/icon';
import { useTranslation } from '../../context/translationContext';

const { RangePicker } = DatePicker;


function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const EditView = ({ callback, scheduleId, goBackToHome }) => {
    const { getTranslation } = useTranslation();
    const [startDate, setStartDate] = useState(moment())
    const [endDate, setEndDate] = useState(moment())
    const [scheduleData, setScheduleData] = useState(null)
    // TODO: make it come from currEdit
    const [selectedFreq, setSelectedFreq] = useState(null)
    const [duplicateModal, setDuplicateModal] = useState(false)

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
    const editStatus = useSelector(state => state.newScheduleStore.currEdit)
	const dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch()


    useEffect(() => {
        if (!_isEmpty(newScheduleStore)) {
            if (newScheduleStore[scheduleId]) {
                setScheduleData(newScheduleStore[scheduleId])
                const { start_date, expiry_date, frequency } = newScheduleStore[scheduleId]
                if (start_date)
                    setStartDate(start_date)
                if (expiry_date)
                    setEndDate(expiry_date)
                if (frequency.length === 0)
                    handleNewFreq()
            }
        } else {
            setScheduleData(null)
            callback(null)
        }
    }, [newScheduleStore, scheduleId])

    useEffect(() => {
        if (!_isEmpty(editStatus)) {
            const { freqId } = editStatus;
            if (editStatus.scheduleId === scheduleId || editStatus.scheduleId === null) {
                setSelectedFreq(freqId);
            }
        }
    }, [editStatus, scheduleId])

    const onChangeTime = (dateArray) => {
        if (dateArray[0])
            dispatch(updateStartTime({ key: scheduleId, startDate: moment(dateArray[0]).format("YYYY-MM-DD") }))
        if (dateArray[1])
            dispatch(updateEndTime({ key: scheduleId, endDate: moment(dateArray[1]).format("YYYY-MM-DD") }))
    }

    const handleClose = () => {
        dispatch(updateEditStatus({ scheduleId: null, freqId: null, stateId: null }))
        callback(null)
    }

    const handleNewFreq = () => {
        dispatch(addNewFrequency({ key: scheduleId }))
    }

    const renderView = () => {
        return (
            <div className="display-column" style={{ height: "100%", minHeight: '80vh' }} >
                <div className="d jc-sb mb-10">
                    <ArrowLeftIcon className="icon-small-wrapper d-flex ant-row-center ant-row-middle" onClick={() => handleClose()} />
                    <CloseIcon className="icon-small-wrapper d-flex ant-row-center ant-row-middle" onClick={() => goBackToHome()} />
                </div>
                <div>
                    <div className="d jc-sb">
                        <div className="d">
                            <div className="mr-10">
                                <RangePicker 
                                popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                                    dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                                suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                                separator={
                                    <img
                                        src={
                                        !_isEmpty(dashboard && dashboard?.customerTheme) &&
                                        dashboard?.customerTheme === "schneider"
                                            ? DateRangeSeparatorSE : 
                                            dashboard?.customerTheme === "mcdonalds"
                                            ? DateRangeSeparatorMCD
                                            : DateRangeSeparator
                                        }
                                        alt='ant-date-range-separator'
                                    />
                                } format="YYYY-MM-DD" disabledDate={disabledDate} value={[moment(startDate), moment(endDate)]}
                                    onChange={onChangeTime} allowClear={false} className="red" />
                            </div>
                            <div>
                                <Button className="yellow-button" onClick={() => handleNewFreq()} ><PlusIcon className="plus-icon-yellow" />{getTranslation('Add Frequency')}</Button>
                            </div>
                        </div>
                        <div>
                            <Button onClick={() => setDuplicateModal(true)}
                                className="red-light-button">{getTranslation('Duplicate Schedule')}</Button>
                        </div>
                    </div>
                    <div style={{ maxHeight: '70vh', height: '100%', overflowY: 'auto' }}>
                        {scheduleData && scheduleData.frequency.map((data, idx) => selectedFreq === null ?
                            <FrequencyView deleteTrue={scheduleData.frequency.length - 1}
                                scheduleId={scheduleId} freqId={idx} key={idx} freqData={data} />
                            : selectedFreq === idx &&
                            <FrequencyView
                                isActive={true} key={idx} deleteTrue={scheduleData.frequency.length - 1}
                                scheduleId={scheduleId} freqId={idx} freqData={data} />)}
                    </div>
                    {selectedFreq !== null && <Actuator scheduleData={scheduleData}
                        scheduleId={scheduleId} onEditCallback={() => handleEdit(null)} />}
                </div>
                <GetDuplicateModal handleClose={() => setDuplicateModal(false)}
                    duplicateModal={duplicateModal} scheduleId={scheduleId} />
            </div>
        )
    }

    return (
        <div>
            {renderView()}
        </div>
    )
}
