import React, { useEffect, useState } from 'react'
import { AllCalendar } from '../../components/allCalendar/AllCalendar'
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import { AddControls } from '../../components/addControl'
import { useDispatch, useSelector } from 'react-redux'
import { getExpectedSchedule } from '../../elements/apis/controlApis'
import { ArrowUpOutlined } from '@ant-design/icons'
import { getEventFromYearObject } from '../../lib/getEventHelper'
import { updateEventsDate } from '../../actions/yearActions'
import { toast } from 'react-toastify'
import { toastError } from '../../toast/toast'
import ArrowUp from "../../imgs/ArrowUp.svg"
import { useTranslation } from '../../context/translationContext'
import { getCurrentUnitSystem } from '../../lib/function-helpers'

export const ConfigSchedules = () => {
    const { getTranslation } = useTranslation();
	const [currentView, setCurrentView] = useState('calendar')
	const [eventsData, setEventsData] = useState([])
	const [equipmentName, setequipmentName] = useState(null)

	const equipmentReducer = useSelector(state => state.equipmentReducer)
	const dispatch = useDispatch();

	useEffect(() => {
		if (equipmentReducer.clientName && equipmentReducer.equipmentName) {
			setequipmentName(equipmentReducer.equipmentName)
			init(equipmentReducer.equipmentName, equipmentReducer.actualClientName)
		}
	}, [equipmentReducer.equipmentName])

	const init = async (equipmentName, clientName) => {
		let res = []
		try {
			let resp = await getExpectedSchedule({ equipmentName, clientName , unit_type:getCurrentUnitSystem()})
			if (resp && resp.data) {
				resp.data = JSON.parse(resp.data);
				if (!_isEmpty(resp.data)) res = resp.data
			} else {
				toast.error(resp.message, {icon: toastError()})
				setEventsData([])
				dispatch(updateEventsDate({ eventsDate: [], "legendData": [] }))
				return;
			}

			let [eventsDate, allLegendMapping] = getEventFromYearObject(res);
			setEventsData(eventsDate)
			dispatch(updateEventsDate({ eventsDate, "legendData": allLegendMapping }))

		} catch (err) {
			console.error(err)
			setEventsData([])
			dispatch(updateEventsDate({ eventsDate: [], "legendData": [] }))
		}

	}

	const cancel = () => setCurrentView('calendar')

	const getView = () => {
		if (currentView === 'calendar')
			return <div className="wrapper" > <AllCalendar eventsData={eventsData} addControls={setCurrentView} /> </div>
		else
			return <div className="wrapper" > <AddControls cancel={cancel} init={init} /> </div>
	}

	return (
		<>
			{equipmentName ? getView() : <div className="d ai-center jc-c cs-title"
				style={{ width: '100%', height: '80%', textAlign: 'center' }} >
				{getTranslation('Select Site & Equipment to View Controls ')}<img src={ArrowUp} alt="arrow-up-icon" />
			</div>}
		</>
	)
}
