/*
*
* Dashboard state object
*
* */
import update from 'immutability-helper';


const dashboardReducer = (state = {
    activeDashboard: {},
    dashboardConfig: {},
    globalFilters: { site: '', siteObject: {} },
    termsAndConditions: null,
    userAccessibleCustomers: {},
    customerTheme: '',
    sessionExpired: false
}, action) => {
    switch (action.type) {
        case "CLEAR_DASHBOARD":
            state = {
                view: 'ControlApp'
            };
            
            break;
        case "SET_DASHBOARD_CONFIG":
            state = update(state, {
                dashboardConfig: {
                    $set: action.payload
                }
            })
            break;
        
        case "SET_GLOBAL_FILTERS":
            state = update(state, {
                globalFilters: {
                    $set: action.payload
                }
            })
            break;
        
        case "SET_ACTIVE_DASHBOARD":
            state = update(state, {
                activeDashboard: {
                    $set: action.payload
                }
            })
            break;
        case "UPDATE_USER_ACCESSIBLE_CUSTOMERS":
            state = {
                ...state,
                userAccessibleCustomers: action.payload
            }
            break;
        case "UPDATE_TERMS_AND_CONDITIONS":
            state = {
                ...state,
                termsAndConditions: action.payload
            }
            break;
        case "UPDATE_CUSTOMER_THEME":
            state = {
                ...state,
                customerTheme: action.payload
            }
            break;
        case "UPDATE_SESSION_EXPIRED":
            state = update(state, {
                sessionExpired: {
                    $set: action.payload
                }
            })
            break;
        default:
            break;
    }
    return state;
};


export default dashboardReducer;
