/*
 *
 * Dashboard state object
 *
 * */

import moment from "moment";

const addNewStateHelper = (freq, freqId, newState) => {
	for (var i in freq) {
		if (i == freqId) {
			freq[i].schedules = [...freq[i].schedules, newState];
			break;
		}
	}
	return freq
}


const deleteStateHelper = (freq, freqId, stateId) => {
	let tempFreq = freq
	for (var i in tempFreq) {
		if (i == freqId) {
			tempFreq[i].schedules.splice(stateId, 1);
			break;
		}
	}
	return tempFreq
}

const updateStateHelper = (freq, freqId, stateId, newState) => {
	let tempFreq = freq
	for (var i in tempFreq) {
		if (i == freqId) {
			tempFreq[i].schedules[stateId] = newState;
			break;
		}
	}
	return tempFreq
}

const duplicateScheduleHelper = (reqSchedule, scheduleCount, timeFrames) => {
	let tempScheduleCount = scheduleCount;
	let tempSchedules = {}
	for (let timeFrameCount = 0; timeFrameCount < timeFrames.length; timeFrameCount++) {
		Object.assign(tempSchedules, {
			[`schedule${++tempScheduleCount}`]: {
				...reqSchedule, ...timeFrames[timeFrameCount]
			}
		})
	}
	return tempSchedules
}

const updateFreqHelper = (freq, freqId, newFreqTime) => {
	let tempFreq = freq
	for (var i in tempFreq) {
		if (i == freqId) {
			if (newFreqTime.isDate) {
				if (tempFreq[i].days_of_week)
					delete tempFreq[i].days_of_week
				Object.assign(tempFreq[i], { ...newFreqTime })
			} else {
				if (tempFreq[i].date)
					delete tempFreq[i].date
				Object.assign(tempFreq[i], { ...newFreqTime })
			}
		}
	}
	return tempFreq
}

const deleteFreqHelper = (freq, freqId) => {
	let tempFreq = [...freq.slice(0, freqId), ...freq.slice(freqId + 1)]
	return tempFreq
}

const deleteScheduleHelper = (schedule, scheduleId) => {
	delete schedule[scheduleId];
	return schedule
}

const newSchedulesReducer = (
	state = {
		schedules: {},
		currEdit: {
			scheduleId: null,
			freqId: null,
			stateID: null
		},
		lastFreqId: null
	},
	action
) => {
	switch (action.type) {
		case "CLEAR_NEW_SCHEDULES":
			state = {
				...state, schedules: {}, currEdit: {
					scheduleId: null,
					freqId: null,
					stateID: null
				}
			}
			break;
		case "UPDATE_NEW_SCHEDULES":
			state = { ...state, schedules: action.payload }
			break;
		case "ADD_NEW_SCHEDULES":
			state = {
				...state, schedules: {
					...state.schedules,
					[action.payload.key]: {
						"start_date": null,
						"expiry_date": null,
						"frequency": []
					}
				},
				lastFreqId: null
			}
			break;
		case "DELETE_SCHEDULES":
			state = {
				...state, schedules: {
					...deleteScheduleHelper(state.schedules,
						[action.payload.key])
				}
			}
			break;
		case "DUPLICATE_SCHEDULES":
			state = {
				...state, schedules: {
					...state.schedules,
					...duplicateScheduleHelper(state.schedules[action.payload.key],
						action.payload.schedulesCount, action.payload.timeFrames)
				},
				lastFreqId: null
			}
			break;
		case "ADD_NEW_FREQUENCY":
			state = {
				...state, schedules: {
					...state.schedules,
					[action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...state.schedules[action.payload.key].frequency,
						{
							isDate: true,
							date: null,
							schedules: []
						}]
					}
				},
				lastFreqId: null
			}
			break;
		case "DELETE_FREQUENCY":
			state = {
				...state, schedules: {
					...state.schedules,
					[action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...deleteFreqHelper(state.schedules[action.payload.key].frequency,
							action.payload.freqId)]
					}
				},
				lastFreqId: null
			}
			break;
		case "UPDATE_START_TIME":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"start_date": action.payload.startDate
					}
				}
			}
			break;
		case "UPDATE_END_TIME":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"expiry_date": action.payload.endDate
					}
				}
			}
			break;
		case "ADD_STATE":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...addNewStateHelper(state.schedules[action.payload.key].frequency,
							action.payload.freqId, action.payload.newState)]
					}
				},
				currEdit: {
					scheduleId: null,
					freqId: null,
					stateID: null
				}
			}
			break;
		case "DELETE_STATE":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...deleteStateHelper(state.schedules[action.payload.key].frequency,
							action.payload.freqId, action.payload.stateId)]
					}
				},
				currEdit: {
					scheduleId: null,
					freqId: null,
					stateID: null
				}
			}
			break;
		case "UPDATE_STATE":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...updateStateHelper(state.schedules[action.payload.key].frequency,
							action.payload.freqId, action.payload.stateId, action.payload.newState)]
					}
				}
			}
			break;
		case "UPDATE_FREQUENCY_TIME":
			state = {
				...state,
				schedules: {
					...state.schedules, [action.payload.key]: {
						...state.schedules[action.payload.key],
						"frequency": [...updateFreqHelper(state.schedules[action.payload.key].frequency,
							action.payload.freqId, action.payload.newFreqTime)]
					}
				},
				lastFreqId: action.payload.freqId
			}
			break;
		case "UPDATE_EDIT":
			state = {
				...state,
				currEdit: { ...action.payload }
			}
			break;
		default:
			break;
	}
	return state;
};

export default newSchedulesReducer;
