import { Tag } from "antd";
import FilterIcon from "../../imgs/FilterIcon.svg"

const getFilterOptions = (columnName, allData, getTranslation) => {
    let res = [];
    let alreadyPushed = {};
    allData.map(data => {
        if (data[columnName] && alreadyPushed[data[columnName]] === undefined) {
            Object.assign(alreadyPushed, { [data[columnName]]: true })
            res.push({ "text": getTranslation(data[columnName]), "value": getTranslation(data[columnName]) });
        }
    })
    return res;
}

const columnsLayout = (displayName, indexName, allData, getTranslation) => {
    return {
        title: getTranslation(displayName),
        dataIndex: indexName,
        key: indexName,
        filters: getFilterOptions(indexName, allData, getTranslation),
        onFilter: (value, record) => record[indexName] && record[indexName].indexOf(value) === 0,
    }
}

export const antdTableHelper = (dataSource, callback, currentData, oldcolumn, isChanged, getTranslation) => {
    let column = [];
    let columnName = []
    let data = dataSource;
    if(!isChanged){
        column.push(...oldcolumn)
        column.forEach(col => col.hasOwnProperty('filteredValue') && delete col['filteredValue'])
        callback(column)
        return;
    }
    // if (extraCol && extraCol.length) {
    //     columnName.push(...extraCol.map(data => { return { displayName: data, indexName: data } }))
    // }

    columnName = [...columnName, {
        displayName: 'Equipment Type',
        indexName: 'equipmentType'
    }, {
        displayName: 'Equipment Name',
        indexName: 'equipmentName'
    },]

    column = columnName.map(({ displayName, indexName }) => columnsLayout(displayName, indexName, data, getTranslation))

    let extra = [{
        title: getTranslation('Schedule Deployment Status'),
        dataIndex: 'deploymentStatus',
        filters: getFilterOptions("deploymentStatus", data, getTranslation),
        onFilter: (value, record) => record["deploymentStatus"].indexOf(value) === 0,
        render: (data) => <>{data}</>
    },{
        title: getTranslation('Equipment Schedule Status'),
        dataIndex: 'control',
        filters: getFilterOptions("control", data, getTranslation),
        onFilter: (value, record) => record["control"].indexOf(value) === 0,
        render: (data) => <>{data}</>
    }]
    column.push(...extra);
    column.forEach(col => {
        col['filterIcon'] = <img src={FilterIcon} alt="filter-icon" />
        col['filteredValue'] = null
    })
    callback(column)
}