import React, { Component, useEffect, useState } from 'react';
import { Redirect, Router, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import { ToastContainer } from 'react-toastify';
import 'react-datasheet/lib/react-datasheet.css';
import './assests/css/antd.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

import PrivateRoute from './custom-routes/PrivateRoute'; // route only accessible when loggedin
import PublicRoute from './custom-routes/PublicRoute'; // route only accessible when not loggedin

import { REACT_APP_EUROPE_DOMAIN_NAME_KEY } from './lib/constants.js';
import NewLogin from "./components/login/newLogin.jsx"
import Login from './components/login/login';
import SampleView from './apps/ControlApp/controlApp';
import { history } from "./config/history";
import store from "./store";
import { loginStatus } from './elements/authentication/authentication.js';
import { updateLoginStatus, updatePathname } from './actions/appActions.js';
import { Spin } from 'antd';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import toast_close from "../src/imgs/toast_close.png"
import CustomError from './components/customError';
import { DEFAULT_LANGUAGE, TranslationProvider } from './context/translationContext';
// for preventing delay of timer in login otp page
import HackTimer from "./scripts/hacktimer/HackTimer";

// Language translation for Antd components
import { ConfigProvider } from 'antd';
import frFR from 'antd/es/locale/fr_FR'; // Import French locale from Ant Design
import enUS from 'antd/es/locale/en_US'; // Import English locale from Ant Design

// let location = history.location;

export function checkSessionFromServer() {
    let pathname = window.location.pathname;
    loginStatus().then(resp => {
        if(resp.allow){
            let uuid = uuidv4()
            !localStorage.getItem("uuid") && localStorage.setItem("loginTime", moment().format("YYYY-MM-DD HH:mm:ss"));
            !localStorage.getItem("uuid") && localStorage.setItem("uuid", uuid)
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'login',
                'userId': resp.userId,
                'source': 'Schedule Dashboard',
                'uuid': uuid,
                'time': moment().format("YYYY-MM-DD HH:mm:ss"),
                'customer': resp.customer
            })
        } else {
            localStorage.removeItem("loginTime");
        }
        store.dispatch(
            updatePathname(pathname)
        );
        store.dispatch(
            updateLoginStatus(resp)
        );
    });
}


checkSessionFromServer();

export const storageChangeEvent = new Event('localStorageChange');
export const languageChangeEvent = new Event('languageChangeInLocalStorage');

const LoadingIndicator = props => {
    const [showLoader, setShowLoader] = useState(false)

    window.addEventListener('localStorageChange', (event) => {
        if(parseInt(sessionStorage.getItem("promiseTracker")) > 1)
            setShowLoader(true)
        else 
            setShowLoader(false)
    });

    window.onbeforeunload = function(){
        window.removeEventListener('localStorageChange', (event) => {
            if(parseInt(sessionStorage.getItem("promiseTracker")) > 1)
                setShowLoader(true)
            else 
                setShowLoader(false)
        });
    }

    return showLoader &&
        <div style={{
            top: 0,
            zIndex: 99999,
            position: "absolute",
            width: "100vw",
            height: "100vh",
            background: "#FFFFFF88"
        }}>
            <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} ><Spin size={'large'} /></span>
        </div>;
}

const CloseButton = ({ closeToast }) => (
    <div className="d ai-center">
      <img src={toast_close} onClick={closeToast} className="d ai-center" style={{height:'9px', cursor: 'pointer'}}/>
    </div>
);

const App = () => {
    const [antdLocale, setAntdLocale] = useState((localStorage.getItem("language") || DEFAULT_LANGUAGE));
    const isEUDashboardLogin = window.location.origin.toLowerCase().includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)

    const urlList = ['/', '/login', '/home', '/home/', '/home/configschedules', '/home/configschedules/', '/home/downloadschedules', '/home/downloadschedules/',
        '/home/controlstatus', '/home/bulkcontrol', '/home/instantcontrol', '/home/controlstatus/', '/home/bulkcontrol/', '/home/instantcontrol/', '/home/cpm/', '/home/cpm',
        '/home/controloverride', '/home/controloverride/', '/home/groups', '/home/groups/', '/home/setschedules', '/home/setschedules/',
        '/home/viewschedules', '/home/viewschedules/', '/home/viewsummary', '/home/viewsummary/', '/home/deploymentstatus', '/home/deploymentstatus/',
        '/home/holidaycalendar', '/home/holidaycalendar/'];

    useEffect(() => {
        const storageChangeEvent = new Event('localStorageChange');
        sessionStorage.setItem("promiseTracker", "1")
        storageChangeEvent.key = "promiseTracker"
        storageChangeEvent.value = 1
        window.dispatchEvent(storageChangeEvent)
    }, [])

    const isValidUrl = (url) => {
      return urlList.some((pattern) => {
        const hasTrailingSlash = pattern.endsWith('/');
        const regexPattern = pattern
          .replace(/:[^\s/]+/g, '[^/]{24}') // this regex is use for handling dynamic routes
          .replace(/\/$/, '') // Remove trailing slash from the pattern
          .replace(/\//g, '\\/'); // Escape slashes for regex
    
        const regex = new RegExp(`^${regexPattern}${hasTrailingSlash ? '\\/?' : ''}$`);
        return regex.test(url);
      });
    }

    if (!isValidUrl(window.location.pathname)) {
      return <CustomError />
    }

    window.addEventListener("languageChangeInLocalStorage", (event) => {
      setAntdLocale(localStorage.getItem("language"));
    });

    window.onbeforeunload = function () {
      window.removeEventListener("languageChangeInLocalStorage", (event) => {
        setAntdLocale(localStorage.getItem("language"));
      });
    };

    return (
        <div className="App">
            <ConfigProvider locale={antdLocale === 'en' ? enUS : frFR}>
                <TranslationProvider>
                    <Provider store={store}>
                        <Router history={history}>
                            <React.Fragment>
                                <Switch>
                                    <PublicRoute path="/login" component={isEUDashboardLogin ? NewLogin : Login} isOTPRequired={true}/>
                                    <PrivateRoute path="/home" component={SampleView} />
                                    <Redirect from='/' to='/home/' />
                                </Switch>
                            </React.Fragment>
                        </Router>
                    </Provider>
                    <ToastContainer
                        position="top-left"
                        autoClose={false}
                        newestOnTop
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        theme="light"
                        closeButton={CloseButton}
                        // transition={Slide}
                    />
                    <LoadingIndicator />
                </TranslationProvider>
            </ConfigProvider>
        </div>
        );
}

export default App;
