import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Dimmer, Form } from 'semantic-ui-react';
import { Spin, Select } from 'antd';
import { login, validateOTP, generateOTP } from '../../elements/authentication/authentication';
import { checkSessionFromServer } from '../../App';
import background from '../../imgs/newLoginBackground.png';
import { updateLoginStatus } from '../../actions/appActions';
import { ResetPasswordModal } from './forgotPassword';
import { RESET_OTP_TIMER, REACT_APP_PASSWORD_RESET_URLS, REACT_APP_EUROPE_DOMAIN_NAME_KEY } from '../../lib/constants';
import { toast } from "react-toastify";
import { toastError, toastSuccess } from '../../toast/toast';
import { DownArrowFilledIcon, NewLoginScreenFooterLogoIcon } from '../../assests/icon';
import logo_se from "../../imgs/logo-se.png"
import './login.css';
import "./newLogin.css"
import moment from 'moment';
import NewVerificationPage from './newVerificationPage';

const LoginForm = props => {


    const isLoggedIn = useSelector(state => state.appStatus.isLoggedIn)

    const [username, setusername] = useState('')
    const [password, setpassword] = useState('')
    const [OTP, setOTP] = useState('')
    const [error, seterror] = useState('')
    const [enableOTP, setEnableOTP] = useState(null);
    const [loginResp, setLoginResp] = useState(null);
    const [otpSource, setOTPSource] = useState("email")
    const [showVerificationPage, setShowVerificationPage] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [disableButton, setDisableButton] = useState(false)

    const [timer, setTimer] = useState(RESET_OTP_TIMER); // initial timer value of 15 seconds
    const [showTimer, setShowTimer] = useState(false); // whether or not to show the timer
    const dispatch = useDispatch()

    useEffect(() => {
        let intervalId;
        if (showTimer && timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer == 0) {
            setDisableButton(false)
            setShowTimer(false);
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showTimer, timer]);

    const setError = (errorString) => {
        if(errorString){
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'login failed',
              'userId': '',
              'source': 'Dashboard',
              'time': moment().format("YYYY-MM-DD HH:mm:ss"),
              'errorString': errorString
            })
        }
    }

    const sendOTP = async () => {
        try{
            setDisableButton(true)
            let resp = await generateOTP({channel: otpSource}, true);
            if (resp){
                toast.success("Verification code sent successfully", {icon: toastSuccess()})
                setTimer(RESET_OTP_TIMER); 
                setShowTimer(true)
            }
            else{
                setDisableButton(false)
            }
        } catch(e){
            console.log(e);
            setDisableButton(false)
        }
    };

    const submitForm = async () => {
        setIsLoading(true)
        let error = '';
        if (username !== '' && password !== '') {
            let resp = await login({ username, password });
            
            if(resp.allow){
                if(!resp.userType){
                    checkSessionFromServer(resp)
                    setIsLoading(false)
                    window.location.reload()
                    return
                }

                if (props.isOTPRequired) {
                    setLoginResp(resp);
                    if(resp.noOtp){
                        dispatch(updateLoginStatus(resp))
                        window.location.reload()
                    }else
                        setEnableOTP(resp);
                } else
                    dispatch(updateLoginStatus(resp))

            } else {
                if(resp.isRedirect && (resp.hasOwnProperty("isPhoneVerified") || resp.hasOwnProperty("isEmailVerified"))){
                    setLoginResp(resp);
                    setShowVerificationPage(true)
                } else if (resp.isRedirect){
                    if(window.location.href.includes("dev")){
                        if(window.location.href.includes("teleport"))
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withTeleport}?reset=true`)
                        else
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withoutTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withoutTeleport}?reset=true`)
                    } else if(window.location.href.includes("qa")){
                        if(window.location.href.includes("teleport"))
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withTeleport}?reset=true`)
                        else
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withoutTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withoutTeleport}?reset=true`)
                    } else {
                        window.location.replace(`${!window.location.origin
                            .toLowerCase()
                            .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                            ? REACT_APP_PASSWORD_RESET_URLS.india.prod.withoutTeleport
                            : REACT_APP_PASSWORD_RESET_URLS.europe.prod.withoutTeleport}?reset=true`)
                    }
                    setIsLoading(false)
                    return
                } else{
                    setError(resp.message || "Username or password is incorrect");
                    error = resp.message || "Username or password is incorrect"
                }
            }
        } else if (username === '' && (password === ''))
            error = 'Username and password can\'t be empty';
        else if (username === '')
            error = 'Username can\'t be empty';
        else
            error = 'Password can\'t be empty';
        seterror(error)
        setIsLoading(false)
    }

    const onFinishOTP = async () => {
        try{
            let resp = await validateOTP({OTP, channel: otpSource});
            if (resp) {
                dispatch(updateLoginStatus(loginResp))
                window.location.reload();
            } else {
                toast.error("Invalid code, please try again.", {icon: toastError()});
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (value) => {
        setOTPSource(value)
    }

    const handleOTPInput = (e) => {
        if(e.target.value.length < 7){
            setOTP(e.target.value)
        }
    }

    const getOTPFormFields = () => {
            return <>
                <div className='new-otp-container'>
                    <div className='new-otp-source-container'>
                        <Select
                            defaultValue={otpSource}
                            className='new-otp-source-dropdown'
                            onChange={handleChange}
                            suffixIcon={<DownArrowFilledIcon className="dropdown-arrow-icon"/>}
                            options={enableOTP?.hasOwnProperty('isPhoneVerified') ? [
                                {
                                    value: 'email',
                                    label: 'Email',
                                },
                                {
                                    value: 'sms',
                                    label: 'Phone',
                                },
                            ] : [
                                {
                                    value: 'email',
                                    label: 'Email',
                                }
                            ]}
                        />
                        <Button id={'new-otp-source-generate-btn'} onClick={sendOTP} style={disableButton ? {opacity: '0.4', pointerEvents: 'none'} : {opacity: '1'}}>
                            Send Code
                        </Button>
                    </div>
                    <div>
                        <Form.Input fluid iconPosition='left' placeholder='Enter Verification Code' id="new-OTP-input"
                            name="OTP" value={OTP} onChange={handleOTPInput} />
                    </div>
                    <div style={{textAlign: 'left', marginBottom: '20px'}} id="fs-12">
                        { showTimer && <span>If you didn't receive the verification code,  <span className={"additional-trigger-timer-count"}>retry in {timer} sec.</span> </span>}
                    </div>
                    <div className='new-submit-otp-container'>
                        <Button 
                            disabled = {!OTP || OTP.length !== 6}
                            type="primary" 
                            htmlType="submit" 
                            id={'new-submit-login'}
                            onClick={onFinishOTP}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
            </>
    }

    const renderOTPFrom = () => {
        return (
            <div className='login-form' style={{height: '100%', 'background': 'url("' + background + '")', backgroundSize: 'cover'}}>
                <div className='login-infomation'>
                    <div className='green-color-cover-container'>
                        <div className='transparent-green-cover'></div>
                        <div className='green-cover'></div>
                    </div>
                    <div className='login-logo-instruction-container'>
                        <div>
                            <img src={logo_se} style={{height: "36lvh",marginTop:"-12.5lvh",marginLeft:"-4lvw"}}/>
                        </div>
                        <div className={"new-login-form-container"}>
                            <div className={"new-login-instruction eu-login-form-title"}>
                                Verify your Identity
                            </div>
                            {getOTPFormFields()}
                        </div>
                    </div>
                </div>
                <div className='login-form-footer'>
                    <span id='login-form-footer-content'>
                        This application is protected by copyright law and international treaties. <br/>
                        © 2024 Schneider Electric Industries SAS. All Rights Reserved.
                    </span>
                    <div>
                        <NewLoginScreenFooterLogoIcon style={{width:"14.5vw"}}/>
                    </div>
                </div>
            </div>
        );
    }

    const renderLoginForm = () => {
        return (
            <div className='login-form' style={{ height: '100%', 'background': 'url("' + background + '")', backgroundSize: 'cover' }}>
                <div className='login-infomation'>
                    <div className='green-color-cover-container'>
                        <div className='transparent-green-cover'></div>
                        <div className='green-cover'></div>
                    </div>
                    <div className='login-logo-instruction-container'>
                        <div>
                            <img src={logo_se} style={{height: "36lvh",marginTop:"-12.5lvh",marginLeft:"-4lvw"}}/>
                        </div>
                        <div className={"new-login-form-container"}>
                            <Form size='large'>
                                <div>
                                    <label>
                                        <span id="color-imp-red">*</span> Email
                                    </label>
                                    <Form.Input
                                        fluid
                                        placeholder="E-mail"
                                        name="username"
                                        value={username}
                                        onChange={(e) => setusername(e.target.value)}
                                    />
                                    <label>
                                        <span id="color-imp-red">*</span> Password
                                    </label>
                                    <Form.Input
                                        fluid
                                        value={password}
                                        placeholder='Password'
                                        type='password'
                                        name='password'
                                        onChange={(e) => setpassword(e.target.value)}
                                    />
                                    {
                                        !!error && 
                                        <span id='error-msg'>
                                            {error}
                                        </span>
                                    }
                                    <Button id={"new-submit-login"} fluid size='large' onClick={submitForm}>
                                        Log In
                                    </Button>
                                    <div className="d-flex mb-8" >
                                        <ResetPasswordModal isNewLoginScreen={true}/>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <div className='login-form-footer'>
                    <span id='login-form-footer-content'>
                        This application is protected by copyright law and international treaties. <br/>
                        © 2024 Schneider Electric Industries SAS. All Rights Reserved.
                    </span>
                    <div>
                        <NewLoginScreenFooterLogoIcon style={{width:"14.5vw"}} />
                    </div>
                </div>
                {
                    isLoading && <Dimmer active={isLoading}>
                        <Spin size={'large'} className='new-login-screen-spinner'/>
                    </Dimmer>
                }
            </div>
        );
    }

    const renderBaseLoader = () => {
        return <div style={{ height: '100%' }}>
            <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}><Spin
                size={'large'} className='new-login-screen-spinner'/></span>
        </div>
    }

    if(showVerificationPage)
        return <NewVerificationPage 
            isPhoneVerified={loginResp["isPhoneVerified"]} 
            isEmailVerified={loginResp["isEmailVerified"]}
            username={username}
            password={password}
        />
    if (enableOTP)
        return renderOTPFrom();
    if (!isLoggedIn && isLoggedIn !== null)
        return renderLoginForm();
    else
        return renderBaseLoader();

}


export default LoginForm
