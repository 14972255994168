
export function clearEquipment() {
    return (dispatch, getState) => {
        dispatch({
            type: "CLEAR_EQUIPMENT"
        });
    }
}

export function setEquipment(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_EQUIPMENT",
            payload
        });
    }
}

