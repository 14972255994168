

import errorIcon from "../../imgs/404_error_icon.png"
import "./style.css"

const CustomError = () => {
    return(
        <div className="custom-error-container">
            <div className="custom-error-block">
                <img 
                    src={errorIcon} 
                    className="custom-error-img"
                />
                <div className="custom-error-404">404</div>
                <div className="custom-error-content1">Oops! Page Not Found</div>
                <div className="custom-error-content2">The page you are looking for doesn't seem to exist.</div>
            </div>
        </div>
    )
}

export default CustomError;