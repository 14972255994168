import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import _isEmpty from 'lodash/isEmpty'
import _map from 'lodash/map'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import { convertTime24to12 } from '../../lib/dateHelpers'
import { handleSoftMapping } from '../../lib/softMapping'
import { DeleteIcon, DeleteIconMCD, EditIcon } from '../../assests/icon'
import { useTranslation } from '../../context/translationContext'


const getRequiredEquipmentValues = (state, streamFunctions, getTranslation) => {
  return (
    !_isEmpty(state) &&
    _map(state, (value, key) => {
      let outputValue = handleSoftMapping(value, key, streamFunctions);
      if (outputValue.endsWith(" AM") || outputValue.endsWith(" PM")) {
        outputValue =
          outputValue.slice(0, outputValue.length - 2) +
          getTranslation(outputValue.slice(-2));
      }
      return (
        <h4 key={key} style={{ wordBreak: "break-word" }}>
          <span>{getTranslation(key)}</span>: {getTranslation(outputValue)}
        </h4>
      );
    })
  );
};


export const EventCard = ({ scheduleId }) => {
    const { getTranslation } = useTranslation();
    const [data, setData] = useState([]);

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
    const equipmentReducer = useSelector(state => state.equipmentReducer)

    useEffect(() => {
        if (!_isEmpty(newScheduleStore)) {
            if (scheduleId) {
                if (newScheduleStore[scheduleId]) {
                    let data = flatten(newScheduleStore[scheduleId]);
                    setData(data);
                } else setData([]);
            } else setData([])
        } else setData([])
    }, [newScheduleStore, scheduleId])


    const parseFull = {
        "Sunday": "Sun",
        "Monday": "Mon",
        "Tuesday": "Tue",
        "Wednesday": "Wed",
        "Thursday": "Thur",
        "Friday": "Fri",
        "Saturday": "Sat"
    };

    const flatten = (data) => {
        let result = [];
        let { frequency } = data;
        for (let i = 0; i < frequency.length; i++) {
            let freq = ""
            if (frequency[i].isDate)
                freq = frequency[i].date
            else
                freq = frequency[i].days_of_week.map(day => parseFull[day]).join(',')
            frequency[i].schedules.map(schedule => {
                result.push({
                    freq,
                    time: Object.keys(schedule)[0],
                    state: schedule[Object.keys(schedule)[0]]
                })
            })
        }
        return result;
    }

    return (
        data.length ? data.map(({ freq, time, state }, idx) => <div key={idx} className="wrapper mt-10 mr-10"
            style={{ width: 'auto', padding: '1em', minWidth: 200 }}>
            <div className="display-column">
                <div className="">
                    <h3 className="text-red" style={{ wordBreak: "break-word" }}>{getTranslation('Freq')}: {freq}</h3>
                </div>
                <div className="red-hr"></div>
                <div>
                    {getRequiredEquipmentValues({ ...state, Time: convertTime24to12(time, true) }, equipmentReducer.streamFunctions, getTranslation)}
                </div>
            </div>
        </div>) : <div>{getTranslation('No Event Found')}</div>
    )
}


export const SimpleEventCard = ({ schedulesState, scheduleStateId, deleteState, onEdit, isActive }) => {
    const { getTranslation } = useTranslation();
    const [time, setTime] = useState("00:00");
    const [acutators, setAcutators] = useState({})
    const equipmentReducer = useSelector(state => state.equipmentReducer)
    const dashboard = useSelector(state => state.dashboard);

    useEffect(() => {
        if (!_isEmpty(schedulesState)) {
            let tempTime = Object.keys(schedulesState)[0]
            setTime(tempTime)
            setAcutators(schedulesState[tempTime])
        }
    }, [schedulesState])

    return (
        <div className={"wrapper mt-10 mr-10 " + (isActive && "wrapper-active")}
            style={{ width: 'auto', minWidth: 200, padding: '1em', border: '1px solid #FFF' }}>
            <div className="display-column">
                <div className="d jc-sb ai-center">
                    <h3 className="text-red m-0" style={{ margin: 0 }}>{getTranslation('Event')} {scheduleStateId + 1}</h3>
                    <div className="d">
                        <EditIcon className="event-icon" onClick={() => onEdit()} />
                        {
                            (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color') ? 
                            <DeleteIconMCD onClick={() => deleteState()} className="ml-5 event-icon"/> :
                            <DeleteIcon onClick={() => deleteState()} className="ml-5 event-icon" />
                        }
                    </div>
                </div>
                <div className="red-hr"></div>
                {getRequiredEquipmentValues({ ...acutators, Time: convertTime24to12(time, true) }, equipmentReducer.streamFunctions, getTranslation)}
            </div>
        </div>
    )
}