import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import appStatus from "./reducers/appReducer.js";
import dashboard from "./reducers/dashboardReducer.js";
import newScheduleStore from './reducers/newSchedulesReducer'
import yearReducer from './reducers/yearReducer'
import equipmentReducer from './reducers/equipmentReducer'
import bulkReducer from './reducers/bulkReducer'
import instantControlReducer from "./reducers/instantControlReducer";
import controlStatusReducer from "./reducers/controlStatusReducer";
import { composeWithDevTools } from 'redux-devtools-extension';
import cpmReducer from "./reducers/cpmReducer.js";

export default createStore(combineReducers({
    appStatus, dashboard, newScheduleStore, yearReducer,
    equipmentReducer, bulkReducer, instantControlReducer, controlStatusReducer, cpmReducer
}),
    composeWithDevTools(applyMiddleware(thunk))
);
