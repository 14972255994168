import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import _isEmpty from "lodash/isEmpty";
import DashboardMenu from "../../components/dashboard-preview/dashboardMenu";
import {
	updateDashboardConfig,
	resetGlobalFilters
} from "../../actions/dashboardActions";

import { AppHeader } from "../../components/app-header/app-header";
import { controlScreens } from "../../lib/applicationLayoutFixtures";
import "react-toastify/dist/ReactToastify.css";
import "./controlApp.css";
import '../../assests/css/index.css'
import { SiteSelection } from "../../components/dashboard-preview/site-selection";
import { ConfigSchedules } from "../../views/Configure/ConfigSchedules";
import { SelectEquipment } from "../../components/dashboard-preview/equipment-select";
import { BulkControl } from "../../views/BulkControl";
import { NewInstantControl } from "../../views/InstantControl/newInstantControl";
import { ControlStatus } from "../../views/ControlStatus";
import { DownloadSchedules } from "../../views/DownlaodSchedules";
import { SelectEquipmentType } from "../../components/dashboard-preview/equipment-type";
import { BrandHeader } from "../../components/app-header/BrandHeader";
import { SelectSiteEquipmentType } from "../../components/dashboard-preview/site-equipment-type";
import { useIdleTimer } from 'react-idle-timer'
import { REACT_APP_EUROPE_DOMAIN_NAME_KEY, REACT_APP_V2_DASHBOARD_URL, REACT_APP_EUROPE_DEMO_DOMAIN_NAME_KEY } from "../../lib/constants";
import moment from 'moment';
import { Button, Modal } from 'antd';
import { TNCModalIcon } from "../../assests/icon";
import { OptOutTNC } from "../../components/terms-and-condition";
import { responseLogout } from "../../components/app-header/BrandHeader";
import NoPermission from "../../views/NoPermission";
import "./style.css"
import { useTranslation } from "../../context/translationContext";
import { CPM } from "../../views/CPM";
import { SelectCPMSite } from "../../components/dashboard-preview/cpm-site-select";

const ControlApp = props => {
	const mounted = useRef();

	const dashboard = useSelector(state => state.dashboard);
	const appPermissions = useSelector(state => state.appStatus.appPermissions)
	const isStaff = useSelector(state => state.appStatus.isStaff);
	const userData = useSelector(state => state.appStatus)
	const [showTNCModal, setShowTNCModal] = useState(false)
    const [showNewTNCModal, setShowNewTNCModal] = useState(false)
	const dispatch = useDispatch();
    let uuid = localStorage.getItem("uuid")
	const remaining = useRef(0)
    const baseTime = useRef(localStorage.getItem("loginTime"))
    const totalIdleTime = useRef(0)
    const totalAction = useRef(0)
	const { getTranslation } = useTranslation();


	const onIdle = () => {
        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
        baseTime.current = currentTime
    }

	const onAction = () => {
        totalAction.current += 1
    }

    const onActive = () => {
        const currentTime = moment().format("YYYY-MM-DD HH:mm:ss")
        const idleTime = moment(currentTime).diff(moment(baseTime.current), 'minutes')
        totalIdleTime.current = totalIdleTime.current + idleTime
        baseTime.current = currentTime
    }

    const { getRemainingTime } = useIdleTimer({
        onIdle,
        onActive,
		onAction,
        timeout: 600000,    // time after which user idle is considered
        throttle: 500,
        crossTab: true
    })

    useEffect(() => {
        const interval = setInterval(() => remaining.current = Math.ceil(getRemainingTime() / 1000), 500)

        return () => {
            clearInterval(interval)
        }
    })

    window.addEventListener("beforeunload", (ev) => {  
        if(totalAction.current && localStorage.getItem("uuid"))
            triggerSessionEvent()
        return '';
    });

    const triggerSessionEvent = () => {
		const logoutTime = moment().format("YYYY-MM-DD HH:mm:ss")
		const timeSpent = moment(logoutTime).diff(moment(localStorage.getItem("loginTime")), 'minutes')
		window.localStorage.setItem("uuid", "")
		window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
            'event': 'Session Duration',
            'userId': userData.userId,
			'customer': userData.customer,
            'uuid': uuid,
            'session time': timeSpent,
            'idle time': totalIdleTime.current,
            'active time': timeSpent - totalIdleTime.current,
            'source': 'Schedule Dashboard',
			'userType': isStaff ? 'Internal' : 'External'
        })
    }

	useEffect(() => {
		if (!_isEmpty(appPermissions)) {
			if (!mounted.current) {
				//componentDidMount
				fetchDashboardConfig();
				dispatch(resetGlobalFilters());
				mounted.current = true;
			} else {
				//componentDidUpdate
				let { match } = props;
				let { type: dashboardAttribute } = match.params;
				if (_isEmpty(dashboard.activeDashboard)) {
					fetchDashboardConfig();
				} else if (!!dashboardAttribute) {
					if (
						dashboard.activeDashboard._id &&
						dashboard.activeDashboard._id !== dashboardAttribute
					) {
						fetchDashboardConfig();
					}
				}
			}
		}
	}, [appPermissions]);

	const fetchDashboardConfig = () => {
		//Getting url of current view to handle refresh
		let dashboardAttribute = props.location.pathname.slice(6);
		if (controlScreens && appPermissions.accessToNew) {
			let tempControlScreens = controlScreens
			if (appPermissions.onlyViewControls) {
				tempControlScreens = [{
					name: "View Controls",
					icon: "ScheduleOutlined",
					url: "configschedules",
					view: "configSchedules",
					new: true,
					config: {
						siteSelection: true
					}
				}]
			}
			if (appPermissions.canBulkControl) {
				tempControlScreens.push({
					name: "Bulk Control",
					url: "bulkControl",
					view: "bulkControl",
					new: true,
					icon: "TableOutlined",
					config: {
						siteSelection: true
					}
				})
			}
			if (appPermissions.canCPMControl) {
				tempControlScreens.push({
					name: "Chiller Plant Manager",
					url: "cpm",
					view: "cpm",
					new: true,
					icon: "ControlOutlined",
					config: {
					  siteSelection: true
					}
				})
			}
			if (appPermissions.regularOveride || appPermissions.indefiniteOverride)
				if (appPermissions.canInstantControl) {
					tempControlScreens.push({
						name: "Instant Control",
						url: "instantControl",
						view: "instantControl",
						new: true,
						icon: "InstantOutlined",
						config: {
							siteSelection: true
						}
					})
				} else {
					tempControlScreens.push({
						name: "Control Override",
						url: "controlOverride",
						view: "controlOverride",
						new: true,
						icon: "OverrideOutlined",
						config: {
							siteSelection: true
						}
					})
				}
			tempControlScreens.push({
				name: "Dashboard",
				// url: "configschedules",
				// view: "configSchedules",
				new: true,
				icon: "LayoutOutlined",
				anchorTag: !window.location.origin
					.toLowerCase()
					.includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
					? REACT_APP_V2_DASHBOARD_URL.india
					: window.location.origin
						.toLowerCase()
						.includes(REACT_APP_EUROPE_DEMO_DOMAIN_NAME_KEY)
					? REACT_APP_V2_DASHBOARD_URL.europeDemo
					: REACT_APP_V2_DASHBOARD_URL.europe,
				config: {
					siteSelection: true
				}
			})
			dispatch(updateDashboardConfig(tempControlScreens, dashboardAttribute));
		} else {
			dispatch(updateDashboardConfig(false));
		}
	};

	const renderedView = () => {
		let view = dashboard.activeDashboard.view;
		switch (view) {
			case "configSchedules":
				return <ConfigSchedules />
			case "bulkControl":
				return <BulkControl />
			case "controlOverride":
				return <NewInstantControl isControlOverride={true}/>
			case "instantControl":
				return <NewInstantControl />
			case "controlStatus":
				return <ControlStatus />
			case "downloadSchedules":
				return <DownloadSchedules />
			case "cpm":
				return <CPM/>
			default:
				return <NoPermission />;
		}
	};

	const renderFilters = () => {
		let view = dashboard.activeDashboard.view;
		if (view) {
			switch (view) {
				case "configSchedules":
					return <SelectEquipment />
				case "controlOverride":
					return <SelectSiteEquipmentType />
				case "instantControl":
					return <SelectSiteEquipmentType />
				case "cpm":
					return <SelectCPMSite />
				case "controlStatus":
					return <SelectSiteEquipmentType controlStatus={true}/>
				case "downloadSchedules":
					return <div className={"hierarchy-context mt-30"} />;
				case "bulkControl":
					return <SelectEquipmentType />
				default:
					return <SiteSelection />;
			}
		}
	}

	// Overriding Antd table filter dropdown elements color
	useEffect(() => {
		if (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider') {
			document.documentElement?.style?.setProperty('--primary-table-filter-color', '#009530');
		}
		if (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds') {
			document.documentElement?.style?.setProperty('--primary-table-filter-color', '#da291c');
		}
		if(dashboard && dashboard.termsAndConditions){
            if(!dashboard.termsAndConditions.is_accepted && dashboard.termsAndConditions.latest_terms_and_conditions){
                setShowTNCModal(true)
            }
        }
    }, [dashboard])

	return (
		(userData.isNewUser && !userData?.canAccess?.access) ? <>
			<div style={{ overflow: 'hidden', height: '100vh' }}className={`${dashboard?.customerTheme === 'schneider' && ' SE-theme-color'} 
				${dashboard?.customerTheme === 'mcdonalds' && ' MCD-theme-color'}`}>
                <BrandHeader triggerSessionEvent={triggerSessionEvent} isDashboardConfigEmpty={true}/>
                <NoPermission />
            </div>
		</> : 
		!_isEmpty(dashboard && dashboard?.customerTheme) && Object.keys(appPermissions).length > 0 && (
			<div
				style={{ overflow: "hidden", height: "100vh" }}
				className={`${dashboard?.customerTheme === 'schneider' && ' SE-theme-color'} ${dashboard?.customerTheme === 'mcdonalds' && ' MCD-theme-color'}`}
			>
				<BrandHeader triggerSessionEvent={triggerSessionEvent} />
				<div className={appPermissions && appPermissions['accessToNew'] ? 
					"dashboard-user-sessions-container" : "dashboard-user-sessions-container-no-permission"}>
					{
						appPermissions && appPermissions['accessToNew'] && <div className={"dashboard-listing"}>
							<DashboardMenu />
						</div>
					}
				<div
					className={
						"session-information " + (appPermissions.accessToNew ? "new-ui" : "no-permission")
					}
					style={{ overflow: "hidden" }}
				>
					<div className="mb-20">
						<AppHeader>{renderFilters()}</AppHeader>
					</div>
					<div
						style={{ height: "100%" }}
						className="no-scroll mb-20 main-content"
					>
						{renderedView()}
					</div>
				</div>
			</div>
			<Modal
				centered={true}
				footer={null}
				closable={false}
				visible={showTNCModal}
				className="tnc-model"
				width={500}
			>
				<div className='tnc-model-body'>
					<div className='tnc-model-icon'>
						<TNCModalIcon />
					</div>
					<div className={`tnc-model-heading ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-change-color' || 
						dashboard?.customerTheme === 'mcdonalds' && 'MCD-change-color')}`}>
					{getTranslation("Updates: Privacy Policy")}	
					</div>
					<div className='tnc-model-content'>
					{getTranslation("We've recently updated our privacy policy")}	. <br/> {getTranslation("Please click the button below to read it")}.
					</div>
					<div className='d jc-c'>
						<Button
							className={`tnc-model-btn ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-change-btn-color' || 
								dashboard?.customerTheme === 'mcdonalds' && 'MCD-change-btn-color')}`}
							onClick={() => { setShowNewTNCModal(true); setShowTNCModal(false) }}
						>
							{getTranslation("Read Privacy Policy")}
						</Button>
					</div>
				</div>
			</Modal>
			{
				showNewTNCModal && <OptOutTNC setShowNewTNCModal={setShowNewTNCModal} logoutUser={responseLogout} termsData={dashboard.termsAndConditions['latest_terms_and_conditions']} />
			}
      	</div>)
  	);
};

export default ControlApp;
