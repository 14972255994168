const translateDatasourceItems = (obj, getTranslation) => {
  for (let [key, value] of Object.entries(obj)) {
    if (getTranslation(value)) {
      obj[key] = getTranslation(value);
    }
  }
  return obj;
};

export const translateDatasource = (datasource, getTranslation) => {
  if (!Array.isArray(datasource)) return [];
  let updatedData = [];
  for (let index = 0; index < datasource.length; index++) {
    updatedData.push(
      translateDatasourceItems(datasource[index], getTranslation)
    );
  }
  return updatedData;
};
