import moment from 'moment'
import _isEmpty from 'lodash/isEmpty';
import { allColourRange, colorMapping } from './softMapping';

export const getAllDates = (value, start_date, expiry_date) => {
    const getHelp = (days) => {
        var dateArray = [];
        var currentDate = moment(start_date);
        var stopDate = moment(expiry_date);
        while (currentDate <= stopDate) {
            if (days.includes(moment(currentDate).format('dddd'))) {
                dateArray.push(moment(currentDate).format('YYYY-MM-DD'))
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;
    }

    let allDates = [];
    if (value) {
        const { isDate, days_of_week, date } = value;
        if (isDate) {
            allDates.push(moment(date).format('YYYY-MM-DD'))
        }
        else allDates.push(...getHelp(days_of_week))
    }
    return allDates;
}
// this function will get the all schedule frequency.
export const getEvent = (startDate, expiryDate, frequency) => {
    let allSch = [];

    const format = (date, hour, minute) => {
        var newDate = moment(date).utcOffset("+05:30");
        newDate.set({ hour, minute, second: 0, millisecond: 0 })
        newDate.toISOString()
        newDate.format()
        return moment(newDate).toDate();
    }

    const getHelp = (days, title, type, time) => {
        var dateArray = [];
        var currentDate = moment(startDate);
        var stopDate = moment(expiryDate);
        while (currentDate <= stopDate) {
            if (days.includes(moment(currentDate).format('dddd'))) {

                dateArray.push({
                    start: format(currentDate, time),
                    end: format(currentDate, time),
                    title, type
                })
            }
            currentDate = moment(currentDate).add(1, 'days');
        }
        return dateArray;

    }

    for (let index = 0; index < frequency.length; index++) {
        const { isDate, days_of_week, date } = frequency[index];
        for (let scheduleCount = 0; scheduleCount < frequency[index].schedules.length; scheduleCount++) {
            if (!_isEmpty(frequency[index].schedules[scheduleCount])) {
                let time = Object.keys(frequency[index].schedules[scheduleCount])
                let hour = time.toString().split(':')[0]
                let minute = time.toString().split(':')[1]
                for (let scheduleName of Object.keys(frequency[index].schedules[scheduleCount][time])) {
                    if (scheduleName === "time") continue
                    let title = `${frequency[index].schedules[scheduleCount][time][scheduleName]}`
                    let type = scheduleName;
                    if (isDate) {
                        allSch.push({
                            title,
                            type,
                            start: format(date, hour),
                            end: format(date, hour),

                        })
                    }
                    else allSch.push(...getHelp(days_of_week, title, type, hour))
                }
            }
        }

    }
    return allSch.map((data, id) => { return { ...data, id } })
}

const getLegendMapping = (name, value, allLegendMapping, usedColours) => {
    let { range, id, mode, colour } = colorMapping(name.toLowerCase(), value);
    if (id !== "None") {
        if (!usedColours[id]) {
            allLegendMapping = [...allLegendMapping, { range, mode, colour }]
        }
        Object.assign(usedColours, { [id]: true })
    }

    return [allLegendMapping, usedColours]
}

export const getEventFromYearObject = (data) => {
    let res = []
    let allLegendMapping = [];
    let usedColours = {
        1: false, 2: false, 3: false, 4: false, 5: false, 6: false, 7: false, 8: false, 9: false, 10: false, 11: false, 12: false
    };

    if (data && !_isEmpty(data)) {
        for (let streamName of Object.keys(data)) {
            if (!_isEmpty(data[streamName])) {
                for (let year of Object.keys(data[streamName])) {
                    if (!_isEmpty(data[streamName][year])) {
                        for (let month of Object.keys(data[streamName][year])) {
                            if (!_isEmpty(data[streamName][year][month])) {
                                for (let day of Object.keys(data[streamName][year][month])) {
                                    if (!_isEmpty(data[streamName][year][month][day])) {
                                        if (data[streamName][year][month][day]["schedule"]) {
                                            data[streamName][year][month][day]["schedule"].map(
                                                scheduleData => {
                                                    let time = scheduleData.time;
                                                    let hour = time.split(':')[0];
                                                    let minute = time.split(':')[1];
                                                    let date = new Date(parseInt(year), parseInt(month) - 1,
                                                        parseInt(day), parseInt(hour), parseInt(minute), 0, 0)
                                                    if (streamName.toLowerCase() === "vfd control") {
                                                        scheduleData.state = parseFloat(scheduleData.state) <= 10 ? (2 * parseFloat(scheduleData.state)) + 30 : parseFloat(scheduleData.state)
                                                    }
                                                    // if (streamName.toLowerCase() === "set differential pressure") {
                                                    //     if (parseInt(scheduleData.state) >= 1 && parseInt(scheduleData.state) <= 200) {
                                                    //         scheduleData.state = parseFloat(scheduleData.state) / 10;
                                                    //     }
                                                    // }
                                                    [allLegendMapping, usedColours] = getLegendMapping(streamName,
                                                        scheduleData.state, allLegendMapping, usedColours);
                                                    res.push({
                                                        start: date,
                                                        end: date,
                                                        title: scheduleData.state,
                                                        name: streamName,
                                                        type: scheduleData.type
                                                    })
                                                }
                                            )
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return [res, allLegendMapping]
    } else return []
}