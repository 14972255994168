
const controlStatusReducer = (
	state = {
		siteName: null,
		siteDisplayName: null,
	},
	action
) => {
	switch (action.type) {
		case "CLEAR_EQUIPMENT_CONTROL_STATUS":
			state = { siteName: null, siteDisplayName: null};
			break;
        case "SET_SITE_NAME_CONTROL_STATUS":
            state = {
                ...state,
                siteName: action.payload.siteName,
                siteDisplayName: action.payload.siteDisplayName,
            };
            break;
		default:
			break;
	}
	return state;
};

export default controlStatusReducer;
