import React, { useEffect, useState } from 'react'
import moment from 'moment'

import * as dates from 'date-arithmetic'
import { useDispatch, useSelector } from 'react-redux'
import { updateYear } from '../../actions/yearActions'
import { ShowScheduleOnDate } from '../allCalendar/showSchedule'

export let navigate = {
	PREVIOUS: 'PREV',
	NEXT: 'NEXT',
	TODAY: 'TODAY',
	DATE: 'DATE',
}
const createCalendar = currentDate => {
	if (!currentDate) {
		currentDate = moment()
	} else {
		currentDate = moment(currentDate)
	}

	const first = currentDate.clone().startOf('month')
	const last = currentDate.clone().endOf('month')
	const weeksCount = Math.ceil((first.day() + last.date()) / 7)
	const calendar = Object.assign([], { currentDate, first, last })

	for (let weekNumber = 0; weekNumber < weeksCount; weekNumber++) {
		const week = []
		calendar.push(week)
		calendar.year = currentDate.year()
		calendar.month = currentDate.month()

		for (let day = 7 * weekNumber; day < 7 * (weekNumber + 1); day++) {
			const date = currentDate.clone().set('date', day + 1 - first.day())
			date.calendar = calendar
			week.push(date)
		}
	}

	return calendar
}

const CalendarDate = props => {
	const { dateToRender, dateOfMonth } = props
	const [parseDates, setParseDates] = useState([]);

	useEffect(() => {
		if (props.eventsDate)
			setParseDates(props.eventsDate.map(data => moment(data.start).format('YYYY-MM-DD')))
	}, [props.eventsDate])

	const today =
		dateToRender.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
			? 'today'
			: ''

	const event = parseDates.includes(dateToRender.format('YYYY-MM-DD')) ? 'event' : ''

	if (dateToRender.month() < dateOfMonth.month()) {
		return (
			<button disabled={true} className="date prev-month">
				{dateToRender.date()}
			</button>
		)
	}

	if (dateToRender.month() > dateOfMonth.month()) {
		return (
			<button disabled={true} className="date next-month">
				{dateToRender.date()}
			</button>
		)
	}

	return (
		<button
			className={`date in-month ${today} ${event}`}
			onClick={() => props.onClick(dateToRender)}>
			{dateToRender.date()}
		</button>
	)
}

const Calendar = ({ date }) => {

	const [calendar, setcalendar] = useState(undefined)
	const [allEvent, setAllEvent] = useState(null)
	const [selectedDate, setSelectedDate] = useState(null)
	const [drawerVisible, setDrawerVisible] = useState(false);
	const [timelineData, setTimelineData] = useState([]);

	const dispatch = useDispatch()
	const allEventData = useSelector(state => state.yearReducer.eventsDate)

	const handleClickOnSlot = (date) => {
		setSelectedDate(date.format('YYYY-MM-DD'))
	}

	const onDrawerClose = () => {
		setSelectedDate(null)
		setDrawerVisible(false)
	}

	useEffect(() => {
		if (selectedDate && allEvent) {
			let tempEventData = {}
			allEvent.filter(eventData => moment(eventData.start).format('YYYY-MM-DD') === selectedDate)
				.map(reqData => {
					let time = moment(reqData.start).format('hh:mm A');
					if (tempEventData[time]) {
						tempEventData[time] = [...tempEventData[time], reqData]
					} else {
						tempEventData[time] = [reqData]
					}
				})
			setTimelineData(tempEventData)
			setDrawerVisible(true)
		} else setTimelineData([]);
	}, [selectedDate])

	useEffect(() => {
		setAllEvent(allEventData)
	}, [allEvent])

	useEffect(() => {
		let tempCalendar = createCalendar(date)
		setcalendar(tempCalendar)
	}, [date])

	return (
		!calendar ? null :
			<div>
				<ShowScheduleOnDate onDrawerClose={onDrawerClose} selectedDate={selectedDate}
					timelineData={timelineData} drawerVisible={drawerVisible} />
				<div className="month">
					<div className="month-name">
						{calendar.currentDate.format('MMMM').toUpperCase()}
					</div>
					{['S', 'M', 'T', 'W', 'T', 'F', 'S'].map((day, index) => (
						<span key={index} className="day">
							{day}
						</span>
					))}
					{calendar.map((week, index) => (
						<div key={index}>
							{week.map(date => (
								<CalendarDate
									eventsDate={allEvent}
									key={date.date()}
									dateToRender={date}
									dateOfMonth={calendar.currentDate}
									onClick={handleClickOnSlot}
								/>
							))}
						</div>
					))}
				</div>
			</div>
	)
}


const Year = ({ date }) => {

	const months = []
	const firstMonth = dates.startOf(date, 'year')

	for (let i = 0; i < 12; i++) {
		months.push(
			<Calendar key={i + 1} date={dates.add(firstMonth, i, 'month')} />
		)
	}

	return <div className="year">{months.map(month => month)}</div>

}


Year.range = date => {
	return [dates.startOf(date, 'year')]
}

Year.navigate = (date, action) => {
	switch (action) {
		case navigate.PREVIOUS:
			return dates.add(date, -1, 'year')

		case navigate.NEXT:
			return dates.add(date, 1, 'year')

		default:
			return date
	}
}

Year.title = (date, { localizer }) => localizer.format(date, 'monthHeaderFormat')

export default Year
