import React from "react";
import dataAPi from '../elements/apis/apis'

export const CustomerContext = React.createContext({
    customer: {},
    setCustomer: function (customer) {
        this.customer = customer;
    },
    getCustomerDetails: async function (customer) {
        const sessionDetails = await dataAPi.setSessionCustomer(customer);
        this.setCustomer({ ...customer, ...sessionDetails.data });
    }
});
