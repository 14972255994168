import React from 'react'
import { useState, useEffect } from 'react'
import { connect, useSelector, useDispatch } from 'react-redux';
import { updateLoginStatus } from '../../actions/appActions';
import { clearDashboardSettings, resetGlobalFilters, updateSessionExpired } from '../../actions/dashboardActions';
import { logout } from '../../elements/authentication/authentication';
import { Button, Modal } from 'antd';
import _map from 'lodash/map'
import zenatixLogo from '../../imgs/ZenatixLogoWhite.png'
import logo_se from '../../imgs/logo-se.png'
import logo_mcd from '../../imgs/McdLogo.png'
import { CustomerSelect } from '../customer-select';
import { OptOutTNC } from '../terms-and-condition';
import change_customer_icon from "../../imgs/change_customer_icon.svg"
import logout_icon from "../../imgs/logout_icon.svg"
import opt_out from "../../imgs/opt_out_icon.svg"
import license_icon from "../../imgs/license_icon.svg"
import DropdownIcon from "../../imgs/DropdownIcon.svg"
import DropdownIconSE from "../../imgs/DropdownIconSE.svg"
import DropdownIconMCD from "../../imgs/DropdownIconMCD.svg"
import language_icon from "../../imgs/language_icon.svg"
import ruler_icon from "../../imgs/ruler_icon.svg"
import _isEmpty from 'lodash/isEmpty';
import {
    REACT_APP_BASE_URL,
    CUSTOMER_NAME_CHANGE,
    SESSION_TIMEOUT,
    SESSION_EXPIRY_CHECK_INTERVAL,
    SESSION_TIMEOUT_EXCEPTION_CUSTOMERS,
    EXCEPTION_CUSTOMERS
  } from "../../lib/constants";
import { storageChangeEvent } from '../../App';
import { LanguageSelect } from '../language-select';
import { useTranslation } from '../../context/translationContext';
import dataApi from '../../elements/apis/apis';
import store from '../../store';
import {toast} from 'react-toastify';
import moment from 'moment';
import { UnitSystemSelect } from '../unit-system-select';

export const responseLogout = async (sessionExpired) => {
    updateLoginStatus(false);
    await logout();
    sessionStorage.setItem("promiseTracker", 1)
    storageChangeEvent.key = "promiseTracker"
    storageChangeEvent.value = 1
    window.dispatchEvent(storageChangeEvent)
    localStorage.removeItem("loginTime");
    if (sessionExpired) {
        store.dispatch(updateSessionExpired(true));
        toast.dismiss();
    } else {
        window.location.href = '/';
    }
}

const Header = (props) => {
    const { getTranslation } = useTranslation();
    const [userActionModal, setuserActionModal] = useState(false);
    const [termsAvailable, setTermsAvailable] = useState(false)
    const [termsData, setTermsData] = useState(null)
    const sessionInfo = useSelector(state => state.appStatus);
    const accessibleCustomers = useSelector(state => state.dashboard.userAccessibleCustomers);
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    let dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch()
    let { firstName, lastName, customer } = sessionInfo;
    const [licenseUrl, setLicenseUrl] = useState('not-found')

    const loginTime = moment(localStorage.getItem("loginTime"));
    const loginTimePlusSessionTimeout = loginTime.add(EXCEPTION_CUSTOMERS.includes(customer) ? SESSION_TIMEOUT_EXCEPTION_CUSTOMERS : SESSION_TIMEOUT, "minutes");

    const checkSession = () => {
      const currentTime = moment();
      if (currentTime.isSameOrAfter(loginTimePlusSessionTimeout)) {
        responseLogout(true);
      }
    };

    useEffect(() => {
      let intervalID;
      if (intervalID || !props.isLoggedIn || props.sessionExpired) return;
      intervalID = setInterval(checkSession, SESSION_EXPIRY_CHECK_INTERVAL);

      return () => {
        clearInterval(intervalID);
      };
    }, [props.isLoggedIn, props.sessionExpired]);

    useEffect(() => {
        if(props.termsAndConditions){
            const { user, terms_and_conditions, is_accepted } = props.termsAndConditions;
            if (user && terms_and_conditions && is_accepted) {
                setTermsAvailable(true);
                setTermsData(terms_and_conditions);
            } else {
                setTermsAvailable(false);
            }
        }
    },[props.termsAndConditions])

    useEffect(() => {
        try {
            const fetchLicenseUrl = async () => {
                const response = await dataApi.getLicenses();
                setLicenseUrl(response?.data);
            }
            if(!_isEmpty(dashboard && dashboard?.customerTheme))
                fetchLicenseUrl();
        } catch (error) {
            // console.log("🚀 ~ useEffect ~ error:", error)
        }
    }, [])

    const logoutUser = async () => {
        props.triggerSessionEvent()
        dispatch(updateLoginStatus(false))
        await logout();
        localStorage.removeItem("loginTime");
        window.location = '/';
    }


    const getUserInitials = () => {
        // let {isOEM} = dashboard.dashboardConfig;
        if (true) {
            if (firstName || lastName)
                return <div className={'user-icon'} style={{ padding: 0 }} >
                    <div>
                        <span>{String((firstName ? firstName[0] : '') + (lastName ? lastName[0] : '')).toUpperCase()}</span>
                    </div>
                </div>;
        }
    }




    return sessionInfo.customer && (!_isEmpty(dashboard && dashboard?.customerTheme) || props?.isDashboardConfigEmpty) ?
        <div className="brand-header-wrapper">
            <div className="d jc-sb ai-center" style={{height: '100%'}}>
                <div className="Cs-zenatix-logo" style={{width: '800px'}}>
                    {
                        Object.keys(appPermissions).length > 0 && (!(appPermissions.hideZenatixLogo) || dashboard?.customerTheme === 'schneider' || dashboard?.customerTheme === 'mcdonalds') &&
                            <img src={dashboard?.customerTheme === 'schneider' ? logo_se : dashboard?.customerTheme === 'mcdonalds' ? logo_mcd : zenatixLogo} 
                            style={dashboard?.customerTheme === 'schneider' ? {width:'30%', marginLeft: '-16px',marginBottom:'-14px'} : 
                            dashboard?.customerTheme === 'mcdonalds' ? { width: '7%', marginLeft: '20px'} : { width: '16%', marginLeft: '20px'}}  alt="zenatixLogo" />
                    }
                </div>
                <div className="d ai-center mr-15">
                    {
                        Object.keys(appPermissions).length > 0 && !(appPermissions.hideZenatixLogo || appPermissions.onlyShowZenatixLogo) &&
                            <img className="cs-brand-logo" src={`${REACT_APP_BASE_URL}/users/getLogo?name=${sessionInfo.logo}`} alt="customer-logo" />
                    }
                    <div className="d ai-center" onClick={() => setuserActionModal(true)} style={{ cursor: 'pointer' }}>
                        <div className="text-white cs-sub-title cs-fnt-ibm"  >
                            {`${firstName || ""}  ${lastName || ""}`}</div>
                        <img src={dashboard?.customerTheme === 'schneider' ? DropdownIconSE : DropdownIcon} style={{ margin: '5px', width: '28px' }} />
                    </div>
                </div>
            </div>
            <Modal
                open={userActionModal}
                title={null}
                className={`brand-header-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && "SE-theme-color" || 
                    dashboard?.customerTheme === 'mcdonalds' && "MCD-theme-color")}`}
                width="30vw"
                closable={false}
                footer={null}
                onCancel={() => setuserActionModal(false)}
                style={{ top: '90px', right: '20px', position: "absolute" }}
            >
                <div className="display-column brand-header-details-wrapper">
                    {getUserInitials()}
                    <div className="brand-header-name" >{props.username}</div>
                    <div className="brand-header-username" >
                        {
                            Object.keys(appPermissions).length > 0 && (appPermissions.maskCustomerName) ? 
                                CUSTOMER_NAME_CHANGE[props.customer] ? CUSTOMER_NAME_CHANGE[props.customer] : 
                                    props.customer : props.customer
                        }
                    </div>
                </div>
                {props.isLoggedIn &&
                    <div className='brand-header-item-wrapper'>
                        {accessibleCustomers && (Object.keys(accessibleCustomers).length > 1) &&
                            <div className="brand-header-item">
                                <img src={change_customer_icon} style={{marginRight: '5px'}}/>
                                <CustomerSelect button={true} data={accessibleCustomers} />
                            </div>
                        }
                        {termsAvailable && <div className="brand-header-item">
                            <img src={opt_out} style={{marginRight: '5px'}}/>
                            <OptOutTNC setuserActionModal={setuserActionModal} logoutUser={logoutUser} termsData={termsData}/>
                        </div>}
                        <div className="brand-header-item">
                            <img src={language_icon} style={{margin: '5px 5px 0px 3px'}}/>
                            <LanguageSelect />
                        </div>
                        <div className="brand-header-item">
                            <img src={ruler_icon} style={{marginRight:'7px'}}/>
                            <UnitSystemSelect />
                        </div>
                        {
                            !_isEmpty(dashboard && dashboard?.customerTheme) && 
                                <div className="brand-header-item open-source-link">
                                    <img src={license_icon} style={{ marginRight: "5px" }} />
                                    <a
                                        href={licenseUrl}
                                        target="_blank"
                                    >
                                        {getTranslation('Open source licenses')}
                                    </a>
                                </div>
                        }
                        <div className="brand-header-item-last" onClick={logoutUser}>
                            <img src={logout_icon} style={{marginRight: '5px'}}/>
                            {getTranslation('Logout')}
                        </div>
                    </div>
                }
            </Modal>
            <Modal
                open={props?.sessionExpired}
                title={null}
                className={`session-expired-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && "SE-theme-color" || 
                    dashboard?.customerTheme === 'mcdonalds' && "MCD-theme-color")}`
                }
                width="20vw"
                closable={false}
                footer={false}
                centered
            >
                <div className="session-expired-message">
                    {getTranslation("Your session has expired.")}
                    <br />
                    {getTranslation("Click OK to login again.")}
                </div>
                <Button className="session-expired-btn" onClick={() => window.location.href = '/'}>
                    {getTranslation("OK")}
                </Button>
            </Modal>
        </div> : <></>

}

const mapStateToProps = (state) => {
    return {
        isLoggedIn: state.appStatus.isLoggedIn,
        username: state.appStatus.username,
        customer: state.appStatus.customer,
        heading: state.dashboard.activeDashboard.name,
        dashConf: state.dashboard.dashboardConfig,
        termsAndConditions: state.dashboard.termsAndConditions,
        sessionExpired: state.dashboard.sessionExpired,
    };
};

export const BrandHeader = connect(mapStateToProps, {
    updateLoginStatus,
    resetGlobalFilters,
    clearDashboardSettings,
    updateSessionExpired
})(Header);