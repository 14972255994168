import React, { useEffect, useRef, useState } from 'react'
import { ArrowUpOutlined } from '@ant-design/icons'
import { Button, Modal } from 'antd'
import { DatePicker } from '../dayjsToMoment'
import { EventCard } from './EventCard'
import { useDispatch, useSelector } from 'react-redux';
import { deleteSchedules, updateEndTime, updateStartTime } from '../../actions/newSchedulesActions';
import moment from 'moment';
import _map from 'lodash/map'
import _isEmpty from 'lodash/isEmpty'
import { DeleteIcon, DeleteIconMCD, EditIcon } from '../../assests/icon';
import { GetDuplicateModal } from './duplicate';
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import { useTranslation } from '../../context/translationContext'

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const SingleTimeFram = ({ config, edit, idx, schedulesCount }) => {

    const { getTranslation } = useTranslation();
    const [startDate, setStartDate] = useState(config.start_date !== null ? moment(config.start_date) : null)
    const [endDate, setEndDate] = useState(config.expiry_date !== null ? moment(config.expiry_date) : null)
    const [scheduleId, setScheduleId] = useState(config.scheduleId)
    const [duplicateModal, setDuplicateModal] = useState(false)

    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
	const dashboard = useSelector(state => state.dashboard);
    const dispatch = useDispatch()
    const ref = useRef();

    useEffect(() => {
        if (config.start_date !== null)
            setStartDate(config.start_date)
        else
            setStartDate(null)
        if (config.expiry_date !== null)
            setEndDate(config.expiry_date)
        else
            setEndDate(null)
        if (config.scheduleId)
            setScheduleId(config.scheduleId)
        else
            setScheduleId(null)
    }, [config])

    const onChangeTime = (dateArray) => {
        if (dateArray[0])
            dispatch(updateStartTime({ key: scheduleId, startDate: moment(dateArray[0]).format("YYYY-MM-DD") }))
        if (dateArray[1]) {
            dispatch(updateEndTime({ key: scheduleId, endDate: moment(dateArray[1]).format("YYYY-MM-DD") }))
        }
        if (newScheduleStore[scheduleId]['expiry_date'] !== dateArray[1]) {
            // in case of none freq, fost forward to next step. and only in case of change od expiry date
            if (!_isEmpty(newScheduleStore)) {
                if (scheduleId) {
                    if (newScheduleStore[scheduleId] && newScheduleStore[scheduleId]['frequency'].length === 0) {
                        // skip to next step.
                        edit(scheduleId);
                    }
                }
            }
        }
    }
    useEffect(() => { console.log(startDate) }, [startDate])
    const handleDeleteSchedule = () => {
        const config = {
            title: getTranslation('Are you Sure ?'),
            icon: null,
            content: getTranslation("This time frame will be delete forever, this process is not reversible."),
            okText: getTranslation("Delete"),
            cancelText: getTranslation("Cancel"),
            className: `cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`,
            onOk: () => dispatch(deleteSchedules({ key: scheduleId }))
        };
        Modal.confirm(config)
    }

    return (
        <div className="mr-10 mt-10 mb-20">
            <GetDuplicateModal handleClose={() => setDuplicateModal(false)}
                duplicateModal={duplicateModal} scheduleId={scheduleId} />
            <div className="d jc-sb ">
                <div className="d">
                    <div className="mr-10">
                        <RangePicker 
                        popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                        suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                        separator={
                            <img
                                src={
                                !_isEmpty(dashboard && dashboard?.customerTheme) &&
                                dashboard?.customerTheme === "schneider"
                                    ? DateRangeSeparatorSE : 
                                    dashboard?.customerTheme === "mcdonalds"
                                    ? DateRangeSeparatorMCD
                                    : DateRangeSeparator
                                }
                                alt='ant-date-range-separator'
                            />
                        } format="YYYY-MM-DD" disabledDate={disabledDate}
                            value={(startDate !== null && endDate !== null) ? [moment(startDate), moment(endDate)] : null}
                            onChange={onChangeTime} allowClear={false} className="red" />
                    </div>
                    {/* <div>
                        <Button  className="yellow-button" ><PlusIcon />Add Frequency</Button>
                    </div> */}
                </div>
                <div>
                    <Button onClick={() => setDuplicateModal(true)} className="red-light-button">{getTranslation('Duplicate Schedule')}</Button>
                </div>
            </div>
            <div className={`d back-grey  ${(startDate !== null && endDate !== null) ? 'jc-sb' : 'jc-c'}`}>
                {(startDate !== null && endDate !== null) ?
                    <>
                        <div className="d" style={{ width: '95%', overflowX: 'auto' }}>
                            {/* All  Cards */}
                            <EventCard scheduleId={scheduleId} />
                        </div>
                        <div className="display-column jc-fs mt-20">
                            {
                               (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds') ? 
                                <DeleteIconMCD onClick={() => handleDeleteSchedule()} className="cs-medium-icon mb-10"/> : 
                                <DeleteIcon onClick={() => handleDeleteSchedule()} className="cs-medium-icon mb-10" />
                            }
                            <EditIcon onClick={() => edit(scheduleId)} className="cs-medium-icon" />
                        </div>
                    </> :
                    <div className="br-1 d color-grey jc-c ai-center mt-20" style={{ height: 100, textAlign: 'center' }}>
                        <ArrowUpOutlined className="mr-5" /> {getTranslation('Select Date Range to Add Control')}
                    </div>}
            </div>
        </div >
    )
}
