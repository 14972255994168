import React, { useEffect, useState } from 'react'
import { Table, Input, Button, Modal } from 'antd'
import { DatePicker } from '../../components/dayjsToMoment';
import { ExclamationCircleFilled } from '@ant-design/icons'
import { antdTableHelper } from './helper';
import { deleteControls, getAllEquipmentSchedulesStatus, getClientEquipmentMappingFilterApplied } from '../../elements/apis/controlApis';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone'
import _isEmpty from 'lodash/isEmpty';
import "../../App.css";
import { toastError } from '../../toast/toast';
import SearchIcon from "../../imgs/SearchIcon.svg"
import { useTranslation } from '../../context/translationContext';
import { translateDatasource } from '../../lib/utils';

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const ControlStatus = () => {
    const { getTranslation } = useTranslation();
    const [column, setColumn] = useState([])
    const [selectedRows, setSelectedRows] = useState([]);
    const [tableData, setTableData] = useState([])
    const [filterTable, setFilterTable] = useState(null)
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [currentData, setCurrentData] = useState(null);
    const [isChanged, setIsChanged] = useState(true)

    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const controlStatusReducer = useSelector(state => state.controlStatusReducer)
	const dashboard = useSelector(state => state.dashboard);

    useEffect(() => {
        if(controlStatusReducer.siteName)
            init(controlStatusReducer.siteName)
    }, [controlStatusReducer, controlStatusReducer.siteName, controlStatusReducer.equipmentType])

    // useEffect(() => {
    //     init()
    // },[])

    useEffect(() => {
        antdTableHelper(tableData, setColumn, currentData, column, isChanged, getTranslation)
    }, [tableData, currentData])

    const rowSelection = {
        selectedRowKeys: selectedRows,
        onChange: (selectedRowKeys, selectedRows) => {
            let keyArray = selectedRowKeys.toString()
            if (keyArray) setSelectedRows(keyArray.split(','));
            else setSelectedRows([])
        },
        getCheckboxProps: (record) => ({
            disabled: !record.enabled,
            // Column configuration not to be checked
            name: record.name,
        }),
    };

    const onSearch = e => {
        let value = e.target.value

        const serachColumn = ["siteName", "region", "equipmentType", "equipmentName", "control"];
        const filterTable = tableData.filter(o =>
            Object.keys(o).some(k =>
                serachColumn.includes(k) && String(o[k])
                    .toLowerCase()
                    .includes(value.toLowerCase())
            )
        );
        setFilterTable(filterTable)
    };

    const init = async (clientName) => {
        try {
            setIsChanged(true)
            let accptedData = ["City", "Region", "Size"];
            let siteMetaDataMap = {}
            let extraCol = {}

            let res = await getClientEquipmentMappingFilterApplied({ clientName })
            let res1 = await getAllEquipmentSchedulesStatus({ site: clientName })
            // let res1 = apiResponse
            if (res && res.data && res.data.clientSet.edges && res1 && res1.data){
                let allData = []
                res.data.clientSet.edges.map(data => {
                    if (data.node.siteMetadata)
                        siteMetaDataMap = data.node.siteMetadata
                    // for (let metaData of Object.keys(siteMetaDataMap)) {
                    //     if (accptedData.includes(metaData))
                    //         Object.assign(extraCol, { [metaData]: true })
                    // }
                    let siteName = data.node.displayName;
                    let timezone = data.node.timezone

                    data.node.equipments.edges.map(equipmentData => {
                        let config = {
                            key: equipmentData.node.name,
                            ...siteMetaDataMap,
                            siteName,
                            deploymentStatus: res1['data'][equipmentData.node.name]["Schedule Deployment Status"],
                            equipmentName: equipmentData.node.displayName,
                            equipmentType: equipmentData.node.type !== null ?
                                equipmentData.node.type.displayName : "",
                        }

                        equipmentData.node.schedules !== null ?
                            Object.assign(config, {
                                control: res1['data'][equipmentData.node.name]["Equipment Schedule Status"],
                                enabled: equipmentData.node.scheduleExpiryDate ? 
                                    moment(equipmentData.node.scheduleExpiryDate).diff(moment().tz(timezone)) >= 0 ? true : false : false

                            })
                            :
                            Object.assign(config, {
                                "control": res1['data'][equipmentData.node.name]["Equipment Schedule Status"], enabled: false
                            })
                        allData.push(config)
                    })
                })
                // setExtraColumn(Object.keys(extraCol))
                setTableData(allData)
            }
        }
        catch (err) {
            console.error(err)
        }
    }

    const onDisable = () => {
        let config = {};
        config = {
            title: 'Enter Duration',
            icon: <ExclamationCircleFilled />,
            content: <div>
                <div className="d jc-sb mt-20" >
                    <div className="display-column">
                        <div className="cs-medium">From :</div>
                        <DatePicker onChange={(val) => setStartDate(val)} />
                    </div>
                    <div className="display-column">
                        <div className="cs-medium">To :</div>
                        <DatePicker onChange={(val) => setEndDate(val)} />
                    </div>
                </div>
                <div className="mt-20">
                    Controls will be disable for selected Time Duration.
                </div>
            </div>,
            okText: "Disable",
            className: 'cs-modal',
            onOk: () => { }
        };
        Modal.confirm(config)
    };

    const handleResponseMessage = (failure, success) => {
        let config = {
            icon: null,
            className: 'cs-modal',
            cancelButtonProps: { className: "cs-dis-none" }
        };

        let SuccesConfig = {
            title: getTranslation('Success'),
            content: getTranslation("All controls deleted from the selected devices."),
            ...config
        };

        let ErrorConfig = {
            title: getTranslation('Deletion UnSuccessful'),
            content: <div>
                <div>{getTranslation("Not able to delete control on following equipments:")}</div>
                <div className="mt-10">
                    {failure.join(", ")}
                </div>
            </div>,
            ...config
        }

        if (success.length === 0) {
            toast.error("Not able to delete controls.", {icon: toastError()})
            return;
        }

        if (failure.length === 0) {
            Modal.confirm(SuccesConfig)
            return;
        } else {
            Modal.confirm(ErrorConfig)
            return
        }
    }

    const reset = () => {
        setSelectedRows([]);
        setStartDate(null)
        setEndDate(null)
        init(controlStatusReducer.siteName);
    }

    const DeleteModal = () => {
        const [modalVisible, setModalVisible] = useState(false)
        const [startDate, setStartDate] = useState(null);

        const sendDelete = async () => {
            try {
                let resp = await deleteControls({
                    equipmentNames: selectedRows,
                    fromDate: moment(startDate).format('YYYY-MM-DD'),
                    clientName: controlStatusReducer.siteName
                }, appPermissions.isDemoUser)
                if (resp && resp.data) {
                    resp.data = JSON.parse(resp.data);
                    const { failure, success } = resp.data;
                    handleResponseMessage(failure, success);
                    reset()
                } else {
                    toast.error(resp.message, {icon: toastError()});
                }
            } catch (err) {
                console.error(err)
            }
        }

        const handlDeleteModalCanel = () => {
            setModalVisible(false)
        }

        return <>
            <Modal
                visible={modalVisible}
                title={null}
                closable={false}
                onCancel={handlDeleteModalCanel}
                footer={null}
                className={`cs-modal controls-action-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                    dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
                width="30vw"
            >
                <div className="d ai-fs">
                    <div className="display-column ml-20">
                        <div className="d ai-center">
                            <div className="cs-title cs-medium">
                                {getTranslation("Are You Sure ?")}
                            </div>
                        </div>
                        <div className="" >{getTranslation("All deployed controls will be deleted from the selected devices!")}</div>
                        <div className="d ai-center mt-20">
                            <div className="cs-medium mr-10">{getTranslation("From")} :</div>
                            <DatePicker popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')} disabledDate={disabledDate} onChange={(val) => setStartDate(val)} />
                        </div>
                        <div className="d jc-fe">
                            <Button className="blue-button-outline" onClick={sendDelete} >{getTranslation("Yes")}</Button>
                            <Button className="blue-button ml-10" onClick={handlDeleteModalCanel} >{getTranslation("No")}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <Button className="red-light-button ml-10"
                onClick={() => setModalVisible(true)} >  {getTranslation('Delete Control')} </Button>
        </>
    };

    const checkExistence = (arrToCheck, equipment) => {
        let isContain = false;
        for(let index = 0 ; index < arrToCheck.length ; index++){
            let obj = arrToCheck[index]
            if(obj['text'] === equipment)   isContain = true;
        }
        return isContain
    }

    const onChangeOnTable = (pagination, filter, _, filteredAction) => {
        setIsChanged(false)
        if(filter['equipmentName'] && filter['equipmentType'])
            return;
        else if((filter['equipmentName'] && !filter['equipmentType']) || (!filter['equipmentName'] && filter['equipmentType'])){
            let newColumn = []
            if(!filter['equipmentType']){
                let newEquipmentTypeData = []
                let newEquipmentNameData = []
                filteredAction.currentDataSource.map(obj => {
                    let isContainEquipmentType = checkExistence(newEquipmentTypeData, obj.equipmentType)
                    if(!isContainEquipmentType)
                        newEquipmentTypeData.push({"text": obj.equipmentType, "value": obj.equipmentType})
                })
                tableData.map(obj => {
                    let isContainEquipmentName = checkExistence(newEquipmentNameData, obj.equipmentName)
                    if(!isContainEquipmentName)
                        newEquipmentNameData.push({"text": obj.equipmentName, "value": obj.equipmentName})
                })
                for(let index = 0 ; index < column.length ; index++){
                    newColumn.push({...column[index]})
                    if(column[index]['key'] === "equipmentType"){
                        newColumn[index]['filters'] = newEquipmentTypeData
                    }
                    if(column[index]['key'] === "equipmentName"){
                        newColumn[index]['filters'] = newEquipmentNameData
                    }
                }
            } else if(!filter['equipmentName']){
                let newEquipmentNameData = []
                let newEquipmentTypeData = []
                filteredAction.currentDataSource.map(obj => {
                    let isContainEquipmentName = checkExistence(newEquipmentNameData, obj.equipmentName)
                    if(!isContainEquipmentName)
                        newEquipmentNameData.push({"text": obj.equipmentName, "value": obj.equipmentName})
                })
                tableData.map(obj => {
                    let isContainEquipmentType = checkExistence(newEquipmentTypeData, obj.equipmentType)
                    if(!isContainEquipmentType)
                        newEquipmentTypeData.push({"text": obj.equipmentType, "value": obj.equipmentType})
                })
                for(let index = 0 ; index < column.length ; index++){
                    newColumn.push({...column[index]})
                    if(column[index]['key'] === "equipmentType"){
                        newColumn[index]['filters'] = newEquipmentTypeData
                    }
                    if(column[index]['key'] === "equipmentName"){
                        newColumn[index]['filters'] = newEquipmentNameData
                    }
                }
            }
            setColumn(newColumn)
        } else if(!filter['equipmentName'] && !filter['equipmentType']){
            let allEquipmentName = []
            let allEquipmentType = []
            let newEquipmentTypeData = []
            let newEquipmentNameData = []
            filteredAction.currentDataSource.map(obj => {
                allEquipmentName.push(obj.equipmentName)
                allEquipmentType.push(obj.equipmentType)
            })
            allEquipmentName = [...new Set(allEquipmentName)];
            allEquipmentType = [...new Set(allEquipmentType)];
            allEquipmentName.forEach(data => {
                newEquipmentNameData.push({"text": data, "value": data})
            })
            allEquipmentType.forEach(data => {
                newEquipmentTypeData.push({"text": data, "value": data})
            })
            let newColumn = []
            for(let index = 0 ; index < column.length ; index++){
                newColumn.push({...column[index]})
                if(column[index]['key'] === "equipmentType"){
                    newColumn[index]['filters'] = newEquipmentTypeData
                }
                if(column[index]['key'] === "equipmentName"){
                    newColumn[index]['filters'] = newEquipmentNameData
                }
            }
            setColumn(newColumn)
        }
        setCurrentData(filteredAction.currentDataSource)
    }

    const renderView = () => {
        return (
            <div className="display-column" style={{ width: '100%' }} >
                {
                    (!controlStatusReducer.siteName) ? 
                        <div className='d ai-center jc-c' style={{fontSize:'24px !important', height: '100%'}}>{getTranslation('Select Site to view Control Status')} </div> : 
                        _isEmpty(tableData) ? <div className='d ai-center jc-c' style={{fontSize:'24px !important', height: '100%'}}>{getTranslation('No Data Available')}</div> : <>
                            <div className="d mt-10">
                                <Input bordered={false} prefix={<img src={SearchIcon} alt="search" width={'20px'} />} onChange={onSearch}
                                    className="curve" placeholder={`${getTranslation("Type here to search")}...`} />
                                {selectedRows.length !== 0 && <>
                                    {/* <Button className="red-light-button ml-10"
                                        onClick={() => onDisable()} >  Disable Control </Button> */}
                                    {appPermissions.canDeleteControls && <DeleteModal />}
                                </>}
                            </div>
                            <div className="mt-10">
                                <Table
                                    onChange={onChangeOnTable}
                                    id="control-action-table"
                                    columns={column}
                                    rowSelection={appPermissions.canDeleteControls && {
                                        type: 'checkbox',
                                        ...rowSelection,
                                    }}
                                    pagination={{
                                        showSizeChanger: true
                                    }}
                                    style={{marginBottom: '25px'}}
                                    className="control-status-table"
                                    dataSource={filterTable === null ? translateDatasource(tableData, getTranslation) : translateDatasource(filterTable, getTranslation)} />
                            </div>
                        </>
                }
            </div>
        )
    }

    return (
        <div className="d wrapper jc-c" style={{ minHeight: '85vh' }}>
            {renderView()}
        </div>
    )
}