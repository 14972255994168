import { Button, Modal, Select } from "antd";
import _map from 'lodash/map';
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateSessionCustomer } from "../../actions/appActions";
import { checkSessionFromServer } from "../../App";
import { CustomerContext } from "../../context/customerContext";
import dataAPi from '../../elements/apis/apis'
import { useSelector } from "react-redux";
import { CUSTOMER_NAME_CHANGE } from "../../lib/constants";
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import CloseBlackOutlined from "../../imgs/CloseBlackOutlined.svg";
import _isEmpty from 'lodash/isEmpty';
import { useTranslation } from "../../context/translationContext";

export const CustomerSelect = (props) => {
    const { getTranslation } = useTranslation();
    const [customers, setCustomers] = useState({});
    const [a, setA] = useState("b");
    const [visible, setVisible] = useState(false);

    const customerContext = useContext(CustomerContext);
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
	const dashboard = useSelector(state => state.dashboard)

    const dispatch = useDispatch();

    useEffect(() => {
        getAllData();
    }, []);

    const getAllData = async () => {
        try {
            // const resp = await dataAPi.fetchCustomers();
            if (Object.keys(props.data).length === 1) {
                let customer = Object.values(props.data)[0];
                await customerContext.getCustomerDetails(customer);
                checkSessionFromServer();
                dispatch(updateSessionCustomer(customer));
            } else {
                setCustomers(props.data);
            }
        } catch (e) {
            console.log(e);
        }
    };

    const setCustomer = async (sessionCustomer) => {
        await customerContext.getCustomerDetails(customers[sessionCustomer]);
        window.location = "/";

        // props.updateSessionCustomer(customers[sessionCustomer]);
        // setVisible(false);
        // if (props.setCustomer)
        //     props.setCustomer(sessionCustomer);
    };

    const handleClose = () => {
        setVisible(false);
    }

    if (props.button) {
        return <div className="grey">
            <span onClick={() => setVisible(true)}>
                {getTranslation('Change Customer')}
            </span>
            <Modal
                open={visible}
                title={null}
                onCancel={handleClose}
                closable={false}
                footer={null}
                className={`cs-modal new-ui ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
            >
                <div className="d jc-sb ai-center">
                    <div className="cs-title cs-medium" id="select-customer">
                    {getTranslation('SELECT CUSTOMER')}
                    </div>
                    <div>
                        <img src={CloseBlackOutlined} className="cur" alt="close-icon" onClick={() => handleClose()} />
                    </div>
                </div>
                <div className="grey mt-20">

                    <Select
                        style={{ width: '100%' }}
                        showSearch
                        placeholder={getTranslation("Select a customer")}
                        suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        onSelect={setCustomer}>
                        {
                            _map(customers, (item, value) => <Select.Option key={value} value={value}>
                                    {
                                        Object.keys(appPermissions).length > 0 && (appPermissions.maskCustomerName) ? 
                                        CUSTOMER_NAME_CHANGE[value] ? CUSTOMER_NAME_CHANGE[value] : 
                                            value : value
                                    }
                                </Select.Option>
                            )
                        }
                    </Select>
                </div>
            </Modal>
        </div>
    } else {
        return <Modal
            visible={true}
            closable={false}
            title={null}
            footer={null}
            className={`cs-modal new-ui ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
        >
            <div className="d">
                <div className="cs-title cs-medium" id="select-customer">
                    {getTranslation('SELECT CUSTOMER')}
                </div>
                <img src={CloseBlackOutlined} className="cur" alt="close-icon" onClick={() => handleClose()} />
            </div>
            <Select
                className="mt-20"
                style={{ width: '100%' }}
                showSearch
                placeholder={getTranslation("Select a customer")}
                suffixIcon={<img src={DownArrowOutlined} alt="down-arrow" width={'14px'} />}
                optionFilterProp="children"
                filterOption={(input, option) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
                onSelect={setCustomer}>
                {_map(customers, (item, value) => <Select.Option key={value} value={value}>{value}</Select.Option>)}
            </Select>
        </Modal>
    }

};




