import dataApi from '../elements/apis/apis'
import { getClientEquipmentMapping } from '../elements/apis/controlApis'
import { permissionMap } from "../lib/softMapping";
import { updateCustomerTheme, updateTermsAndConditions, updateUserAccessibleCustomers } from "./dashboardActions";

export function updateLoginStatus(payload) {

	return async (dispatch, getState) => {
		if (payload.allow) {
			if(!payload.noApp) {
				await dispatch(updateCustomerTheme());
				dispatch(setUserPermissions());
				dispatch(updateUserAccessibleCustomers())
				await dispatch(canAccessDashboard());
			}
			dispatch(updateTermsAndConditions(payload.username))
		}
		dispatch({
			type: "SET_LOGIN_STATUS",
			payload: payload
		});

	}

}

export function updateSessionCustomer(payload) {
	return (dispatch, getState) => {
		dispatch({
			type: "UPDATE_CUSTOMER",
			payload: payload
		});
	}
}

export function setUserPermissions(clientEquipmentMappingData) {
	return async (dispatch, getState) => {
		let permissions = await dataApi.getUserPermissions();
		let clientEquipmentMappings
		if(clientEquipmentMappingData)
			clientEquipmentMappings = clientEquipmentMappingData
		else
			clientEquipmentMappings = await getClientEquipmentMapping();
		let instantControlEnabled = clientEquipmentMappings?.data?.instantControlEnabled
		let cpmEnabled = false;
		
		for(let site of clientEquipmentMappings?.data?.clientSet?.edges)
		{
			if(site?.node?.cpmEnabled)
			{
				cpmEnabled = true
			}
		}
		let payload = {}
		permissions.data.getUserPermissions.map(item => Object.assign(payload, { [permissionMap[item.codename] || ""]: true }))
		if (instantControlEnabled) {
			payload["canInstantControl"] = true
		}
		if (cpmEnabled && (payload.regularOveride || payload.indefiniteOverride)) {
			payload["canCPMControl"] = true
		}
		if(clientEquipmentMappings) 
			payload["clientEquipmentMapping"] = clientEquipmentMappings

		// Handled customer theme if user has "SE_based_theme_color" permission assigned to it. Needed for Demo Users
		if (payload.changeColorForSE) {
			dispatch({
				type: "UPDATE_CUSTOMER_THEME",
				payload: 'schneider',
			});
		}
		if(payload.changeColorForMCD) {
			dispatch({
				type: "UPDATE_CUSTOMER_THEME",
				payload: 'mcdonalds',
			});
		}
		dispatch({
			type: "UPDATE_PERMISSIONS",
			payload
		});
	}
}

export function updatePathname(pathname) {
	return (dispatch, getState) => {

		dispatch({
			type: "SET_INITIAL_PATHNAME",
			payload: pathname
		});

	}

}

export function canAccessDashboard() {
	return async (dispatch, getState) => {
		let canAccess = await dataApi.canAccessDashboard({source: "Schedules Dashboard"});
		dispatch({
			type: "UPDATE_ACCESSIBILITY",
			payload: canAccess
		});
	}
}