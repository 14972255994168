import originalMoment from "moment";
import { extendMoment } from "moment-range";

const moment = extendMoment(originalMoment);

// since plywood doesn't recognize when date is in string format
export const parseToDate = function (data) {
	return moment(data).isValid() ? moment(data) : data;
}

export const parseDateToString = (data) => {
	return !isNaN(data) && moment(data).isValid() ? moment(data).toString() : data;
}

export const getISOPeriod = (periodObject) => {

	let timePeriod = 'P';

	if (periodObject.hrs || periodObject.mins)
		timePeriod += "T";

	if (periodObject.days)
		timePeriod += periodObject.days + "D";

	if (periodObject.hrs)
		timePeriod += periodObject.hrs + "H";

	return timePeriod;
}


// this function assumes you have extended moment with moment-range library 
export const convertToMomentRangeFormat = (intervals) => {
	if (intervals) {
		let intervalArray = intervals.split('/');
		return moment.range(moment(intervalArray[0]), moment(intervalArray[1]));
	}
	return intervals;
}

/*
{determiner(value for parser): value to be displayed} ?
determiner is the value that will be parsed by getIntervals function to set the range
*/
export const RelativeTimeRanges = {

	//hour
	// 'last_minute_30': 'Last 30 minutes',
	// 'last_hour_1': 'Last 1 hour',
	// 'last_hour_2': 'Last 2 hours',
	// 'last_hour_6': 'Last 6 hours',
	// 'last_hour_12': 'Last 12 hours',
	// 'last_hour_24': 'Last 24 hours',

	// days
	'current_day': 'Today',
	'last_day_1': 'Yesterday',
	'last_day_7': 'Last 7 days',
	'last_day_14': 'Last 14 days',

	//'week'
	'current_week_from_sunday': 'This Week (Sun-)',
	'current_week_from_monday': 'This Week (Mon-)',
	'previous_week_from_sunday': 'Previous Week (Sun-Sat)',
	'previous_week_from_monday': 'Previous Week (Mon-Sat)',

	// 'month'
	// current_month_from_start
	'current_month': 'Month till date',
	'previous_month': 'Previous Month',

	// 'quarter'
	'current_quarter': 'Quarter till date',
	'previous_quarter': 'Previous Quarter'
};

//TODO: clean out-- unecessary steps for some, maybe?
export const parseRangesToIntervals = (determiner) => {
	//1st set for subtraction last || previous and startof for current
	//also helps in setting end
	//2nd gives out what type of time data
	//3rd gives info on duration's origin -- ignoring from(3rd in determiner)
	let resolveDuration = (startMoment, secondArg, thirdArg, fourthArg) => {
		let duration = (!!thirdArg && !isNaN(thirdArg)) ? thirdArg : '';
		startMoment.startOf(secondArg);
		if (thirdArg === 'from' && fourthArg) {
			return startMoment.day(fourthArg);
		}
		return startMoment.subtract(duration, `${secondArg}s`);
	}
	let determinerParts = determiner.split('_');


	let end = moment().startOf('day'), start = end.clone();

	if (determiner === 'current_day') {
		end = moment();
		start = moment().startOf('day');
	}

	if (determinerParts[1] === 'hour' || determinerParts[1] === 'minute') {
		end = moment();
		let shift = determinerParts[1] + "s";
		start = moment().subtract(Number(determinerParts[2]), shift);
	} else
		start = resolveDuration(start, ...determinerParts.slice(1)); // present is the reference, anything other than previous reference present

	// shifting to past
	if (determinerParts[0] === 'previous') {
		// if current
		start.subtract(1, `${determinerParts[1]}s`);
		end.startOf(`${determinerParts[1]}`);
	}

	return moment.range(start.subtract(1, 'second').utc(), end.subtract(1, 'second').utc());
}

export const convertTime24to12 = (time, front) => {
	// Check correct time format and split into components
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
	let isAM
	if (time.length > 1) { // If time format correct
		time = time.slice(1);  // Remove full string match value
		isAM = +time[0] < 12 ? true : false; // Set AM/PM
		time[3] = +time[0] < 12 ? " AM" : " PM"; // Set AM/PM
		time[0] = +time[0] % 12 || 12; // Adjust hours
	}
	if (front) return time.join('')
	return { time, am: isAM }; // return adjusted time or original string
}

export const convertTime12to24 = (time12h) => {
	const [time, modifier] = time12h.split(' ');
	let [hours, minutes] = time.split(':');

	if (hours === '12') {
		hours = '00';
	}

	if (modifier === 'PM') {
		hours = parseInt(hours, 10) + 12;
	}
	return `${hours}:${minutes}`;
}

export const prependZero = (number) => {
	if (number <= 9)
		return "0" + number;
	else
		return number;
}