import React, { useEffect, useRef } from "react";
import { Image } from "semantic-ui-react";
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from 'react-redux';
import { removeBranding } from '../../lib/constants';
import { updateActiveDashboard } from '../../actions/dashboardActions'
import moment from "moment";
import { REACT_APP_BASE_URL } from "../../lib/constants";
import './dashboard-preview.css';
import ScheduleOutlined from "../../imgs/ScheduleOutlined.svg"
import DownloadOutlined from "../../imgs/DownloadOutlined.svg"
import ControlOutlined from "../../imgs/ControlOutlined.svg"
import BulkOutlined from "../../imgs/BulkOutlined.svg"
import OverrideOutlined from "../../imgs/OverrideOutlined.svg"
import InstantOutlined from "../../imgs/InstantOutlined.svg"
import LayoutOutlined from "../../imgs/LayoutOutlined.svg"
import { useTranslation } from "../../context/translationContext";

const DashboardMenu = props => {

    //Redux Dispatch
    const dispatch = useDispatch()

    const { getTranslation } = useTranslation();
    const dashboard = useSelector(state => state.dashboard)
    const isLoggedIn = useSelector(state => state.appStatus.isLoggedIn)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)
    const username = useSelector(state => state.appStatus.username)
    const customer = useSelector(state => state.appStatus.customer)
    const firstName = useSelector(state => state.appStatus.firstName)
    const lastName = useSelector(state => state.appStatus.lastName)
    const logo = useSelector(state => state.appStatus.logo)
    const sessionInfo = useSelector(state => state.appStatus)
    const isStaff = useSelector(state => state.appStatus.isStaff)

    const mounted = useRef();

    useEffect(() => {
        if (!mounted.current) {
            //componentDidMount
            setTimeout(() => {
                initMenuIcons()
            }, 100)
            mounted.current = true;
        } else {
            //componentDidUpdate
            initMenuIcons()
        }
    });

    useEffect(() => {
        if(!_isEmpty(dashboard.activeDashboard)){
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                'event': 'page location',
                'time': moment().format("YYYY-MM-DD HH:mm:ss"),
                'pageLocation': window.location.pathname,
                'source': 'Schedule Dashboard',
                'userId': sessionInfo.userId,
                'customer': sessionInfo.customer,
                'uuid': localStorage.getItem("uuid"),
                'userType': isStaff ? 'Internal' : 'External'
            })
        }
    }, [dashboard.activeDashboard])

    const selectDashboard = (index) => {
        let { dashboardConfig, activeDashboard } = dashboard;
        let dash = dashboardConfig[index];
        dispatch(updateActiveDashboard(dash))
    }


    const getScreenIcon = (icon, itemIndex, active) => {
        switch (icon) {
            case "LayoutOutlined":
                return <object className={'menu-icon'} data={LayoutOutlined} data-tag={active} type="image/svg+xml"/>
            case "ControlOutlined":
                return <object className={'menu-icon'} data={ControlOutlined} data-tag={active} type="image/svg+xml"/>
            case "OverrideOutlined":
                return <object className={'menu-icon'} data={OverrideOutlined} data-tag={active} type="image/svg+xml"/>
            case "InstantOutlined":
                return <object className={'menu-icon'} data={InstantOutlined} data-tag={active} type="image/svg+xml"/>
            case "TableOutlined":
                return <object className={'menu-icon'} data={BulkOutlined} data-tag={active} type="image/svg+xml"/>
            case "ScheduleOutlined":
                return <object className={'menu-icon'} data={ScheduleOutlined} data-tag={active} type="image/svg+xml"/>
            case "DownloadOutlined":
                return <object className={'menu-icon'} data={DownloadOutlined} data-tag={active} type="image/svg+xml"/>
        }
    }

    const getMenuItems = () => {
        const { dashboardConfig } = dashboard;
        let { activeDashboard } = dashboard;

        const getItems = (screen, index) => {
            let itemIndex = screen._id || screen.name;
            let screenIcon = screen.icon;
            let anchorTag = screen.anchorTag;
            let active = screen.name === activeDashboard.name ? 'active' : '';

            if (anchorTag) {
                return <a
                    className={'single-screen ' + (screen.name === activeDashboard.name ? 'active' : '')}
                    key={index}
                    href={anchorTag}
                >
                    {getScreenIcon(screenIcon, itemIndex, active)}
                    <h3>{getTranslation(screen.name)}</h3>
                </a>
            }
            return (
                <div
                    className={'single-screen ' + (screen.name === activeDashboard.name ? 'active' : '')}
                    key={index}
                    onClick={() => { selectDashboard(index); }}>
                    {getScreenIcon(screenIcon, itemIndex, active)}
                    <h3>{getTranslation(screen.name)}</h3>
                </div>
            )
        }

        if (!_isEmpty(dashboardConfig)) {
            /* todo : concept of sections has been deferred AoN,
                     well be using it later on when sections
                     and definitions become complex
            */
            return dashboardConfig.map((screen, index) => {
                if (appPermissions.accessToNew)
                    return screen.new && getItems(screen, index)
                else
                    return !screen.new && getItems(screen, index)
            })
        } else
            return <div>Loading...</div>
    }

    const getUserInitials = () => {
        if (firstName || lastName)
            return <div className={'user-icon'}>
                <span>{String((firstName ? firstName[0] : '') + (lastName ? lastName[0] : '')).toUpperCase()}</span>
            </div>;
    }


    const initMenuIcons = () => {
        let icons = document.querySelectorAll('.menu-icon');
        if (!_isEmpty(icons))
            icons.forEach(icon => {
                let color = "#626469";
    
                if (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider') {
                    color = icon.getAttribute("data-tag") ? '#009530' : color;
                } else if (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds') {
                    color = icon.getAttribute("data-tag") ? '#da291c' : color;
                } else {
                    color = icon.getAttribute("data-tag") ? '#F44067' : color;
                }
                
                icon.setAttribute('width', "20");
                icon.setAttribute('height', "20");
                try {
                    if (icon.getSVGDocument()) {
                        icon.getSVGDocument().querySelectorAll("path").forEach(path => {
                            path.setAttribute('fill', color);
                            path.setAttribute('border-color', color);
                        })
                    }
                } catch (err) {
                    icon.querySelectorAll("path").forEach(path => {
                        path.setAttribute('fill', color);
                        path.setAttribute('border-color', color);
                    })
                }
            })
    }

    const getCustomerLogo = () => {
        const isBranding = removeBranding.indexOf(String(firstName).toLowerCase()) > -1;

        if (!isBranding && isLoggedIn && logo)
            return <div className={"customer-logo-images "}>
                <Image src={`${REACT_APP_BASE_URL}/users/getLogo?name=${logo}`}
                    style={{ 'padding': "5px", 'width': '100px' }}
                    size="small" wrapped /></div>
    }


    return (<React.Fragment>
        <div className={"dashboard-items"}>
            {getMenuItems()}
        </div>
        {/* <div className={'bottom-logos'}>
            {
                Object.keys(appPermissions).length > 0 && !(appPermissions.hideZenatixLogo || appPermissions.onlyShowZenatixLogo) &&
                getCustomerLogo()
            }
        </div> */}
    </React.Fragment>)

}



export default DashboardMenu;
