import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import './app-header.css';
import { useTranslation } from '../../context/translationContext';


export const AppHeader = props => {
    const { getTranslation } = useTranslation();

    //Redux states
    const heading = useSelector(state => state.dashboard.activeDashboard.name)


    return (
        <div className="app-header">
            <h1 className={"screen-heading cs-bold text-red"}>{getTranslation(heading)}</h1>
            <div className="pos app-filter">
                {props.children}
            </div>
        </div>
    )

}
