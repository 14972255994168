import React, { useState, useEffect, useRef } from 'react';
import { Checkbox } from 'antd';
import Search from 'antd/lib/input/Search';
import { useDispatch, useSelector } from 'react-redux';
import _isEmpty from 'lodash/isEmpty'
import { updateFilterData } from '../../actions/bulkActions';

const CheckboxGroup = Checkbox.Group;

export const FilterContent = ({ name, callBack, timeFrameId, freqId }) => {

    const [checkedList, setCheckedList] = useState([])
    const [indeterminate, setIndeterminate] = useState(true)
    const [checkAll, setCheckAll] = useState(false)
    const [options, setOptions] = useState([])

    const bulkReducer = useSelector(state => state.bulkReducer)
    const dispatch = useDispatch()

    const ref = useRef();

    useEffect(() => {
        if (bulkReducer.schedules && bulkReducer.schedules[timeFrameId]) {
            if (bulkReducer.schedules[timeFrameId].frequency[freqId]) {
                setFilter(bulkReducer.schedules[timeFrameId].frequency[freqId]['schedules'])
            }
        }
    }, [bulkReducer.schedules])

    useEffect(() => {
        let currGrid = bulkReducer.schedules[timeFrameId].frequency[freqId]['schedules'];
        let reqIndex = name === "Site" ? 0 : 1;
        let filterGrid = [currGrid[0], currGrid[1]];
        for (let rowCount = 2; rowCount < currGrid.length; rowCount++) {
            if (checkedList.includes(currGrid[rowCount][reqIndex]['value'])) {
                filterGrid.push(currGrid[rowCount])
            }
        }
        if (filterGrid.length > 2)
            dispatch(updateFilterData({ [`${timeFrameId}__${freqId}`]: filterGrid }))
        else
            dispatch(updateFilterData({ [`${timeFrameId}__${freqId}`]: null }))
    }, [checkedList])

    const setFilter = (grid) => {
        let plainOptions = []
        let alreadyMapped = {}
        let reqIndex = name === "Site" ? 0 : 1;

        for (let rowCount = 2; rowCount < grid.length; rowCount++) {
            if (grid[rowCount][reqIndex]['value'] !== "") {
                if (!alreadyMapped[grid[rowCount][reqIndex]['value']]) {
                    plainOptions.push(grid[rowCount][reqIndex]['value'])
                    Object.assign(alreadyMapped, { [grid[rowCount][reqIndex]['value']]: true })
                }
            }
        }
        setOptions(plainOptions)
    }

    useEffect(() => {
        if (ref.current) {
            if (callBack)
                callBack(checkedList)
        } else ref.current = true;
    }, [checkedList])

    const onSearch = (value) => {
        if (value === "") {
            setOptions(plainOptions)
            return;
        }
        let searchData = plainOptions.filter(data => data.indexOf(value) > -1);
        setOptions(searchData)
    }

    const onSelect = list => {
        setCheckedList(list);
        setIndeterminate(!!list.length && list.length < options.length);
        setCheckAll(list.length === options.length);
    };

    const onCheckAllChange = e => {
        setCheckedList(e.target.checked ? options : []);
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };

    const allClear = () => {
        setCheckedList([]);
        setIndeterminate(false)
        setCheckAll(false);
    }

    return (
        <div className="display-column br-1 mt-10" >
            <div>
                <Search placeholder="input search text" onSearch={onSearch} />
            </div>
            <div className="display-column mb-20 mt-10" style={{ maxHeight: 200, overflowY: 'auto' }}>
                <>
                    <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
                        Check all
                    </Checkbox>
                    <CheckboxGroup options={options} value={checkedList} onChange={onSelect} />
                </>
            </div>
        </div>
    )
}