
const cpmReducer = (
	state = {
		siteName: null,
		siteDisplayName: null,
	},
	action
) => {
	switch (action.type) {
		case "CLEAR_SITE_EEQUIPMENTS_CPM":
			state = { siteName: null, siteDisplayName: null};
			break;
        case "SET_SITE_EEQUIPMENTS_CPM":
            state = {
                ...state,
                siteName: action.payload.siteName,
                siteDisplayName: action.payload.siteDisplayName,
				siteStreamData:action.payload.siteStreamData
            };
            break;
		default:
			break;
	}
	return state;
};

export default cpmReducer;
