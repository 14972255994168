import _isEmpty from "lodash/isEmpty";
import { history } from "../config/history";
import _ from "lodash";
import dataApi from "../elements/apis/apis";

// executes for the first time app gets mounted
export function updateDashboardConfig(config, dashboardAttribute) {
    return (dispatch, getState) => {
      dispatch({
        type: "SET_DASHBOARD_CONFIG",
        payload: config
      });

      if (!_isEmpty(dashboardAttribute)) {
        if (config) {
          let Idx = 0;
          for (let i = 0; i < config.length; i++) {
            if (config[i].url?.toLowerCase() === dashboardAttribute?.toLowerCase()) {
              Idx = i;
              break;
            }
          }
          dispatch({
            type: "SET_ACTIVE_DASHBOARD",
            payload: config[Idx]
          });
          history.push(`/home/${config[Idx].url.toLowerCase()}`);
        }
        //dispatch(setActiveDasboard(config, dashboardAttribute))
      } else {
        if (config) {
          dispatch({
            type: "SET_ACTIVE_DASHBOARD",
            payload: config[0]
          });
          history.push(`/home/${config[0].url.toLowerCase()}`);
        } else {
          history.push(`/home/`);
        }
      }
    };
}

export function updateGlobalFilters(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_GLOBAL_FILTERS",
      payload: filters
    });
  };
}

export function resetGlobalFilters(filters) {
  return (dispatch, getState) => {
    dispatch({
      type: "SET_GLOBAL_FILTERS",
      payload: {}
    });
  };
}

export function clearDashboardSettings(metadata) {
  return (dispatch, getState) => {
    dispatch({
      type: "CLEAR_DASHBOARD"
    });
  };
}

export function searchAndSetActiveDashboard(dashboardID) {
  return (dispatch, getState) => {
    // "5ce1b3037e186e06d1c5f999"
    let state = {
      ...getState().dashboard.dashboardConfig
    };
    history.push(`/home/${dashboardID.toLowerCase()}`);
    dispatch(setActiveDasboard(state, dashboardID));
  };
}

export function updateActiveDashboard(activeDashboard) {
  return (dispatch, getState) => {
    let linkType = activeDashboard.url;
    history.push(`/home/${linkType.toLowerCase()}`);
    dispatch({
      type: "SET_ACTIVE_DASHBOARD",
      payload: activeDashboard
    });
  };
}

export function setActiveDasboard(config, dashboardAttribute) {
  //it can be sectionType or dashboardId for now-- replace it later with the dashboard path

  alert();
  return dispatch => {
    let state = {
      ...config
    };
    let activeDash = false;
    let allDash = [];
    state.dashboard.forEach(dash => {
      allDash = [...allDash, ...dash.screens];
    });
    if (allDash.length) {
      activeDash = allDash.filter(screen => {
        return (
          screen._id === dashboardAttribute ||
          (screen.config.sectionType &&
            screen.config.sectionType.toLowerCase() ===
              dashboardAttribute.toLowerCase())
        );
      });

      dispatch({
        type: "SET_ACTIVE_DASHBOARD",
        payload: (activeDash && activeDash[0]) || false
      });
    }
  };
}

export function updateTermsAndConditions(username) {
  return (dispatch) => {
      dataApi.getTermsAndConditions({ "username": username }).then(resp => {
      
          dispatch({
              type: "UPDATE_TERMS_AND_CONDITIONS",
              payload: resp
          });

      }).catch(err => console.error(err))
  }
}

export function updateUserAccessibleCustomers() {
  return (dispatch) => {
    let accessibleCustomerList = {}
    dataApi.fetchCustomers().then(resp => {

      accessibleCustomerList = resp.data

      dispatch({
        type: "UPDATE_USER_ACCESSIBLE_CUSTOMERS",
        payload: accessibleCustomerList
      });
    }).catch(err => console.error(err))
  }
}

export const updateCustomerTheme = () => async (dispatch) => {
  try {
    const resp = await dataApi.getDashboardTheme();
    dispatch({
      type: "UPDATE_CUSTOMER_THEME",
      payload: resp?.data?.customerTheme || 'zenatix',
    });
  } catch (error) {
    dispatch({
      type: "UPDATE_CUSTOMER_THEME",
      payload: 'zenatix',
    });
  }
};

export function updateSessionExpired(sessionExpired) {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_SESSION_EXPIRED",
      payload: sessionExpired
    });
  };
}