import React, { useState } from "react"
import { Button, Modal, Typography, Space } from 'antd';
import DOMPurify from "dompurify";
import dataApi from "../../elements/apis/apis";
import "./style.css"
import { useDispatch, useSelector } from "react-redux";
import { OptOutDownloadIcon } from "../../assests/icon";
import _isEmpty from 'lodash/isEmpty'
import { useTranslation } from "../../context/translationContext";
import { toastSuccess } from "../../toast/toast";
import { toast } from "react-toastify";
import { updateTermsAndConditions } from "../../actions/dashboardActions";

const { Text, Title } = Typography;

export const OptOutTNC = ({ setuserActionModal, logoutUser, termsData, setShowNewTNCModal}) => {
    const { getTranslation } = useTranslation();
    const [openModal, setOpenModal] = useState(setuserActionModal ? false : true)
    const [showOptOutModal, setShowOptOutModal] = useState(false)
    const [termsViewed, setTermsViewed] = useState(false);
    const dashboard = useSelector(state => state.dashboard)
    const username = useSelector(state => state.appStatus.username)
    const { title, description, text, is_active } = termsData;
    const dispatch = useDispatch()

    const showModal = async () => {
        setuserActionModal && setuserActionModal(false)
        setOpenModal(true)
    }

    const handleTNCOk = async () => {
        try{
            if(setuserActionModal){
                setShowOptOutModal(true)
            } else {
                let resp = await dataApi.optInTNC()
                if(resp.message){
                    toast.success("Privacy Policy accepted.", {icon: toastSuccess()});
                    dispatch(updateTermsAndConditions(username))
                    setShowNewTNCModal && setShowNewTNCModal(false)
                }
            }
            setOpenModal(false);
        } catch (e) {
            console.log(e);
        }
    };

    const handleTNCCancel = () => {
        if(!setuserActionModal){
            setShowOptOutModal(true)
        }
        setOpenModal(false);
    };

    const handleOptOutOk = () => {
        dataApi.optOutFromTNC().then(resp => {
            logoutUser()
        }).catch((e) => { })
        setShowOptOutModal(false)
    }

    const handleOptOutCancel = () => {
        if(!setuserActionModal){
            setOpenModal(true)
        }
        setShowOptOutModal(false)
    }

    const onModalContentScroll = () => {
        let ele = document.getElementById("terms-content");
        let value = ele.scrollHeight - Math.ceil(ele.scrollTop);
        // handling +- 1px differences or variations while scrolling
        if (value - 1 <= ele.clientHeight && ele.clientHeight <= value + 1) {
            setTermsViewed(true);
        } else {
            setTermsViewed(false);
        }
    };

    const handleTNCDownload = async () => {
        try {
            await dataApi.downloadTNC()
        } catch (e) {
            console.log(e);
        }
    }

    const termsAndConditionModalFooter = [
        <div className="d jc-sb" style={{ margin: '0 25px' }}>
            <div onClick={handleTNCDownload} className="d ai-center download-tnc">
                <OptOutDownloadIcon /> {getTranslation('Download')}
            </div>
            <div>
                <Button
                    key="submit"
                    type="primary"
                    disabled={!termsViewed}
                    style={!termsViewed ? { opacity: '0.4' } : { opacity: '1' }}
                    className="terms-and-condition-footer-btn accept-modal-btn"
                    onClick={handleTNCOk}
                >
                    {
                        setuserActionModal ? <>{getTranslation("Opt out")}</> : <>{getTranslation("Accept")}</>
                    }
                </Button>
                <Button
                    key="back"
                    className="terms-and-condition-footer-btn cancel-modal-btn"
                    onClick={handleTNCCancel}
                >
                    {getTranslation('Cancel')}
                </Button>
            </div>
        </div>
    ];

    const optOutModalFooter = [
        <Button
            key="submit"
            type="primary"
            className="terms-and-condition-footer-btn accept-modal-btn"
            onClick={handleOptOutOk}
        >
            {getTranslation('Yes')}
        </Button>,
        <Button
            key="back"
            className="terms-and-condition-footer-btn cancel-modal-btn"
            onClick={handleOptOutCancel}
        >
            {getTranslation('No')}
        </Button>
    ]

    const sanitizedDOM = DOMPurify.sanitize(text, { RETURN_DOM: true });

    return (
        <div>
            {
                setuserActionModal && <span onClick={showModal}>{getTranslation('Privacy Policy')} {termsData.version && `v${termsData.version}`}</span>
            }
            <Modal
                open={openModal}
                closable={false}
                className={`terms-and-conditions-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
                centered={true}
                width={600}
                footer={termsAndConditionModalFooter}
            >
                <Space direction={"vertical"}>
                    {is_active ? (
                        <React.Fragment>
                            <Title level={3} className="terms-modal-title">
                                {getTranslation(title)}
                            </Title>
                            <Title level={4} className="terms-modal-description">
                                {description}
                            </Title>
                            <Text
                                id="terms-content"
                                color="#707070"
                                className="terms-modal-text"
                                onScroll={(e) => onModalContentScroll()}
                            >
                                <span
                                    dangerouslySetInnerHTML={{
                                        __html: sanitizedDOM ? sanitizedDOM.outerHTML : "",
                                    }}
                                />
                            </Text>
                        </React.Fragment>
                    ) : (
                        <Title level={3} className="terms-modal-title">
                            {getTranslation("No record found !!")}
                        </Title>
                    )}
                </Space>
            </Modal>
            <Modal
                open={showOptOutModal}
                closable={false}
                className={`opt-out-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
                centered={true}
                footer={optOutModalFooter}
            >
                <div>
                    {getTranslation('You will no longer be able to')} <br /> {getTranslation('access any dashboard service.')} <br /> {getTranslation('Are you sure you want to proceed?')}
                </div>
            </Modal>
        </div>
    )
}