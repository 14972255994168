import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Form, Grid, Message } from 'semantic-ui-react';
import { Spin, Select } from 'antd';
import { login, validateOTP, generateOTP } from '../../elements/authentication/authentication';
import { checkSessionFromServer } from '../../App';
import background from '../../imgs/DashboardLoginBackground.png';
import { updateLoginStatus } from '../../actions/appActions';
import { ResetPasswordModal } from './forgotPassword';
import { REACT_APP_EUROPE_DOMAIN_NAME_KEY, REACT_APP_PASSWORD_RESET_URLS, RESET_OTP_TIMER } from '../../lib/constants';
import { toast } from "react-toastify";
import { toastError, toastSuccess } from '../../toast/toast';
import { OTPVerificationIcon, GreyDownArrowIcon } from '../../assests/icon';
import VerificationPage from './VerificationPage';

import './login.css';
import moment from 'moment';
import UserIcon from "../../imgs/user_icon.svg"
import PasslockIcon from "../../imgs/passlock_icon.svg"

const LoginForm = props => {


    const isLoggedIn = useSelector(state => state.appStatus.isLoggedIn)

    const [username, setusername] = useState('')
    const [password, setpassword] = useState('')
    const [OTP, setOTP] = useState('')
    const [error, seterror] = useState('')
    const [enableOTP, setEnableOTP] = useState(null);
    const [loginResp, setLoginResp] = useState(null);
    const [otpSource, setOTPSource] = useState("email")
    const [showVerificationPage, setShowVerificationPage] = useState(false)
    const [disableButton, setDisableButton] = useState(false)

    const [timer, setTimer] = useState(RESET_OTP_TIMER); // initial timer value of 15 seconds
    const [showTimer, setShowTimer] = useState(false); // whether or not to show the timer
    const dispatch = useDispatch()

    useEffect(() => {
        let intervalId;
        if (showTimer && timer > 0) {
            intervalId = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer == 0) {
            setDisableButton(false)
            setShowTimer(false);
            clearInterval(intervalId);
        }

        return () => clearInterval(intervalId);
    }, [showTimer, timer]);

    const setError = (errorString) => {
        if(errorString){
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
              'event': 'login failed',
              'userId': '',
              'source': 'Schedule Dashboard',
              'time': moment().format("YYYY-MM-DD HH:mm:ss"),
              'errorString': errorString
            })
        }
    }

    const sendOTP = async () => {
        try{
            setDisableButton(true)
            let resp = await generateOTP({channel: otpSource});
            if (resp){
                toast.success("OTP sent successfully", {icon: toastSuccess()})
                setTimer(RESET_OTP_TIMER); 
                setShowTimer(true)
            }
            else{
                setDisableButton(false)
            }
        } catch(e){
            console.log(e);
            setDisableButton(false)
        }
    };

    const submitForm = async () => {

        let error = '';
        if (username !== '' && password !== '') {
            let resp = await login({ username, password });
            if(resp.allow){

                if(!resp.userType){
                    checkSessionFromServer(resp)
                    window.location.reload()
                    return
                }

                if (props.isOTPRequired) {
                    setLoginResp(resp);
                    if(resp.noOtp){
                        dispatch(updateLoginStatus(resp))
                        window.location.reload()
                    }else
                        setEnableOTP(resp);
                } else
                    dispatch(updateLoginStatus(resp))
            } else {
                if(resp.isRedirect && (resp.hasOwnProperty("isPhoneVerified") || resp.hasOwnProperty("isEmailVerified"))){
                    setLoginResp(resp);
                    setShowVerificationPage(true)
                } else if(resp.isRedirect){
                    if(window.location.href.includes("dev")){
                        if(window.location.href.includes("teleport"))
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withTeleport}?reset=true`)
                        else
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.dev.withoutTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.dev.withoutTeleport}?reset=true`)
                    } else if(window.location.href.includes("qa")){
                        if(window.location.href.includes("teleport"))
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withTeleport}?reset=true`)
                        else
                            window.location.replace(`${!window.location.origin
                                .toLowerCase()
                                .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                                ? REACT_APP_PASSWORD_RESET_URLS.india.qa.withoutTeleport
                                : REACT_APP_PASSWORD_RESET_URLS.europe.qa.withoutTeleport}?reset=true`)
                    } else {
                        window.location.replace(`${!window.location.origin
                            .toLowerCase()
                            .includes(REACT_APP_EUROPE_DOMAIN_NAME_KEY)
                            ? REACT_APP_PASSWORD_RESET_URLS.india.prod.withoutTeleport
                            : REACT_APP_PASSWORD_RESET_URLS.europe.prod.withoutTeleport}?reset=true`)
                    }
                    return
                } else{
                    setError(resp.message || "Username or password is incorrect");
                    error = resp.message || "Username or password is incorrect"
                }
            }
        } else if (username === '' && (password === ''))
            error = 'Username and password can\'t be empty';
        else if (username === '')
            error = 'Username can\'t be empty';
        else
            error = 'Password can\'t be empty';
        seterror(error)
    }

    const onFinishOTP = async () => {
        try{
            let resp = await validateOTP({OTP, channel: otpSource});
            if (resp) {
                dispatch(updateLoginStatus(loginResp))
                window.location.reload();
            } else {
                toast.error("Invalid code, please try again.", {icon: toastError()});
            }
        } catch (e) {
            console.log(e);
        }
    };

    const handleChange = (value) => {
        setOTPSource(value)
    }

    const handleOTPInput = (e) => {
        if(e.target.value.length < 7){
            setOTP(e.target.value)
        }
    }

    const getOTPFormFields = () => {
            return <>
                <div className='otp-container'>
                    <div className='otp-source-container'>
                        <Select
                            defaultValue={otpSource}
                            className='otp-source-dropdown'
                            onChange={handleChange}
                            suffixIcon={<GreyDownArrowIcon />}
                            options={enableOTP?.hasOwnProperty('isPhoneVerified') ? [
                                {
                                    value: 'email',
                                    label: 'Email',
                                },
                                {
                                    value: 'sms',
                                    label: 'Phone',
                                },
                            ] : [
                                {
                                    value: 'email',
                                    label: 'Email',
                                }
                            ]}
                        />
                        <Button id={'otp-source-generate-btn'} onClick={sendOTP} style={disableButton ? {opacity: '0.4', pointerEvents: 'none'} : {opacity: '1'}}>
                            Send OTP
                        </Button>
                    </div>
                    <div>
                        <Form.Input fluid iconPosition='left' placeholder='Enter OTP' className='code-input-area'
                            name="OTP" value={OTP} onChange={handleOTPInput} />
                    </div>
                    <div style={{textAlign: 'end', marginBottom: '25px', fontSize: '12px'}}>
                        { showTimer && <span>If you didn't receive OTP,  <span className={"additional-trigger-timer-count"}>retry in {timer} sec.</span> </span>}
                    </div>
                    <div className='submit-otp-container'>
                        <Button disabled = {!OTP || OTP.length !== 6} className={'submit-login'} type="primary" htmlType="submit" style={{fontWeight:'normal' }} onClick={onFinishOTP}>
                            Next
                        </Button>
                    </div>
                </div>
            </>
    }

    const renderOTPFrom = () => {
        return (
            <div className='login-form' style={{height: '100%', 'background': 'url("' + background + '")', backgroundSize: 'cover'}}>
                <Grid textAlign='center' style={{height: '100%'}} verticalAlign='middle'>
                    <Grid.Column className={"login-form-container"}>
                        <div className={"login-instruction cs-title"}>
                            <OTPVerificationIcon id='otp-page-icon'/> 
                            VERIFY YOUR IDENTITY
                        </div>
                            {getOTPFormFields()}
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    const renderLoginForm = () => {
        return (
            <div className='login-form' style={{ height: '100%', 'background': 'url("' + background + '")', backgroundSize: 'cover' }}>
                <Grid textAlign='center' style={{ height: '100%' }} verticalAlign='middle'>
                    <Grid.Column className={"login-form-container"}>
                        <div className={"login-instruction res-fnt-24"} style={!!error ? {marginBottom: '15px'} : {marginBottom: '40px'}}>
                            SIGN IN TO YOUR ACCOUNT
                        </div>
                        {
                            !!error && 
                            <span id='error-msg'>
                                {error}
                            </span>
                        }
                        <Form size='large'>
                            <div>
                                <Form.Input
                                    fluid
                                    icon={
                                        <img
                                            src={UserIcon}
                                            alt="user-icon"
                                            className='form-input-icon'
                                        />
                                    }
                                    iconPosition="left"
                                    placeholder="Username"
                                    name="username"
                                    value={username}
                                    onChange={(e) => setusername(e.target.value)}
                                />
                                <Form.Input
                                    fluid
                                    value={password}
                                    icon={
                                        <img
                                          src={PasslockIcon}
                                          alt="lock-icon"
                                          className='form-input-icon'
                                        />
                                    }
                                    iconPosition='left'
                                    placeholder='Password'
                                    type='password'
                                    name='password'
                                    onChange={(e) => setpassword(e.target.value)}
                                />
                                <div className="d jc-fe mb-10" >
                                    <ResetPasswordModal />
                                </div>
                                <Button className={"submit-login"} fluid size='large' onClick={submitForm}>
                                    Sign In
                                </Button>
                                {
                                    !!error && <span style={{color:'#707070', marginTop: '10px', fontSize: '12px', fontWeight: '400', display: 'block'}}>
                                        Please contact admin in case of query
                                    </span>
                                }

                            </div>
                        </Form>
                    </Grid.Column>
                </Grid>
            </div>
        );
    }

    const renderBaseLoader = () => {
        return <div style={{ height: '100%' }}>
            <span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}><Spin
                size={'large'} /></span>
        </div>
    }

    if(showVerificationPage)
        return <VerificationPage 
            isPhoneVerified={loginResp["isPhoneVerified"]} 
            isEmailVerified={loginResp["isEmailVerified"]} 
            userType={loginResp["userType"]}
            username={username}
            password={password}
        />
    if (enableOTP)
        return renderOTPFrom();
    if (!isLoggedIn && isLoggedIn !== null)
        return renderLoginForm();
    else
        return renderBaseLoader();

}


export default LoginForm