import { Workbook } from "exceljs";

export const downloadMultiSheets = (dataSource, title, EXCEL_HEADER, sheetName) => {
    let workbook = new Workbook();
    for (let key = 0; key < title.length; key++) {

        let worksheet = workbook.addWorksheet(title[key], {
            properties: { tabColor: { argb: "FFC0000" } },
        }); //creating worksheet
        let DOWNLOADCOLUMNDT = dataSource[key];
        const getCellValue = (ColCount) => {
            let CellName = "";
            let Alphas = [
                "0",
                "A",
                "B",
                "C",
                "D",
                "E",
                "F",
                "G",
                "H",
                "I",
                "J",
                "K",
                "L",
                "M",
                "N",
                "O",
                "P",
                "Q",
                "R",
                "S",
                "T",
                "U",
                "W",
                "X",
                "Y",
                "Z",
            ];
            while (ColCount / 26 > 26) {
                CellName = CellName + "Z";
                ColCount = ColCount - 676;
            }
            let div = parseInt(ColCount / 26);
            if (div < 1) CellName = CellName + Alphas[ColCount];
            else CellName = CellName + Alphas[div] + Alphas[ColCount - 26 * div];
            return CellName;
        };

        worksheet.getRow(1).font = {
            color: { argb: "ffffff" },
        };
        worksheet.addConditionalFormatting({
            ref: "A1:" + getCellValue(EXCEL_HEADER[key].length) + "1",
            rules: [
                {
                    type: "expression",
                    formulae: ["0=0"],
                    style: {

                        fill: {
                            type: "pattern",
                            pattern: "solid",
                            bgColor: { argb: "0075ff" },
                        },
                    },
                },
            ],
        });

        worksheet.columns = EXCEL_HEADER[key];

        // Add Array Rows
        worksheet.addRows(DOWNLOADCOLUMNDT);

    }
    workbook.xlsx.writeBuffer().then(function (data) {
        var blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const URL = window.URL.createObjectURL(blob);
        const ANCHOR = document.createElement("a");
        ANCHOR.href = URL;
        ANCHOR.download = sheetName + ".xls";
        ANCHOR.click();
        window.URL.revokeObjectURL(URL);
    });
};