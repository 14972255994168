
import { useTranslation } from "../../context/translationContext";
import "./style.css"

const NoPermission = () => {

    const { getTranslation } = useTranslation();

    return(
        <div className="permission-msg">
            {getTranslation("You do not have permission for this.")}
        </div>
    )
}

export default NoPermission;