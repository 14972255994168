import React, { useEffect, useRef, useState } from 'react'
import { Button, Select } from 'antd'
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from 'react-redux';
import { getClientEquipmentMapping, getStreamsForEquipment, getStreamsForEquipmentForOverride } from '../../elements/apis/controlApis';
import { setEquipment } from '../../actions/equipmentActions';
import { clearNewSchedules } from '../../actions/newSchedulesActions';
import { setUserPermissions } from '../../actions/appActions';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';
import { getCurrentUnitSystem } from '../../lib/function-helpers';

export const SelectEquipment = (props) => {
    const { getTranslation } = useTranslation();
    const [siteEquipmentMapping, setSiteEquipmentMapping] = useState({});
    const [equipmentsData, setEquipmentsData] = useState([]);
    const [selectedSite, setSelectedSite] = useState(null)
    const [selectedClientName, setSelectedClientName] = useState(null)
    const [siteClientMapping, setSiteClientMapping] = useState({})
    const [selectedEquipment, setSelectedEquipment] = useState(null)
    const [overrideTime, setOverrideTime] = useState("1800")
    const [timezone, setTimezone] = useState(null)

    const dispatch = useDispatch()
    const equipmentReducer = useSelector(state => state.equipmentReducer)
    const appPermissions = useSelector(state => state.appStatus.appPermissions)

    const siteSelect = useRef();
    const equipemntSelect = useRef();

    useEffect(() => {
        init()
    }, [])

    const unFocus = (element) => {
        element.blur()
    }

    useEffect(() => {
        if (equipmentReducer.clientName) setSelectedSite(equipmentReducer.clientName)
        if (equipmentReducer.equipmentName) setSelectedEquipment(equipmentReducer.equipmentName)
    }, [equipmentReducer.clientName, equipmentReducer.equipmentName])

    const init = async () => {
        try{
            let res;
            if(appPermissions['clientEquipmentMapping'] && appPermissions['clientEquipmentMapping']['data'])
                res = appPermissions['clientEquipmentMapping']
            else{
                res = await getClientEquipmentMapping()
                dispatch(setUserPermissions(res))
            }
            let timeZone = {}
            if (res && res.data && res.data.clientSet.edges) {
                let tempClientEquipmentMapping = {}
                let tempSiteClientMapping = {}
                res.data.clientSet.edges.map(data => {
                    Object.assign(timeZone, {
                        [data.node.displayName]: data.node.timezone
                    })
                    Object.assign(tempClientEquipmentMapping, {
                        [data.node.displayName]: data.node.equipments.edges.map(equipmentData =>
                            equipmentData.node)
                    })
                    Object.assign(tempSiteClientMapping, {
                        [data.node.displayName]: data.node.clientName
                    })
                })
                if (res.data.controlOverrideDuration) {
                    setOverrideTime(res.data.controlOverrideDuration)
                }
                setTimezone(timeZone)
                setSiteEquipmentMapping(tempClientEquipmentMapping)
                setSiteClientMapping(tempSiteClientMapping)
    
                if (equipmentReducer.clientName)
                    setEquipmentsData(tempClientEquipmentMapping[equipmentReducer.clientName])
            }
        } catch(e) {
            console.log(e);
        }
    }

    const onChangeSites = siteName => {
        if (siteName) {
            let tempEquipmentData = [];
            tempEquipmentData = siteEquipmentMapping[siteName].sort(function (a, b) {
                let textOne = typeof a.displayName === 'string' ?
                    a.displayName.toLowerCase() : a.displayName.toString();
                let textSecond = typeof b.displayName === 'string' ?
                    b.displayName.toLowerCase() : b.displayName.toString();
                textOne = textOne.trim()
                textSecond = textSecond.trim()
                return textOne.localeCompare(textSecond);
            });
            setSelectedClientName(siteClientMapping[siteName])
            unFocus(siteSelect.current)
            setSelectedSite(siteName)
            setEquipmentsData(tempEquipmentData)
            setSelectedEquipment(null)
        }
    };

    const onChangeEquipment = equipmentName => {
        unFocus(equipemntSelect.current)
        if (equipmentName) {
            setSelectedEquipment(equipmentName)

            if (selectedSite && equipmentName)
                updateEqupmentRedux(equipmentName)
        }
    }

    const updateEqupmentRedux = async (equipmentName) => {
        let streamFunctions = []
        let streamFunctionsForOverride = []
        let requiredClientName = selectedClientName || siteClientMapping?.[selectedSite]
        try {
            let resp = await getStreamsForEquipment({ equipmentName, clientName: requiredClientName, unitType: getCurrentUnitSystem() })
            let streamForOverride = await getStreamsForEquipmentForOverride({ equipmentName, clientName: requiredClientName })
            if (streamForOverride && streamForOverride.data) {
                streamFunctionsForOverride = streamForOverride.data
            }
            if (resp && resp.data) {
                streamFunctions = resp.data;
            }
        }
        catch (err) {
            console.error(err)
        }

        dispatch(clearNewSchedules())

        dispatch(setEquipment({
            clientName: selectedSite,
            equipmentName: equipmentName,
            streamFunctions,
            streamFunctionsForOverride,
            defaultValue: getDefault(streamFunctions),
            defaultValueForOverride: getDefault(streamFunctionsForOverride),
            overrideTime: overrideTime,
            timezone: timezone[selectedSite],
            actualClientName: requiredClientName
        }))
    }

    const getDefault = (config) => {
        let defaults = {};

        const assignDefault = (value) => {
          let properties = value.properties?.[getCurrentUnitSystem()] || value.properties;
            switch (value.type) {
                case "Binary":
                    Object.assign(defaults, { [value.name]: properties?.false_string })
                    break;
                case "Continuous":
                    Object.assign(defaults, { [value.name]: properties?.min_measured })
                    break;
                case "Discrete":
                    if(properties?.input_string_list?.includes("AUTO"))
                      Object.assign(defaults, { [value.name]: "AUTO" })
                    else if(properties?.input_string_list?.includes("OCCUPIED"))
                      Object.assign(defaults, { [value.name]: "OCCUPIED" })
                    else if(properties?.input_string_list?.includes("OFF"))
                      Object.assign(defaults, { [value.name]: "OFF" })
                    else
                      Object.assign(defaults, { [value.name]: properties?.input_string_list?.[0] || "FAN" })
                    break;
                default:
                    break;
            }
        }
        for (let stream = 0; stream < config.length; stream++) {
            if (config[stream].toggle) {
                assignDefault(config[stream].toggle)
            }
            assignDefault(config[stream])
        }
        return defaults;
    }

    return (
      <div className="d jc-sb hierarchy-context mb-10">
        <div>
          <span
            id={"custom-select-label"}
          >
            {getTranslation("Site")}
          </span>
          <Select
            ref={siteSelect}
            showSearch
            placeholder={getTranslation("Select Site")}
            suffixIcon={
              <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
            }
            onChange={onChangeSites}
            value={selectedSite}
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {!_isEmpty(siteEquipmentMapping) &&
              Object.keys(siteEquipmentMapping).map((site) => (
                <Select.Option key={site} value={site}>
                  {site}
                </Select.Option>
              ))}
          </Select>
        </div>
        <div>
          <span
            id={"custom-select-label"}
          >
            {getTranslation("Equipment")}
          </span>
          {!props.siteOnly && (
            <Select
              ref={equipemntSelect}
              showSearch
              value={selectedEquipment}
              onChange={onChangeEquipment}
              suffixIcon={
                <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
              }
              placeholder={getTranslation("Select Equipment")}
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {equipmentsData.length !== 0 &&
                equipmentsData.map((equipment) => (
                  <Select.Option value={equipment.name} key={equipment.name}>
                    {getTranslation(equipment.displayName)}
                  </Select.Option>
                ))}
            </Select>
          )}
        </div>
      </div>
    );
}
