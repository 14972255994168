import moment from "moment";
import React from "react";
import { convertTime24to12, prependZero } from "../../lib/dateHelpers";
import { allColourRange, colorMapping, handleSoftMapping } from "../../lib/softMapping";
import { getCurrentUnitSystem } from "../../lib/function-helpers";


export const getTitle = (value, view, streamFunctions, getTranslation) => {
    let { colour, lightColour } = colorMapping(value.name.toLowerCase(), value.title);
    if (view === "month")
        return <>
            <div className={"title-red " + `colour-${colour}`}
                style={{ color: colour, backgroundColor: lightColour, borderLeft: `4px solid ${colour}` }}>
                <div style={{ marginLeft: 5, whiteSpace: 'break-spaces' }}>
                    <span style={{ color: "blue" }}>
                        {convertTime24to12(`${prependZero(moment(value.start).hours())}:${prependZero(moment(value.start).minutes())}`, true)}
                    </span> :
                    <span className="ml-5">{getTranslation(handleSoftMapping(value.title, value.name, streamFunctions))}</span>
                </div>
            </div></>
    else if (view === "week")
        return <div className={"title " + `colour-${colour}`}
            style={{ color: colour, backgroundColor: `${colour} !important` }}>
        </div>
    else
        return `${handleSoftMapping(value.title, null, streamFunctions)}`
}

export const getColors = (legendData, streamFunctions, getTranslation) => {
    const getTitle = (obj, key) => {
        const isMetric = getCurrentUnitSystem() === "metric";
        switch (obj.mode) {
            case 'temp':
                return <div key={key} className="d ai-center mr-10">
                    <div className="legend-dot" style={{ backgroundColor: `#${obj.colour}` }} />
                    {obj.range[0]} - {obj.range[1]} {isMetric ? '°C' : '°F'}
                </div >
            case 'freq':
                return <div key={key} className="d ai-center mr-10">
                    <div className="legend-dot" style={{ backgroundColor: `#${obj.colour}` }} />
                    {obj.range[0]} - {obj.range[1]} Hz
                </div >
            case 'bar':
                return <div key={key} className="d ai-center mr-10">
                    <div className="legend-dot" style={{ backgroundColor: `#${obj.colour}` }} />
                    {obj.range[0]} - {obj.range[1]} {isMetric ? 'bar' : 'pascal'} 
                </div >
            default:
                return <div key={key} className="d ai-center mr-10">
                    <div className="legend-dot" style={{ backgroundColor: `#${obj.colour}` }} />
                    {getTranslation(obj.range[0])}
                </div >

        }
    }

    return legendData.map((obj, id) => getTitle(obj, id))
}