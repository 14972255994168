import _isEmpty from 'lodash/isEmpty';
import axios from 'axios'
import { responseLogout } from '../components/app-header/BrandHeader';
import { storageChangeEvent } from '../App';

export const encodeParams = (params) => {
    if (!params) {
        return ""
    }
    return Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&');
}

const axiosAPI = axios.create({
    withCredentials: true,
});

axiosAPI.interceptors.request.use(
    config => {
        // config.headers['Authorization'] = 'Bearer token';
        if(config.method === "post" || config.method === "delete"){
            config.headers = {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        }
        if(!config.notShowLoader){
            let previousPromiseTracker = JSON.parse(sessionStorage.getItem("promiseTracker")) ?? 1
            sessionStorage.setItem("promiseTracker", previousPromiseTracker + 1)
            storageChangeEvent.key = "promiseTracker"
            storageChangeEvent.value = previousPromiseTracker + 1
            window.dispatchEvent(storageChangeEvent)
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axiosAPI.interceptors.response.use(
    response => {
        const { data } = response;

        if(data['allow'] === false && response.config.url !== "/access/status" && response.config.url !== "/users/login" 
            && response.config.url !== "/users/logout" ){
                !!localStorage.getItem("loginTime") && responseLogout(true);
            return;
        }
        if(!response.config.notShowLoader){
            let trackerValue = JSON.parse(sessionStorage.getItem("promiseTracker"))
            let previousPromiseTracker = trackerValue && (trackerValue > 1) ? trackerValue : 2
            sessionStorage.setItem("promiseTracker", previousPromiseTracker - 1)
            storageChangeEvent.key = "promiseTracker"
            storageChangeEvent.value = previousPromiseTracker - 1
            window.dispatchEvent(storageChangeEvent)
        }
        return response;
    },
    error => {
        if(!error.config.notShowLoader){
            let trackerValue = JSON.parse(sessionStorage.getItem("promiseTracker"))
            let previousPromiseTracker = trackerValue && (trackerValue > 1) ? trackerValue : 2
            sessionStorage.setItem("promiseTracker", previousPromiseTracker - 1)
            storageChangeEvent.key = "promiseTracker"
            storageChangeEvent.value = previousPromiseTracker - 1
            window.dispatchEvent(storageChangeEvent)
        }
        if (
            error?.response?.status === 401 &&
            error?.response?.config?.url !== "/access/status" &&
            error?.response?.config?.url !== "/users/login" &&
            error?.response?.config?.url !== "/users/logout"
          ) {
            !!localStorage.getItem("loginTime") && responseLogout(true);
            return;
        }
        return Promise.reject(error);
    }
);

export default axiosAPI;