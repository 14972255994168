
export function clearEquipment() {
    return (dispatch, getState) => {
        dispatch({
            type: "CLEAR_EQUIPMENT"
        });
    }
}

export function setEquipmentType(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "SET_EQUIPMENT_TYPE",
            payload
        });
    }
}



