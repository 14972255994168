
export function clearNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "CLEAR_NEW_SCHEDULES"
        });
    }
}

export function updateNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_NEW_SCHEDULES",
            payload
        });
    }
}

export function addNewSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "ADD_NEW_SCHEDULES",
            payload
        });
    }
}

export function duplicateSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "DUPLICATE_SCHEDULES",
            payload
        });
    }
}

export function deleteSchedules(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "DELETE_SCHEDULES",
            payload
        });
    }
}

export function addNewFrequency(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "ADD_NEW_FREQUENCY",
            payload
        });
    }
}

export function updateFreqTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_FREQUENCY_TIME",
            payload
        });
    }
}

export function deleteFrequency(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "DELETE_FREQUENCY",
            payload
        });
    }
}

export function updateStartTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_START_TIME",
            payload
        });
    }
}

export function updateEndTime(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_END_TIME",
            payload
        });
    }
}

export function addNewState(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "ADD_STATE",
            payload
        });
    }
}

export function deleteState(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "DELETE_STATE",
            payload
        });
    }
}


export function updateState(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_STATE",
            payload
        });
    }
}

export function updateEditStatus(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UPDATE_EDIT",
            payload
        });
    }
}

export function undo(payload) {
    return (dispatch, getState) => {
        dispatch({
            type: "UNDO",
            payload
        });
    }
}

