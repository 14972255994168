import { toast } from "react-toastify";
import _isEmpty from 'lodash/isEmpty';
import { toastError } from "../../toast/toast";

const getEquipmentName = (equipmentMapping, equipmentName, site) => {
    let siteCorrect = false;
    for (let equipmentData = 0; equipmentData < equipmentMapping.length; equipmentData++) {
        const { siteName, siteDisplayName, name, displayName } = equipmentMapping[equipmentData];
        if (siteDisplayName === site) {
            siteCorrect = true
        }
    }
    if (!siteCorrect) {
        toast.error(`${site} - This site name is incorrect.`, {icon: toastError()})
        return "";
    }
    for (let equipmentData = 0; equipmentData < equipmentMapping.length; equipmentData++) {
        const { siteName, siteDisplayName, name, displayName } = equipmentMapping[equipmentData];
        if (siteDisplayName === site && displayName === equipmentName) {
            return name;
        }
    }
    if (equipmentName === "")
        toast.error(`Equipment name cannot be empty.`, {icon: toastError()})
    else
        toast.error(`${equipmentName} - This equipment name is incorrect.`, {icon: toastError()})
    return "";
}

const validateFeilds = (validFeilds, name, value) => {
    // if (value) {
    if (value === "") return true
    if (name && validFeilds[name].includes(value))
        return true;
    else {
        toast.error(`${value} is not a valid value for stream ${name}`, {icon: toastError()})
        return false
    }
    // } else {
    //     toast.error("Please fill all the feilds")
    //     return true
    // }
}

const checkTime = (timeStr) => {
    if (timeStr === "") return true;
    let valid = (timeStr.search(/^\d{2}:\d{2}$/) != -1) &&
        (timeStr.substr(0, 2) >= 0 && timeStr.substr(0, 2) <= 23) &&
        (timeStr.substr(3, 2) >= 0 && timeStr.substr(3, 2) <= 59) &&
        (timeStr.substr(6, 2) >= 0 && timeStr.substr(6, 2) <= 59);
    return valid
}

export const parseSheet = (gridData, equipmentMapping, validFeilds) => {
    const header = ["site", "equipmentName", ...gridData[1].map(gridData => gridData.component.key)];
    let scheduleState = {};
    for (let row = 2; row < gridData.length; row++) {
        let equipmentName = ""
        let tempState = {};
        let siteName = ""
        let stateTime = "12:00";
        for (let rowValue = 0; rowValue < gridData[row].length; rowValue++) {
            if (header[rowValue] === "site") {
                siteName = gridData[row][rowValue]["value"];
                if (siteName === "") break;
                continue;
            }

            if (header[rowValue] === "equipmentName") {
                equipmentName = getEquipmentName(equipmentMapping, gridData[row][rowValue]["value"], siteName);
                if (equipmentName)
                    continue
                else {
                    // toast.error(`${gridData[row][rowValue]["value"]} Equipment name not valid.`)
                    return false;
                }
            }

            if (header[rowValue] === "Time") {
                if (checkTime(gridData[row][rowValue]["value"])) {
                    stateTime = gridData[row][rowValue]["value"]
                    continue
                } else {
                    toast.error(`${gridData[row][rowValue]["value"]} this time is not in valid format.`, {icon: toastError()})
                    return false;
                }
            }

            if (!validateFeilds(validFeilds, header[rowValue], gridData[row][rowValue]["value"]))
                return false;

            if (gridData[row][rowValue]["value"]) {
                if (tempState[stateTime])
                    Object.assign(tempState, {
                        [stateTime]: {
                            ...tempState[stateTime],
                            [header[rowValue]]: gridData[row][rowValue]["value"]
                        }
                    })
                else
                    Object.assign(tempState, {
                        [stateTime]: {
                            [header[rowValue]]: gridData[row][rowValue]["value"]
                        }
                    })
            }
        }

        if (equipmentName !== "" && !_isEmpty(tempState))
            Object.assign(scheduleState, { [equipmentName]: { "states": { ...tempState } } })
    }
    return scheduleState
}