import React, { useEffect, useState } from 'react'
import { Button, Space, Modal } from 'antd';
import { DatePicker } from '../dayjsToMoment';
import { useDispatch, useSelector } from 'react-redux';
import { duplicateSchedules } from '../../actions/newSchedulesActions';
import moment from 'moment';
import { CloseIcon, DeleteIcon, PlusIcon, DeleteIconMCD } from '../../assests/icon';
import _isEmpty from 'lodash/isEmpty'
import DateRangeSeparator from "../../imgs/DateRangeSeparator.svg";
import DateRangeSeparatorSE from "../../imgs/DateRangeSeparatorSE.svg";
import DateRangeSeparatorMCD from "../../imgs/DateRangeSeparatorMCD.svg";
import CalendarIcon from "../../imgs/CalendarIcon.svg"
import CalendarIconSE from "../../imgs/CalendarIconSE.svg"
import CalendarIconMCD from "../../imgs/CalendarIconMCD.svg"
import { useTranslation } from '../../context/translationContext';

const { RangePicker } = DatePicker;

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

export const GetDuplicateModal = ({ handleClose, duplicateModal, scheduleId }) => {
    const { getTranslation } = useTranslation();
    const [schedulesCount, setSchedulesCount] = useState(0);
	const dashboard = useSelector(state => state.dashboard)
    const dispatch = useDispatch();
    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)

    useEffect(() => {
        if (!_isEmpty(newScheduleStore)) {
            let allSchedules = []
            for (let key of Object.keys(newScheduleStore))
                allSchedules.push({ ...newScheduleStore[key], scheduleId: key })
            setSchedulesCount(allSchedules.length)
        } else setSchedulesCount(0)
    }, [newScheduleStore])

    const [timeFrames, setTimeFrames] = useState([{
        start_date: moment().format('YYYY-MM-DD'),
        expiry_date: moment().format('YYYY-MM-DD')
    }]);

    const handleNewTimeFrame = () => {
        setTimeFrames([...timeFrames, {
            start_date: moment().format('YYYY-MM-DD'),
            expiry_date: moment().format('YYYY-MM-DD')
        }])
    }

    const onChange = (value, idx) => {
        let tempArray = timeFrames;
        tempArray[idx] = { start_date: value[0].format("YYYY-MM-DD"), expiry_date: value[1].format("YYYY-MM-DD") };
        setTimeFrames([...tempArray])
    }

    const onDelete = (idx) => {
        let tempArray = [...timeFrames.slice(0, idx), ...timeFrames.slice(idx + 1)]
        setTimeFrames(tempArray)
    }

    const handleSubmit = () => {
        dispatch(duplicateSchedules({ key: scheduleId, schedulesCount, timeFrames }))
        handleClose()
    }

    return (

        <Modal
            className={`cs-modal ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
            open={duplicateModal}
            onCancel={handleClose}
            title={null}
            footer={null}
            closable={false}
        >
            <div className="d ai-center jc-sb">
                <div className="cs-modal-title">{getTranslation('Duplicate Schedule')}</div>
                <CloseIcon className="icon-wrapper-small" onClick={handleClose} />
            </div>
            <div className="cs-modal-sub-title">
            {getTranslation('The current event control would apply to the selected next timeframe(s).')}
            </div>
            <div className="cs-modal-content">
                {timeFrames.map((data, idx) => <div key={idx} className="d ai-center mb-10" >
                    <RangePicker 
                    popupClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                        dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}
                    suffixIcon={<img src={!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'schneider' ? CalendarIconSE : dashboard?.customerTheme === 'mcdonalds' ? CalendarIconMCD : CalendarIcon} alt="calendar-icon" />}
                    separator={
                        <img
                            src={
                            !_isEmpty(dashboard && dashboard?.customerTheme) &&
                            dashboard?.customerTheme === "schneider"
                                ? DateRangeSeparatorSE : 
                                dashboard?.customerTheme === "mcdonalds"
                                ? DateRangeSeparatorMCD
                                : DateRangeSeparator
                            }
                            alt='ant-date-range-separator'
                        />
                    } key={idx} format="YYYY-MM-DD" disabledDate={disabledDate}
                        value={[moment(data.start_date), moment(data.expiry_date)]}
                        onChange={(val) => onChange(val, idx)} allowClear={false} className="red" />
                    {
                        (!_isEmpty(dashboard && dashboard?.customerTheme) && dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color') ? 
                        <DeleteIconMCD className="ml-10" onClick={() => onDelete(idx)}/> : 
                        <DeleteIcon className="ml-10" onClick={() => onDelete(idx)} />
                    }  
                </div>)}
            </div>
            <div className="d jc-fe">
                <Space>
                    <Button className="red-button-outline" onClick={handleNewTimeFrame}
                        icon={<PlusIcon className="plus-icon-color" />} >{getTranslation('New Time Frame')}</Button>
                    <Button onClick={handleSubmit} className="red-button">{getTranslation('Save')}</Button>
                </Space>
            </div>
        </Modal>
    )
}