import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';

const PublicRoute = ({component: Component, isOTPRequired, ...rest }) => {
  const isLoggedIn = useSelector(state => state.appStatus.isLoggedIn)
  const pathname = useSelector(state => state.appStatus.pathname)
  return (
    <Route
      {...rest}
      render={props =>
        !isLoggedIn ? isOTPRequired ? <Component {...props} isOTPRequired={true}/> : <Component {...props} /> : <Redirect to={pathname !== '/login'? pathname: '/home'} />
      }
    />
  );
};

export default PublicRoute;
