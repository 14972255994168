import { Modal, Select } from "antd";
import _map from "lodash/map";
import React, { useState } from "react";
import axios from "axios";
import _isEmpty from "lodash/isEmpty";
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg";
import CloseBlackOutlined from "../../imgs/CloseBlackOutlined.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "../../context/translationContext";
import { IMPERIAL_SYSTEM, METRIC_SYSTEM  } from "../../lib/constants";

export const UnitSystemSelect = () => {
  const { getTranslation } = useTranslation();
  const dashboard = useSelector((state) => state.dashboard);
  const unitSystem = useSelector((state) => state?.appStatus?.unitSystem)
  const unitSystemsList = [
    { id:METRIC_SYSTEM, label: "Metric", value:METRIC_SYSTEM },
    { id:IMPERIAL_SYSTEM, label: "Imperial", value:IMPERIAL_SYSTEM },
  ];
  const [visible, setVisible] = useState(false);

  const handleClose = () => {
    setVisible(false);
  };

  const setUnitSystem = async (unitSystem) => {
    try {
      await axios.get(`/utilities/setUnitSystemPreference?unitSystem=${unitSystem}`);
      window.location = "/";
    } catch (error) {
       console.error(error)
    }
  };

  return (
    <div className="grey">
      <span onClick={() => setVisible(true)}>
        {getTranslation("Change Unit System")}
      </span>
      <Modal
        visible={visible}
        title={null}
        onCancel={handleClose}
        closable={false}
        footer={null}
        className={`cs-modal new-ui ${
          !_isEmpty(dashboard && dashboard?.customerTheme) &&
          (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')
        }`}
      >
        <div className="d jc-sb ai-center">
          <div className="cs-title cs-medium" id="select-unit-system">
            {getTranslation("SELECT UNIT SYSTEM")}
          </div>
          <div>
            <img
              src={CloseBlackOutlined}
              className="cur"
              alt="close-icon"
              onClick={() => handleClose()}
            />
          </div>
        </div>
        <div className="grey mt-20">
          <Select
            style={{ width: "100%" }}
            showSearch
            placeholder={getTranslation("Select a unit system")}
            suffixIcon={
              <img src={DownArrowOutlined} alt="down-arrow" width={"14px"} />
            }
            optionFilterProp="children"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onSelect={setUnitSystem}
            value={unitSystem}
          >
            {_map(unitSystemsList, (unitSystem) => {
              return (
                <Select.Option key={unitSystem.value} value={unitSystem.value}>
                  {getTranslation(unitSystem.label)}
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </Modal>
    </div>
  );
};
