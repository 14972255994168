import React, { useState } from 'react';
import { useSelector } from 'react-redux'
import "./style.css"
import { useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import ArrowUp from "../../imgs/ArrowUp.svg"
import { useTranslation } from '../../context/translationContext';
import { Collapse } from 'antd';
import EquipmentTable from '../../components/cpm/EquipmentTable';
import { sortAccToPriorityCPM } from '../../lib/softMapping';

export const CPM = () => {
    const { getTranslation } = useTranslation();
    const [chillerItems,setChillerItems] = useState([]);
    const cpmReducer = useSelector(state => state.cpmReducer)

    const initCPM = async () =>{
        const equipmentTypes = Object.keys(cpmReducer.siteStreamData);
        const sortedEquipmentTypes = sortAccToPriorityCPM(equipmentTypes);

        try {
            setChillerItems(sortedEquipmentTypes.map((item,index)=>{
                return  {
                    key: `${index + 1}`,
                    label: cpmReducer.siteStreamData[item]?.equipmentTypeDisplayName || item,
                    children: <EquipmentTable equipmentType={item} />,
                    style: {
                        marginBottom: 24,
                        borderBottom:"1px solid #d9d9d9",
                      },
                }
            }))

        } catch (error) {
            // console.error(error)
        }
    }

    useEffect(()=>{
        if(cpmReducer.siteDisplayName){
            initCPM()
        }
    },[cpmReducer.siteDisplayName])


    return (
        <>
            {
                !chillerItems?.length ?
                    <div className="br-1 d color-grey jc-c ai-center mt-20 cs-title" style={{ height: '80%', textAlign: 'center' }}>
                        {getTranslation("Select Site to View Controls")} <img src={ArrowUp} alt="arrow-up-icon" />
                    </div> 
                    :
                    <div className='cpm-accordian-container'>
                        <Collapse
                            defaultActiveKey={['1']}
                            expandIconPosition={"end"}
                            className='collapse-container'
                            style={{textTransform:"capitalize",fontSize:"20px",backgroundColor:"transparent"}}
                            items={
                                chillerItems
                            }
                        />
                    </div>
              
            }
        </>
    )
}