const pivotReducer = (state = {
	isLoggedIn: null,
	username: '',
	pathname: '/',
	appPermissions: {},
	canAccess: false
}, action) => {
	switch (action.type) {
		case "SET_LOGIN_STATUS":
			state = {
				...state,
				isLoggedIn: action.payload.allow || false,
				username: action.payload.username || false,
				userId: action.payload.userId || false,
				customer: action.payload.customer || false,
				firstName: action.payload.firstName || false,
				lastName: action.payload.lastName || false,
				logo: action.payload.logo || false,
				isStaff: action.payload.isStaff || false,
				isNewUser: action.payload.isNewUser || false,
				unitSystem:action.payload.unitPreference,
			}
			break;
		case "UPDATE_CUSTOMER":
			state = {
				...state,
				customer: action.payload.name,
				logo: action.payload.logo
			};
			break;
		case "UPDATE_PERMISSIONS":
			state = {
				...state,
				appPermissions: action.payload
			}
			break;
		case "SET_INITIAL_PATHNAME":
			state = {
				...state,
				pathname: action.payload,
			}
			break;
		case "UPDATE_ACCESSIBILITY": 
			state = {
				...state,
				canAccess: action.payload
			}
			break;
		default:
			break;
	}

	return state;
};


export default pivotReducer;
