const equipmentReducer = (
	state = {
		clientName: null,
		equipmentName: null,
		streamFunctions: [],
		streamFunctionsForOverride: [],
		defaultValue: {},
		defaultValueForOverride: {},
		overrideTime: "1800",
		timezone: null,
		actualClientName: null
	},
	action
) => {
	switch (action.type) {
		case "CLEAR_EQUIPMENT":
			state = { schedules: [] };
			break;
		case "SET_EQUIPMENT":
			state = {
				clientName: action.payload.clientName,
				equipmentName: action.payload.equipmentName,
				streamFunctions: action.payload.streamFunctions,
				streamFunctionsForOverride: action.payload.streamFunctionsForOverride,
				defaultValue: action.payload.defaultValue,
				defaultValueForOverride: action.payload.defaultValueForOverride,
				overrideTime: action.payload.overrideTime,
				timezone: action.payload.timezone || "Asia/Kolkata",
				actualClientName: action.payload.actualClientName
			};
			break;
		default:
			break;
	}
	return state;
};

export default equipmentReducer;
