
const instantControlReducer = (
	state = {
		siteName: null,
		equipmentType: null,
		equipmentTypeDisplayName: null,
		siteDisplayName: null,
		equipmentsTypeDataMapping: [],
        validationConstraint: {},
        widgets: []
	},
	action
) => {
	switch (action.type) {
		case "CLEAR_EQUIPMENT":
			state = { siteName: null, equipmentType: null, validationConstraint: {}, widgets: [], equipmentTypeDisplayName: null, 
				siteDisplayName: null, equipmentsTypeDataMapping: []};
			break;
		case "SET_EQUIPMENT_TYPE":
			state = {
				siteName: action.payload.siteName,
				equipmentType: action.payload.equipmentType,
                validationConstraint: action.payload.validationConstraint,
                widgets: action.payload.widgets,
				equipmentTypeDisplayName: action.payload.equipmentTypeDisplayName,
				siteDisplayName: action.payload.siteDisplayName,
				equipmentsTypeDataMapping: action.payload.equipmentsTypeDataMapping
			};
			break;
		default:
			break;
	}
	return state;
};

export default instantControlReducer;
