import React, { useState, useEffect, useContext, createContext } from "react";
import axios from "axios";
import { languageChangeEvent } from "../App";

export const DEFAULT_LANGUAGE = "en";
const TranslationContext = createContext();

export function TranslationProvider({ children }) {
  const language = localStorage.getItem("language")
    ? localStorage.getItem("language")
    : DEFAULT_LANGUAGE;
  const [translations, setTranslations] = useState({});
  const [currentLanguage, setCurrentLanguage] = useState(language);

  useEffect(() => {
    fetchTranslations();
  }, []);

  const fetchTranslations = async () => {
    try {
      const response = await axios.get(`/utilities/translations`);
      if (response.status !== 200) {
        throw new Error("Failed to fetch translations");
      }
      localStorage.setItem("language", response.data.language);
      setCurrentLanguage(response.data.language);
      setTranslations(response.data.translations);
      window.dispatchEvent(languageChangeEvent);
    } catch (error) {
      localStorage.setItem("language", error?.response?.data?.language || 'en');
      setCurrentLanguage(error?.response?.data?.language || 'en');
      window.dispatchEvent(languageChangeEvent);
      console.error("Failed to fetch translations:", error?.response?.data?.resp || error);
    }
  };

  const getValueFromNestedPath = (key, nestedPath) => {
    const pathParts = nestedPath.split(".");

    let result = { ...translations };
    for (const path of pathParts) {
      if (result && result.hasOwnProperty(path)) {
        result = result[path];
      } else {
        return undefined;
      }
    }

    return result[key] || key;
  };

  const getTranslation = (text, nestedPath) => {
    if (!text || typeof text !== "string") return text;

    // trim off extra espaces
    text = text.trim();

    if (!translations) {
      console.warn(
        `Translation key "${text}" not found for language "${currentLanguage}"`
      );
      return text; // Fallback to text if translation is missing
    }
    switch (currentLanguage) {
      case "en":
        return text;
      case "fr":
        return nestedPath
          ? getValueFromNestedPath(text, nestedPath)
          : translations[text] || text;
      default:
        return text;
    }
  };

  return (
    <TranslationContext.Provider
      value={{ currentLanguage, getTranslation, setCurrentLanguage }}
    >
      {children}
    </TranslationContext.Provider>
  );
}

export function useTranslation() {
  return useContext(TranslationContext);
}
