import React, { useEffect, useRef, useState } from 'react';
import { Button, Popover, Radio, Modal } from 'antd';
import { Calendar } from '../dayjsToMoment';
import { CustomDatePicker } from '../custom-datepicker';
import moment from 'moment'
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { undo, updateFreqTime } from '../../actions/newSchedulesActions';
import * as BulkActions from '../../actions/bulkActions';
import { toast } from 'react-toastify';
import { getAllDates } from '../../lib/getEventHelper';
import _isEmpty from 'lodash/isEmpty';
import DownArrowOutlined from "../../imgs/DownArrowOutlined.svg"
import { useTranslation } from '../../context/translationContext';

const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
}

function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().startOf("day");
}

let prevArray = [];

function arraysEqual(a, b) {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.

    for (var i = 0; i < a.length; ++i) {
        if (a[i] !== b[i]) return false;
    }
    return true;
}

export const CustomFreqSelect = ({ value, freqId, scheduleId, isBulk }) => {
    const { getTranslation } = useTranslation();

    const daysArr = ['Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat', 'Sun']

    const daysMaping = {
        'Mon': false,
        'Tue': false,
        'Wed': false,
        'Thur': false,
        'Fri': false,
        'Sat': false,
        'Sun': false,
    }

    const parseFull = {
        "Sunday": "Sun",
        "Monday": "Mon",
        "Tuesday": "Tue",
        "Wednesday": "Wed",
        "Thursday": "Thur",
        "Friday": "Fri",
        "Saturday": "Sat"
    };

    const [popoverVisible, setPopoverVisible] = useState(false);
    const [daysSelected, setdaysSelected] = useState(daysMaping)
    const [daysSelectedForTitle, setdaysSelectedForTitle] = useState([])

    const dispatch = useDispatch();
    const newScheduleStore = useSelector(state => state.newScheduleStore.schedules)
    const bulkSchedules = useSelector(state => state.bulkReducer.schedules)
    const lastFreqId = useSelector(state => state.newScheduleStore.lastFreqId)
	const dashboard = useSelector(state => state.dashboard)

    const ref = useRef()

    const [date, setdate] = useState(null)
    const [days, setdays] = useState(false)

    useEffect(() => {
        if (isBulk)
            checkRepeatDates(bulkSchedules);
        else
            checkRepeatDates(newScheduleStore);
    }, [newScheduleStore])

    useEffect(() => {
        if (value) {
            if (value.isDate) {
                setdate(value.date)
                setdays(false)
                setdaysSelected(daysMaping)
            } else {
                let tempDaysSelected = daysMaping;
                let days = value.days_of_week.map(days => parseFull[days])
                days.map(day => {
                    Object.assign(tempDaysSelected, { [day]: true })
                })
                setdaysSelectedForTitle([...days])
                setdaysSelected({ ...tempDaysSelected })
                setdays(true)
            }
        }
    }, [value])

    useEffect(() => {
        if (ref.current) {
            let payload = {}
            if (days) {
                payload.isDate = false;
                payload.days_of_week = daysSelectedForTitle.map(day => getKeyByValue(parseFull, day))
            } else {
                payload.isDate = true,
                    payload.date = moment(date).format("YYYY-MM-DD");
            }
            if (isBulk) {
                dispatch(BulkActions.updateFreqTime({ key: scheduleId, freqId, newFreqTime: payload }))
            } else {
                // if (checkRepeatDates(payload)) {
                dispatch(updateFreqTime({ key: scheduleId, freqId, newFreqTime: payload }))
                // }
            }
        } else ref.current = true;

    }, [days, date, daysSelectedForTitle])

    function onPanelChange(value, mode) {
        // console.log(value, mode);
    }

    const checkRepeatDates = (schedules) => {
        if (schedules && !_isEmpty(schedules)) {

            const { start_date, expiry_date } = schedules[scheduleId];
            let existDate = []

            for (let freqId = 0; freqId < schedules[scheduleId].frequency.length; freqId++) {
                existDate.push(...getAllDates(schedules[scheduleId].frequency[freqId], start_date, expiry_date))
            }
            if (arraysEqual(prevArray, existDate)) {
                return false;
            }
            prevArray = existDate;
            function hasDuplicates(array) {
                var valuesSoFar = Object.create(null);
                for (var i = 0; i < array.length; ++i) {
                    var value = array[i];
                    if (value in valuesSoFar) {
                        return value;
                    }
                    valuesSoFar[value] = true;
                }
                return false;
            }

            const found = hasDuplicates(existDate);
            if (found && lastFreqId !== null) {
                document.getElementById(`freq-btn${lastFreqId}`).click()
                let config = {
                    title: 'Error',
                    icon: null,
                    content: <span> <span className="cs-medium">{getTranslation('Multiple controls')}</span> {getTranslation('deployed for the following date-')} <span className="cs-medium">{moment(found).format('Do MMMM YYYY')}</span></span>,
                    okText: "Change Time Frame",
                    className: 'cs-modal',
                    cancelButtonProps: { className: "cs-dis-none" }
                    // onOk: () => { }
                };
                Modal.confirm(config)
                return false;
            } else return true;
        }
    }

    const handleDaysSelect = (day) => {
        let tempDaysSelected = daysSelected;
        if (tempDaysSelected[day]) {
            document.getElementById(`${day}-${freqId}`).classList.remove("days-selected")
            Object.assign(tempDaysSelected, { [day]: false })
        }
        else {
            document.getElementById(`${day}-${freqId}`).classList.add("days-selected")
            Object.assign(tempDaysSelected, { [day]: true })
        }
        let daysForTitle = Object.keys(tempDaysSelected).filter(day => tempDaysSelected[day]);
        setdaysSelectedForTitle(daysForTitle)
        setdaysSelected(tempDaysSelected)
    }

    const getTitle = () => {
        let title = getTranslation("Select Frequency ");
        if (days) {
            let days = daysSelectedForTitle.join(',')
            title += days
        }
        else {
            let tempDate = date ? moment(date).format('YYYY-MM-DD') : "";
            title += tempDate
        }
        return title;
    }

    useEffect(() => {
        if (popoverVisible) {
            for (let day of Object.keys(daysSelected)) {
                if (daysSelected[day]) {
                    document.getElementById(`${day}-${freqId}`).classList.add("days-selected")
                } else {
                    document.getElementById(`${day}-${freqId}`).classList.remove("days-selected")
                }
            }
        }
    }, [daysSelected, popoverVisible])

    const content = () => {
        return (
            <div className="display-column">
                <div className="display-column"  >
                    <div className="d jc-sb mt-10">
                        <div className="d mb-10">
                            <Radio checked={days} onChange={() => setdays(true)} >{getTranslation('Select days')} :</Radio>
                        </div>
                    </div>
                    <div className="d jc-sa" style={{ position: 'relative' }}>
                        {!days && <div className="overlay"></div>}
                        {daysArr.map((day, key) => <p id={`${day}-${freqId}`} key={key} className={"days"} onClick={() => handleDaysSelect(day)}  > {day} </p>)}
                    </div>
                </div>
                <div>
                    <div className="d jc-sb mt-10">
                        <div className="d mb-10">
                            <Radio checked={!days} onChange={() => setdays(false)} >{getTranslation('Select Date')} :</Radio>
                        </div>
                    </div>
                    <div className="custom-center" >
                        <div className="display-column site-calendar-demo-card" style={{ position: 'relative' }} >
                            {days && <div className="overlay"></div>}
                            <CustomDatePicker customDropdownClassName={!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
					            dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')} small={"-small"} view={"month"} value={date ? moment(date) : null} callback={(date) => { setdate(date); }} />
                            <Calendar
                                value={date ? moment(date) : null} disabledDate={disabledDate}
                                mode="month" headerRender={() => <></>} onChange={(date) => { setdate(date) }}
                                fullscreen={false} onPanelChange={onPanelChange} />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    return (
        <Popover placement="bottomLeft" content={content} open={popoverVisible}
            onOpenChange={() => setPopoverVisible(!popoverVisible)} overlayClassName={`new-ui cs-freq-select ${!_isEmpty(dashboard && dashboard?.customerTheme) && (dashboard?.customerTheme === 'schneider' && 'SE-theme-color' || 
                dashboard?.customerTheme === 'mcdonalds' && 'MCD-theme-color')}`}
            trigger="click">
            <Button onClick={() => setPopoverVisible(!popoverVisible)}
                id={"freq-btn" + freqId} className="red-button-outline" >{getTitle()} <img src={DownArrowOutlined} alt="down-arrow" className='select-down-arrow-icon' /> </Button>
        </Popover>
    )
}