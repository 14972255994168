import { unitMapping } from "./constants";

export const handleSoftMapping = (val, name, streamFunctions) => {
    let isArray = Array.isArray(val);
    let reqVal = streamFunctions ? streamFunctions.filter(data => data.name.toLowerCase() === name.toLowerCase())[0] : null;
    const addSuffix = () => {
        if (name.toLowerCase() === "vfd control") {
            return val + " Hz";
        }
        else {
            if (reqVal && reqVal['unit'])
                return val + " " + (unitMapping[reqVal['unit']] || reqVal['unit']);
            return val;
        }
    }

    if (reqVal?.['type'] === 'Continuous' || name.toLowerCase() === "set differential pressure")
        return addSuffix()
    switch (val) {
        case "0":
            return "OFF"
        case "1":
            return "ON"
        case "FAN":
            return "FAN"
        default:
            if (isArray) return val.join(" - ")
            else return addSuffix()
    }
}

const parseColour = (percentage, colour) => {
    switch (percentage) {
        case 100:
            return `#${colour}FF`;
        case 25:
            return `#${colour}40`;
    }
}

const yellow = "FAAD14";

export const colorMapping = (name, value) => {
    if (name === "set temperature") {
        value = parseInt(value);
        if (value >= 16 && value <= 23) return {
            id: 1, colour: "00CEFF",
            range: [16, 23], mode: 'temp', lightColour: parseColour(25, "00CEFF")
        }
        if (value >= 24 && value <= 30) return {
            id: 2, colour: "007CB4",
            range: [24, 30], mode: 'temp', lightColour: parseColour(25, "007CB4")
        }
    }

    if (name === "vfd control") {
        value = parseInt(value);
        if (value >= 30 && value <= 35) return {
            id: 3, colour: "FAAD14",
            range: [30, 35], mode: 'freq', lightColour: parseColour(25, "FAAD14")
        }
        if (value >= 36 && value <= 40) return {
            id: 4, colour: "E66B0E",
            range: [36, 40], mode: 'freq', lightColour: parseColour(25, "E66B0E")
        }
        if (value >= 41 && value <= 45) return {
            id: 5, colour: "C04C00",
            range: [41, 45], mode: 'freq', lightColour: parseColour(25, "C04C00")
        }
        if (value >= 46 && value <= 50) return {
            id: 6, colour: "790500",
            range: [46, 50], mode: 'freq', lightColour: parseColour(25, "790500")
        }
    }

    if (name === "on/off control" || name === "operating mode") {
        switch (value) {
            case "ON":
            case "1":
                return {
                    id: 7, colour: "FAAD14", range: ["ON"],
                    mode: '', lightColour: parseColour(25, "FAAD14")
                }
            case "OFF":
            case "0":
                return {
                    id: 8, colour: "FF0B00", range: ["OFF"],
                    mode: '', lightColour: parseColour(25, "FF0B00")
                }
            case "FAN":
                return {
                    id: 9, colour: "36E02A", range: ["FAN"],
                    mode: '', lightColour: parseColour(25, "36E02A")
                }
            case "COOL":
                return {
                    id: 10, colour: "007CB4", range: ["COOL"],
                    mode: '', lightColour: parseColour(25, "007CB4")
                }
        }
    }

    if (name === "set differential pressure") {
        value = parseFloat(value);
        if (value >= 0.6 && value <= 0.7) return {
            id: 11, colour: "FAAD14",
            range: [0.6, 0.7], mode: 'bar', lightColour: parseColour(25, "FAAD14")
        }
        if (value >= 0.7 && value <= 0.8) return {
            id: 12, colour: "E66B0E",
            range: [0.7, 0.8], mode: 'bar', lightColour: parseColour(25, "E66B0E")
        }
    }


    return { id: "None", colour: "F44067", lightColour: parseColour(25, "F44067") }
}

export const allColourRange = {

    "vfd control": [
        { range: [30, 35], colour: `#FAAD14`, mode: 'freq' },
        { range: [36, 40], colour: `#E66B0E`, mode: 'freq' },
        { range: [41, 45], colour: `#C04C00`, mode: 'freq' },
        { range: [46, 50], colour: `#790500`, mode: 'freq' },
    ],
    "set differential pressure": [
        { range: [0.6, 0.7], colour: `#FAAD14`, mode: 'freq' },
        { range: [0.7, 0.8], colour: `#E66B0E`, mode: 'freq' },
    ],
    "set temperature": [
        { range: [16, 23], colour: `#00CEFF`, mode: 'temp' },
        { range: [24, 30], colour: `#007CB4`, mode: 'temp' }
    ],
    "operating mode": [
        { range: ['FAN'], colour: `#36E02A`, mode: '' },
        { range: ['COOL'], colour: `#007CB4`, mode: '' }
    ],
    "on/off control": [
        { range: ['ON'], colour: `#FAAD14`, mode: '' },
        { range: ['OFF'], colour: `#FF0B00`, mode: '' }
    ],
}


export const sortAccToPriority = (unSortedData) => {
    let sortedData = []
    const priorityMap = {
        "on/off control": "1",
        "on/off control fan 1": "2",
        "on/off control fan 2": "3",
        "idu on/off control": "4",
        "operating mode": "5",
        "fan mode": "6",
        "occupancy mode": "7",
        "set temperature": "8",
        "chw valve control": "9",
        "vfd control": "10",
        "set differential pressure": "11"
    };
    unSortedData = unSortedData.map(data => {
        return {
            ...data, priorityNumber: priorityMap[data.name.toLowerCase()] || "99"
        }
    })
    sortedData = unSortedData.sort(function (a, b) {
        let textOne = a.priorityNumber;
        let textSecond = b.priorityNumber;
        return textOne.localeCompare(textSecond);
    });
    return sortedData;
}

export const sortAccToPriorityInstantControl = (unSortedData, equipmentType) => {
    // console.log("sortAccToPriorityInstantControl1", unSortedData, equipmentType);
    let sortedData = []
    let priorityMap = {};

    if(equipmentType === "ahu"){
        priorityMap = {
            "on/off control": "1",
            "valve status": "2",
            "set temperature": "3",
            "chw valve control": "4",
            "vfd status": "5",
            "vfd set temperature": "6",
            "vfd control": "7",
        }
    } else if(equipmentType === "ac" || equipmentType === "cassette_ac" || equipmentType === "duct_ac" || equipmentType === "fcu") {
        priorityMap = {
            "on/off control": "1",
            "set temperature": "2",
        }
    } else if(equipmentType === "air_washer" || equipmentType === "condensed_pump" || equipmentType === "exhaust") {
        priorityMap = {
            "on/off control": "1",
            "vfd control": "2",
        }
    } else if(equipmentType === "chiller") {
        priorityMap = {
            "on/off control": "1",
            "evaporator leaving water set point": "2",
        }
    } else if(equipmentType === "cooling_tower") {
        priorityMap = {
            "on/off control": "1",
            "on/off control fan": "2",
            "on/off control fan 1": "3",
            "on/off control fan 2": "4",
            "outlet set temperature": "5",
            "vfd status": "6",
            "approach set temperature":"7",
            "vfd control": "8"
        }
    } else if(equipmentType === "csu") {
        priorityMap = {
            "on/off control": "1",
            "set temperature": "2",
            "vfd control": "3",
        }
    } else if(equipmentType === "fa") {
        priorityMap = {
            "on/off control": "1",
            "vfd set temperature": "2",
            "vfd control": "3",
        }
    } else if(equipmentType === "secondary_pump") {
        priorityMap = {
            "on/off control": "1",
            "on/off control pump 1": "2",
            "on/off control pump 2": "3",
            "vfd status": "4",
            "set differential pressure": "5",
            "vfd control": "6",
        }
    } else if(equipmentType === "tfa") {
        priorityMap = {
            "on/off control": "1",
            "set temperature": "2",
            "chw valve control": "3",
            "vfd control": "4",
        }
    } else if(equipmentType === "vrf" || equipmentType === "vrv") {
        priorityMap = {
            "idu on/off control": "1",
            "odu on/off control": "2",
            "set temperature": "3",
        }
    } 

    unSortedData = unSortedData.map(data => {
        return {
            ...data, priorityNumber: priorityMap[data.title.toLowerCase()] || "99"
        }
    })

    sortedData = unSortedData.sort(function (a, b) {
        let textOne = a.priorityNumber;
        let textSecond = b.priorityNumber;
        return textOne.localeCompare(textSecond);
    });
    // console.log("sortAccToPriorityInstantControl2", sortedData);
    return sortedData;
}

export const sortAccToPriorityCPM = (unSortedData) => {
    let sortedData = []
    const priorityMap = {
        "chiller": "1",
        "chw_header": "2",
        "primary_pump":"3",
        "secondary_pump":"4",
        "condenser_pump":"5",
        "cooling_tower": "6",
    };

    sortedData = unSortedData.sort(function (a, b) {
        let textOne = priorityMap[a] || "99";
        let textSecond = priorityMap[b] || "99";
        return textOne.localeCompare(textSecond);
    });

    return sortedData;
}



export const permissionMap = {
    "can_regular_override": "regularOveride", //whether to show 60 min option on modal while adding override
    "can_indefinite_override": "indefiniteOverride", //whether to show indefinite option on modal while adding override
    "can_access_new": "accessToNew",
    "can_only_view_controls": "onlyViewControls", 
    // whether to show View Controls page then view / add controls page and control status and download schedules pages will not come
    "can_add_controls": "canAddControls", //whether to show add control button on add controls page
    "can_delete_controls": "canDeleteControls", //whether to show delete control button on control status page
    "can_change_customer": "canChangeCustomer",
    "can_bulk_control": "canBulkControl", //whether to show bulk control screen or not
    "hide_zenatix_logo": "hideZenatixLogo",
    "only_show_zenatix_logo": "onlyShowZenatixLogo",
    "SE_based_theme_color": "changeColorForSE",
    "MCD_based_theme_color": "changeColorForMCD",
    "schedule-demo-user": "isDemoUser", //whether to make any post api call
    "mask_customer_name": "maskCustomerName"
}

export const toggleMapping = {
    "Set Temperature": "Auto",
    "CHW Valve Control": "Manual",
    "Set Differential Pressure": "Auto",
    "VFD Control": "Manual",
    "VFD Set Temperature": "Auto",
}


export const getValid = (config) => {
    let defaults = {};
    // console.log(config)
    for (let stream of Object.keys(config)) {
        let tempProperties = config[stream]?.properties?.['metric'] || config[stream]?.properties
        switch (config[stream].type) {
            case "Binary":
                Object.assign(defaults, { [stream]: [tempProperties?.false_string.toString(), tempProperties?.true_string.toString()] })
                break;
            case "Continuous":
                let valid = []
                const leastCount = config[stream].properties?.least_count || 1;
                for (let i = tempProperties?.min_measured; i <= tempProperties?.max_measured;) {
                    valid.push(parseFloat(i).toString())
                    i = (parseFloat(i) + leastCount).toFixed(1);
                }
                Object.assign(defaults, { [stream]: valid })
                break;
            case "Discrete":
                let properties = tempProperties?.['input_string_list'];
                Object.assign(defaults, { [stream]: ["Discrete", ...properties] })
                break;
            default:
                break;
        }
    }
    // console.log(defaults)
    return defaults;
}
